/* .no-data {
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: calc(100vh - 390px);
  overflow: hidden;
}

.no-data p {
  color: #333;
  font-size: 16px;
  font-weight: bold;
} */


.no-data {
  text-align: center;
  padding: 20px;
  /* background-color: #f5f5f5; */
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
 width: 100%;
 height: 100%;
}

.no-data p {
  color: #333;
  font-size: 16px;
  font-weight: bold;
}


@media(max-width :767px) {
  .no-data {
    text-align: center;
    padding: 20px;
    /* background-color: #f5f5f5; */
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
  }
}