.viewMore {
  background: #FFFCF5;
  overflow: auto;
}
.viewMore .userImage {
  text-align: center;
  padding: 30px 0px;
}
.viewMore .userImage img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 5px solid #f1ac0a;
}
.viewMore .userImage .userName {
  font-size: 20px;
  line-height: 30px;
  color: #212121;
  font-weight: 400;
  margin: 0;
  padding-top: 10px;
}
.viewMore .userImage .viewProfile {
  font-size: 15px;
  line-height: 25.5px;
  margin: 0;  
  text-transform: uppercase;
  color: #FFB200;
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
}

.viewMore .logout {
  font-size: 15px;
  line-height: 25.5px;
  text-transform: uppercase;
  color: #FFB200;
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
  margin-top: 50px;
}

.viewMore button.btn {
  width: 90%;
  border-radius: 10px;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  border: none;
  padding: 10px 10px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.viewMore button.btn .title {
  font-size: 15px;
  line-height: 25.5px;
  margin: 0;
  font-weight: 400;
  text-transform: capitalize;
  color: #212121;
  padding-left: 10px;
}
button.btn .arrrowRight {
  position: absolute;
  right: 5px;
  top: 7px;
}
.viewMore .bgRound {
  position: absolute;
  top: 0px;
  z-index: 0;
  left: 0;
}

@media (max-width: 767px) {
  .viewMore {
    padding-bottom: 30px;
    height: 100vh;
    overflow: auto;
  }
  
}


@media (min-width: 768px) {
  .viewMore .bgRound {
    display: none;
  }
}