.profile {
  padding-top: 105px;
}

.profile .profileInner {
  background: #FFFCF5;
  overflow: auto;
  background: url("../../../assets/Phase2/profileBgMob.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
}

.profile .userImage {
  text-align: center;
  padding: 30px 0px 0px 0px;
  position: relative;
}
.profile .userImage img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 5px solid #f1ac0a;
}
.profile .userImage .userName {
  font-size: 20px;
  line-height: 30px;
  color: #212121;
  font-weight: 400;
  margin: 0;
  padding-top: 10px;
}
.profile .userImage .viewProfile {
  font-size: 15px;
  line-height: 25.5px;
  margin: 0;  
  text-transform: uppercase;
  color: #FFB200;
  font-weight: 600;
}

.profile .logout {
  font-size: 15px;
  line-height: 25.5px;
  margin-top: 50px;
  text-transform: uppercase;
  color: #FFB200;
  font-weight: 600;
}

.profile button.btn {
  width: 90%;
  border-radius: 10px;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  border: none;
  padding: 10px 10px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.profile button.btn .title {
  font-family: Poppins;
  font-size: 15px;
  line-height: 25.5px;
  margin: 0;
  font-weight: 400;
  text-transform: capitalize;
  color: #212121;
  padding-left: 10px;
}
button.btn .arrrowRight {
  position: absolute;
  right: 5px;
  top: 7px;
}




.profile .profileInner {
  padding-bottom: 30px;
}

.profile .profileInner .SubscriptionCard {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  margin: 30px 15px;
  padding: 15px;
  background: #FFFCF5;
  margin-top: -60px;
  padding-top: 70px;
}
.profile .profileInner .SubscriptionCard .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DBC48E;
}
.profile .profileInner .SubscriptionCard .header .title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  margin: 0;
  color: #212121;
}
.profile .profileInner .SubscriptionCard .header .status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.profile .profileInner .SubscriptionCard .header .status .active {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #179C4D;
  margin-right: 5px;
}
.profile .profileInner .SubscriptionCard .header .status .text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  margin: 0;
  color: #212121;
}
.profile .profileInner .SubscriptionCard .header .status .text.activeText {
  text-transform: uppercase;
}
.profile .profileInner .SubscriptionCard .desc {
  font-size: 30px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: #212121;
}
.profile .profileInner .SubscriptionCard .desc .amount {
  font-family: Poppins;
}
.profile .profileInner .SubscriptionCard .desc .subText {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #8C8C8C;
}

.profile .profileInner .SubscriptionCard .benefits ul li {
  list-style-image: url('../../../assets/Phase2/beneIcon.svg');
  padding-inline-start: 1ch;
  padding-bottom: 15px;
}

.profile .profileInner .SubscriptionCard .buySubscriptionOuter {
  text-align: center;
}
.profile .profileInner .SubscriptionCard .buySubscriptionOuter .btn.buySubscription {
  border: none;
  background: linear-gradient(97.47deg, #FFB200 0%, #EEAA0C 100%);
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 10px;
}

.profile .profileInner .historyTitle {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 30px;
  margin-bottom: -20px;
}
.profile .profileInner .SubscriptionCard .history {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
.profile .profileInner .SubscriptionCard .history .left {
  width: 50%;
}
.profile .profileInner .SubscriptionCard .history .right {
  width: 50%;
}
.profile .profileInner .SubscriptionCard .history .title {
  font-size: 15px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 0;
  margin-top: 20px;
}
.profile .profileInner .SubscriptionCard .history .subTitle {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  margin: 0;
}

.profile .profileInner .mainTitle {
  font-family: Poppins;
  font-size: 35px;
  font-weight: 400;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  display: none;
}
.profile .profileInner .subscriptionPlanListInner .first {
  box-shadow: none;
  border: none;
  border-radius: 0;
  padding: 0px;
}
.profile .profileInner .subscriptionPlanListInner .first .plansTitle {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
  padding: 0 10px;
  margin-bottom: -20px;
  display: none;
  margin-top: 0;
}
.profile .profileInner .SubscriptionCard .benefits .title {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5px;
  color: #8C8C8C;
}
.profile .profileInner .SubscriptionCard .benefits ul {
  padding-inline-start: 30px;
}


.profile .heading {
  position: absolute;
  top: 8px;
  display: flex;
  gap: 10px;
  left: 10px;
  width: max-content;
}
.profile .heading p {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
.profile .editBtn button.btn.edit {
  width: auto;
  box-shadow: none;
  background: transparent;
  border: none;
  height: 46px;
  display: block;
  position: absolute;
  top: 4px;
  right: 90px;
  margin: 0;
}
.profile .editBtn button.btn.edit .title {
  color: #EEAA0C;
  padding: 0;
  font-weight: 600;
  text-transform: uppercase;
}
.header-jc-sp-c {
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
.profile .mob-header-title {
  font-size: 35px;
}



@media (min-width: 768px) {
  .profile .heading {
    position: relative;
  }
  .profile .profileInner .mainTitle {
    display: block;
  }
  .profile .profileInner .SubscriptionCard .benefits .title {
    font-size: 17px;
  }
  .profile .profileInner .subscriptionPlanListInner .first {
    box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
    border: 1px solid #EEAA0C;
    border-radius: 10px;
    padding: 30px 20px;
    width: 65%;
  }
  .profile .profileInner .subscriptionPlanListInner {
    display: flex;
    justify-content: space-between;
    margin-top: -60px;
    padding: 30px 0px;
  }
  .profile .profileInner .subscriptionPlanListInner .second {
    width: 50%;
  }
  .profile .profileInner .subscriptionPlanListInner .second .SubscriptionCard {
    margin-right: 0;
  }
  .profile .profileInner .subscriptionPlanListInner .first .plansTitle {
    display: block;
  }
  .profile .profileInner .subscriptionPlanListInner .second .historyTitle {
    margin-top: 0;
    padding-left: 15px;
    font-size: 25px;
  }
  .profile .profileInner .SubscriptionCard .header .title {
    font-size: 21px;
  }
  .profile .profileInner .SubscriptionCard .header .status .text {
    font-size: 15px;
  }
  .profile .profileInner .SubscriptionCard .buySubscriptionOuter .btn.buySubscription {
    font-size: 17px;
  }
  .profile .profileInner {
    background: url("../../../assets/Phase2/profileBg.svg");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    position: relative;
    margin-top: 40px;
    margin-right: -30px;
  }
  .profile .editBtn button.btn.edit {
    box-shadow: 1px 3px 15px 0px #00000026;
    background: linear-gradient(97.47deg, #FFB200 0%, #EEAA0C 100%);
    width: 10%;
  }
  .profile .editBtn button.btn.edit .title {
    color: #fff;
  }



}

@media (max-width: 767px) {
  .header-jc-sp-c {
    padding: 0;
  }
  .profile .profileInner .SubscriptionCard .history .title {
    font-size: 13px;
  }
  .profile .profileInner .SubscriptionCard .history .subTitle {
    font-size: 15px;
  }
  .profile .header-create-btn {
    font-size: 15px;
  }

  

}

@media (min-width: 991px) {
  .profile .profileInner {
    /* padding: 0 100px; */
  }
}




@media (max-width: 767px) {
  .profile {
    height: 100vh;
    max-height: calc(100vh - 80px);
    padding-top: 85px;
  }
  .profile .mob-header-title {
    font-size: 20px;
  }
  .profile .profileInner {
    margin-top: -30px;
    padding-bottom: 190px;
  }
  .subscriptionPlanListInner {
    padding: 0px 5px;
  }
  .profile .profileInner {
    padding-bottom: 200px;
  }


}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .profile .mob-header-title {
    font-size: 33px;
  }
  .profile .profileInner {
    background-size: 50% 60%;
    padding-bottom: 250px;
  }
  .profile .profileInner .subscriptionPlanListInner .second {
    width: 60%;
  }



}