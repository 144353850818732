.img-banner {
  width: 100%;
  height: 270px;
  display: flex;
}

.button-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.playlist-title{
  color: #212121;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-left: 10px;
}

.accept-button,
.reject-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.accept-button {
  background-color: #f6ae06;
  color: white;
}

.reject-button {
  background-color: #f6ae06;
  color: white;
}

.sharedPlaylistVideo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: calc(100vh - 80px);
}
.sharedPlaylistVideo .sharedPlaylistVideoInner {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  width: 50%;
}
.sharedPlaylistVideo .sharedPlaylistVideoInner .list-image .img-banner {
  aspect-ratio: 1/1;
  height: auto;
}
.sharedPlaylistVideo .sharedPlaylistVideoInner .button-container {
  padding: 20px 0px;
  position: relative;
}


@media (max-width: 767px) {
  .sharedPlaylistVideo .sharedPlaylistVideoInner {
    width: 90%;
  }
}
