/*----- New CSS Start -----*/
.advance-search {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 105px;
  padding-bottom: 60px;
}

.advance-search-tab-a-center {
  align-self: center;
  padding: 0 5px;
}

.advance-search-mt-20 {
  margin-top: 20px;
}

.advance-search-col-count-3 {
  column-count: 3;
  margin-top: 10px;
  display: flex;
}

.advance-search-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  /* height: 396px; */
  position: relative;
}


.advance-search-inside-wrap {
  display: block;
  width: 100%;
  background-color: #FFFCF5;
}

.advance-search-inside-wrap .advance-search-list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
  background: #000000ba;
}

.advance-search-inside-wrap .advance-search-img-banner {
  width: 100%;
  /* height: 170px; */
  display: flex;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
}

.advance-search-play-icon {
  position: absolute;
  bottom: -32px;
  right: 0px;
}

.advance-search-card-content-item {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.advance-search .advance-search-tab-a-center .MuiTabs-scroller {
  overflow: auto !important;
}

.advance-search-video-content {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.advance-search-video-content img {
  padding-right: 10px;
}

.advance-search-title-text {
  font-size: 17px;
  color: #212121;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.advance-search-text-hrs {
  font-size: 15px;
  color: #8C8C8C;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.advance-search-mt-10 {
  margin-top: 10px;
}

.advance-search-ta-left {
  text-align: left;
  display: flex;
}


/*----- Filter Modal Start CSS -----*/
.filter-search-modal-content {
  display: flex;
  padding-bottom: 20px;
}

.filter-search-modal-msg-text {
  font-size: 25px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  width: 90%;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.filter-search-modal-close-button {
  color: #FFB200;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  width: 10%;
  text-align: end;
}

.filter-search-modal-close-button:hover,
.filter-search-modal-close-button:focus {
  color: #FFB200;
  text-decoration: none;
  cursor: pointer;
}

.filter-search-modal-comm-font-13 {
  font-size: 15px;
  color: #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.filter-search-modal-comm-font-10 {
  font-size: 15px;
  color: #cf1111;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.filter-search-modal-action-content {
  display: flex;
  padding: 12px;
  margin-top: 10px;
}

.filter-search-modal-apply-btn {
  font-size: 17px;
  width: 100px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid;
  border-color: #FFB200;
  background-color: #FFB200;
  color: #FFFFFF;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.filter-search-modal-cancel-btn {
  font-size: 17px;
  width: 100px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0px;
  border-color: transparent;
  background-color: transparent;
  color: #FFB200;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.filter-search-modal-mt-15 {
  margin-top: 15px;
}

.filter-search-modal-today {
  color: var(--black, #212121);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.filter-search-modal-d-flex {
  display: flex;
  flex-wrap: wrap;
}

.filter-search-modal-mt-5 {
  margin-top: 5px;
}

.filter-search-modal-mt-10 {
  margin-top: 10px;
}

.filter-search-modal-mlr-8 {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 10px;
}

.filter-search-modal-upload-dur-div {
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  background-color: #FFFCF5;
  box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.filter-search-modal-location-div {
  width: 100%;
  height: 46px;
  border-radius: 10px;
  background-color: #FFFCF5;
  box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.15);
}

/*----- Filter Modal End CSS -----*/

.mobile-search-header {
  padding: 5px 15px;
  background-color: #FDF5E2;
  border-bottom: 1px solid #DBC48E;
  height: 45px;
  display: none;
}

.filter-search-modal-upload-dur-div.active {
  border: 1px solid #FFB200;
}

.filter-search-modal-upload-dur-div.active .filter-search-modal-today {
  color: #FFB200;
  font-weight: 500;
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  font-size: 17px;
}

.advanceEbbok .advance-search-inside-wrap .advance-search-img-banner {
  aspect-ratio: 9/14;
  object-fit: unset;
}

.advanceEbbok .custom-col-4 {
  width: 20%;
}

.advanceEbbok.advancePad .custom-col-4 {
  width: 33.33%;
}

.advanceEbbok.advancePad .advance-search-inside-wrap .advance-search-img-banner {
  aspect-ratio: 16/9;
}

/* BhaktiPad CSS */


/* 
.advance-search {
  padding: 0px 15px;
  padding-bottom: 50px;
  padding-top: 105px;
  position: relative;
} */


/* 
.advance-search .top {
  margin-top: 43px;
  box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
  border: 1px solid #EEAA0C;
  border-radius: 10px;
  padding: 22px;
  display: flex;
  align-items: center;
  gap: 22px;
} */

.advance-search .top .topImg {
  width: 131px;
  height: 131px;
  border-radius: 8px;
}

.advance-search .top .label {
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  color: #212121;
  margin: 0;
  text-transform: uppercase;
  font-family: Poppins;
}

.advance-search .mainTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
}

.advance-search .mainTitleOuter .viewMore {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
}

.advance-search .mainTitleOuter .mainTitle {
  letter-spacing: 0em;
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
  margin: 0;
}

.advance-search .aboutUs-img-div {
  margin-bottom: 15px;
  text-align: center;
}

.advance-search .aboutUs-img-div .AbousImage {
  width: 100%;
  margin: 15px auto;
  border-radius: 10px;
}

.advance-search .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  padding: 15px;
  background: #fffcf5;
}

.advance-search .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.advance-search .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.advance-search .table-responsive {
  overflow: auto;
}

.advance-search table {
  width: 100%;
}

.advance-search .table-responsive .table th,
.advance-search .table-responsive .table td {
  border: none;
}

.advance-search .table-responsive .table th {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  border-bottom: 1px solid #DBC48E;
}

.advance-search .table-responsive .table .srNo {
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  margin: 0;
  text-align: left;
}

.advance-search .table-responsive .table .srNo .no {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  color: #212121;
  width: 30px;
}

.advance-search .table-responsive .table .srNo .bhaktiImg {
  width: 60px;
  height: 60px;
  border-radius: 8px;
}

.advance-search .table-responsive .table .titleSection .title,
.advance-search .table-responsive .table .titleSection .subTitle {
  margin: 0;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
  min-width: 400px;
}

.advance-search .table-responsive .table .duration {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
}

.advance-search .table-responsive .table .album {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  margin: 0;
  color: #6B6B6B;
}

.advance-search .table-responsive .table .actions {
  display: flex;
  gap: 15px;
  align-items: center;
  /* position: relative; */
}

.advance-search .tableHover:hover {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
}

.advance-search .actionPopupMob {
  right: 10px;
  top: 30px;
  border-radius: 4px 10px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 0px 15px 12px 2px;
}

.advance-search .actionPopupMob .outer {
  display: flex;
  gap: 6px;
  align-items: center;
  width: max-content;
}

.advance-search .actionPopupMob .outer .label {
  font-family: Poppins;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #212121;
  margin: 0;
}

.advance-search .popupOuter {
  position: relative;
  z-index: 1;
}

.advance-search .morePopup {
  position: absolute;
  right: 10px;
  top: 30px;
  background: #FDFDFD;
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 4px 10px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 12px 15px;
  z-index: 9;
  display: block;
}

.advance-search .table-responsive .table tbody tr:nth-last-child(1) .morePopup {
  top: -5px;
}

.advance-search .morePopup .outer {
  display: flex;
  gap: 10px;
  align-items: center;
  width: max-content;
}

.advance-search .morePopup .outer .label {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8c8c8c;
  margin: 0;
}

.advance-search .padPlay {
  box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 15px 0px;
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  cursor: pointer;
}

.advance-search .padPlay.active {
  border: 1px solid #EEAA0C;
}

.advance-search .padPlay .padPlayImg {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

.advance-search .padPlay .details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.advance-search .padPlay .details .main {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  margin: 0;
  text-align: left;
}

.advance-search .padPlay .details .sub {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 300;
  line-height: 19.5px;
  color: #878787;
  margin: 0;
  text-align: left;
}

.advance-search .padPlay .player {
  width: 100%;
  border-radius: 0px 0px 10px 10px;
}




/* album css */

.advance-search .albumOuter {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  grid-gap: 25px;
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  margin-top: 30px;
}

.advance-search .album {
  /* background: #fff; */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  /* width: calc(33.333% - 16px); */
}

.advance-search .albumTop {
  position: relative;
}

.advance-search .albumTop {
  background: #fff;
  border-radius: 10px;
  position: relative;
  box-shadow: 1px 3px 15px 0px #00000026;
}

.advance-search .albumTop.albumLockOuter .albumHover {
  opacity: 1 !important;
}

.advance-search .albumTop.albumLockOuter .albumHover .albumHoverInner {
  opacity: 0;
}

.advance-search .albumImg {
  width: 100%;
  height: auto;
}

.advance-search .albumHover {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}

.advance-search .album:hover .albumHover {
  display: flex;
}

.advance-search .albumHoverInner {
  display: flex;
  gap: 8px;
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 99;
}


.advance-search .albumInnerImg {
  width: 24px;
  height: 24px;
}

.advance-search .albumInnerPlayIconOuter {
  position: absolute;
  bottom: 16px;
  right: 16px;
  cursor: pointer;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 40%;
}

.advance-search .albumInnerPlayIcon {
  width: 48px;
  height: 48px;
}

.advance-search .albumBottom {
  padding: 16px;
  font-family: Roboto, sans-serif !important;
}

.advance-search .name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: left;
  margin: 0;
}

.advance-search .desc {
  display: flex;
  align-items: center;
}

.advance-search .time,
.count {
  font-size: 14px;
  color: #666;
}

.advance-search .dot {
  width: 4px;
  height: 4px;
  background: #666;
  border-radius: 50%;
  margin: 0 8px;
}

.advance-search .albumOuter.noData {
  display: flex;
}

@media (max-width: 991px) {

  .advance-search .morePopup {
    display: none;
  }
  .advance-search .table-responsive .table .titleSection .title,
  .advance-search .table-responsive .table .titleSection .subTitle {
    max-width: 290px;
    min-width: auto;
  }
}


@media screen and (max-width: 767px) {
  .advance-search .albumOuter {
    display: flex;
  }

  .advance-search .table-responsive .table .srNo .no.mobHide {
    display: none;
  }

  .advanceEbbok .custom-col-4 {
    width: 100%;
  }

  .advance-search .table-responsive .table .titleSection .title,
  .advance-search .table-responsive .table .titleSection .subTitle {
    max-width: 180px;
  }

  .advance-search .table-responsive .table .actions .favTooltip {
    display: none;
  }

  .advance-search .css-1w5fbjm-MuiTabs-root .MuiTabs-indicator {
    background: transparent;
  }
  .advanceEbbok.advancePad .custom-col-4 {
    width: 100%;
  }
  .advance-search {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0px;
    padding-bottom: 60px;
  }

  .advance-search-card-wrap {
    box-shadow: 1px 3px 15px 0px #00000026;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
  }

  .advance-search-inside-wrap .advance-search-img-banner {
    /* height: 120px; */
  }

  .advance-search-col-count-3 {
    column-count: 1;
    margin-top: 10px;
  }

  /*----- Mobile header Search Bar Start CSS -----*/
  .mobile-search-header {
    padding: 5px 15px;
    background-color: #FDF5E2;
    border-bottom: 1px solid #DBC48E;
    height: 45px;
    display: block;
  }

  .mobile-search-header .back-arrow {
    height: 30px;
    width: 30px;
    margin-top: 8px;
  }

  .mobile-search-wrap {
    display: flex;
    justify-content: space-between;
    width: 480px;
  }

  .mobile-search-wrap .mobile-search-input {
    position: relative;
    width: 100%;
  }

  .mobile-search-wrap .mobile-search-icon {
    position: absolute;
    top: 13px;
    right: 0px;
  }

  .mobile-search-wrap .mobile-search-input input {
    height: 46px;
    line-height: 46px;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 11px;
    border: none;
    box-shadow: 1px 3px 15px 0px #FDF5E2;
    background: #FDF5E2;
    color: #B1AFAB;
    padding-left: 10px;
    font-size: 15px;
    width: 100%;
  }

  .mobile-search-wrap .mobile-filter-icon {
    width: 42px;
    align-items: center;
    justify-content: end;
    display: flex;
    margin-right: 5px;
  }

  /*----- Search Bar End CSS -----*/

  /*----- Filter Mobile Modal Start -----*/
  .filter-search-modal-content {
    display: flex;
    padding-bottom: 20px;
  }

  .filter-search-modal-action-content {
    display: flex;
    padding: 7px;
    margin-top: 10px;
  }

  .filter-search-modal-location-div {
    width: 100%;
    height: 46px;
    border-radius: 10px;
    background-color: #FFFCF5;
    box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.15);
  }

  /*----- Filter Mobile Modal End -----*/

  .advance-search-title-text {
    font-size: 15px;
  }

  .advance-search-text-hrs {
    font-size: 13px;
  }

  .advance-search .custom-col {
    padding-left: 5px;
    padding-right: 5px;
  }

  .filter-search-modal-msg-text {
    font-size: 20px;
  }

  .filter-search-modal-comm-font-13 {
    font-size: 13px;
  }
  .filter-search-modal-comm-font-10 {
    font-size: 8px;
  }

  .filter-search-modal-apply-btn {
    font-size: 15px;
  }

  .filter-search-modal-cancel-btn {
    font-size: 15px;
  }

  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    font-size: 15px;
  }
  .advance-search .trackPlayButton {
    left: 1px;
    top: 2px;
  }


}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .filter-search-modal-msg-text {
    font-size: 23px;
  }
  .advanceEbbok.advancePad .custom-col-4 {
    width: 50%;
  }

  .advanceEbbok .custom-col-4 {
    width: 33.33%;
  }
}

/*----- New CSS End -----*/