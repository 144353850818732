/*----- New CSS Start -----*/
.bhaktiPadsTabBar {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.bhaktiPadsTabBar .albumOuter {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 25px;
}

.bhaktiPadsTabBar .albumOuter .album {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bhaktiPadsTabBar .albumOuter .album .albumTop {
  background: #fff;
  border-radius: 10px;
  position: relative;
  box-shadow: 1px 3px 15px 0px #00000026;
}

.bhaktiPadsTabBar .albumOuter .album .albumTop .albumImg {
  width: 100%;
  height: 100%;
}

.bhaktiPadsTabBar .albumOuter .album .albumTop .albumHover {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  border-radius: 10px;
}

.bhaktiPadsTabBar .albumOuter .album .albumTop .albumHover::before {
  content: '';
  position: absolute;
  background: #000;
  opacity: 0.5;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  border-radius: 10px;
}

.bhaktiPadsTabBar .albumOuter .album .albumTop:hover .albumHover {
  opacity: 1;
}

.bhaktiPadsTabBar .albumOuter .album .albumTop .albumHover .albumInnerPlayIconOuter {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: -webkit-fill-available;
}

.bhaktiPadsTabBar .albumOuter .album .albumTop .albumHover .albumInnerPlayIconOuter .albumInnerPlayIcon {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 48px;
  height: 48px;
}

.bhaktiPadsTabBar .albumOuter .album .albumTop .albumHover .albumHoverInner {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 9;
}

.bhaktiPadsTabBar .albumOuter .album .albumBottom .name {
  color: #212121;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bhaktiPadsTabBar .albumOuter .album .albumBottom .desc {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}

.bhaktiPadsTabBar .albumOuter .album .albumBottom .desc .time {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
  /* width: 70px; */
  margin-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: none;
}

.bhaktiPadsTabBar .albumOuter .album .albumBottom .desc .dot {
  width: 7px;
  height: 7px;
  background: #BCBCBC;
  border-radius: 50px;
  margin: 0;
}

.bhaktiPadsTabBar .albumOuter .album .albumBottom .desc .count {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-responsive .table {
  margin: 0;
}

@media (max-width: 991px) {
  .bhaktiPadsTabBar .top .label {
    font-size: 32px;
  }

  .bhaktiPadsTabBar .table-responsive .table th.none {
    display: none;
  }

  .bhaktiPadsTabBar .table-responsive .table td.none {
    display: none;
  }

  .bhaktiPadsTabBar .table-responsive .table .titleSection {
    max-width: 290px;
  }

  /* .bhaktiPadsTabBar .mainTitleOuter .mainTitle {
    font-size: 23px;
    line-height: 34.5px;
  } */

  .bhaktiPadsTabBar .albumOuter {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .bhaktiPadsTabBar .albumOuter .album:nth-of-type(n+4) {
    /* display: none; */
  }



  .bhaktiPadsTabBar .albumOuter {
    gap: 20px;
  }
}


@media (max-width: 767px) {
  .bhaktiPadsTabBar .top .label {
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }


  .bhaktiPadsTabBar{
    padding-left:10px;
    padding-right:10px;
  }

  /* .bhaktiPadsTabBar .mainTitleOuter .mainTitle {
    font-size: 18px;
    line-height: 27px;
  }

  .bhaktiPadsTabBar .mainTitleOuter .viewMore {
    height: auto;
    padding: 0;
    font-size: 13px;
    line-height: 19.5px;
  }

  .bhaktiPadsTabBar .mainTitleOuter {
    padding-top: 30px;
  } */

  /* .mobHide {
    display: none;
  } */

  .bhaktiPadsTabBar .table-responsive .table .actions .more {
    transform: rotate(90deg);
  }

  .bhaktiPadsTabBar .table-responsive .table .srNo .bhaktiImg {
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }

  .bhaktiPadsTabBar .table-responsive .table .titleSection .title {
    font-size: 15px;
    line-height: 22.5px;
  }

  .bhaktiPadsTabBar .table-responsive .table .titleSection .subTitle {
    font-size: 13px;
    line-height: 19.5px;
  }

  .bhaktiPadsTabBar .table-responsive .table td {
    padding: 10px;
  }

  .bhaktiPadsTabBar .table-responsive .table .titleSection {
    max-width: 210px;
  }

  .bhaktiPadsTabBar .albumOuter .album .albumBottom .name {
    font-size: 12px;
    line-height: 18px;
  }

  .bhaktiPadsTabBar .albumOuter .album .albumBottom .desc .time {
    font-size: 10px;
    line-height: 15px;
  }

  .bhaktiPadsTabBar .albumOuter .album .albumBottom .desc .dot {
    width: 4px;
    height: 4px;
  }

  .bhaktiPadsTabBar .albumOuter .album .albumBottom .desc .count {
    font-size: 10px;
    line-height: 15px;
  }

  .bhaktiPadsTabBar .albumOuter {
    gap: 7.5px;
  }

  /* .bhaktiPadsTabBar .list-of-play-search {
    display: block;
  } */
  .bhaktiPadsTabBar .albumOuter .album .albumTop .albumHover .albumHoverInner {
    position: absolute;
    bottom: 0px;
    right: 10px;
    display: flex;
    gap: 20px;
    z-index: 9;
  }

  .bhaktiPadsTabBar .albumOuter .album .albumTop .albumHover .albumInnerPlayIconOuter .albumInnerPlayIcon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    top: 20px;
  }
}
