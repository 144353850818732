/* UnderDevelopment.css */

body {
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
  
  .under-development-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .content {
    text-align: center;
  }
  
  h1 {
    color: #333;
  }
  
  p {
    color: #555;
    margin-bottom: 20px;
  }
  
  .construction-image {
    width: 300px;
    height: auto;
  }
  