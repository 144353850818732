.side-menu-bar {
  width: 240px;
  height: auto;
  flex-shrink: 0;
  background: linear-gradient(90deg, #FDF4E0 0%, rgba(253, 244, 224, 0.00) 100%);
  box-shadow: 1px 3px 25px 0px rgba(0, 0, 0, 0.15);
  /* padding: 15px; */
  border-right: 1px solid #DBC48E;
  /* height: 100vh;
  overflow: auto;
  overflow-x: hidden; */
  position: fixed;
  left: 0;
  z-index: 999;
}
.side-menu-bar.viewMore {
 margin-top: 0px;
  overflow: inherit;
}

.side-menu-bar-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  padding: 15px;
}
.sidemenuInner {
  padding: 0px 0px 0px 15px;
  border-right: 1px solid #DBC48E;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 182px);
}
.sidemenuInner.loggedOut {
  max-height: calc(100vh - 127px);
}
.logoutOuter {
  margin-left: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
  background: var(--primary-gold-gradient, linear-gradient(135deg, #FFB200 0%, #EEAA0C 100%));
  border-radius: 10px 0px 0px 10px;
}


.main-logo{
  width: 120px;
  cursor: pointer;
}
.side-menu-bar-item {
    width: 210px;
    height: 46px;
    flex-shrink: 0;
    display: flex;
    padding-left: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.side-menu-bar-item:hover {
    width: 210px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 10px 0px 0px 10px;
    background: var(--primary-gold-gradient, linear-gradient(135deg, #FFB200 0%, #EEAA0C 100%));
    box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    padding-left: 15px;
    margin-bottom: 15px;
}

.side-menu-bar-item-active {
    width: 210px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 10px 0px 0px 10px;
    background: var(--primary-gold-gradient, linear-gradient(135deg, #FFB200 0%, #EEAA0C 100%));
    box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    padding-left: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.side-menu-bar-item-img {
    height: 28px;
    width: 28px;
    align-self: center;
    margin-right: 5px;
}

.side-menu-bar-item-text {
    color: #212121;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
    align-self: center;
    padding-left: 10px;
}

.side-menu-bar-item-active .side-menu-bar-item-text{
    font-weight: 600;
    color: #fff;
}

.side-menu-bar-item-active .side-menu-bar-item-img,.side-menu-bar-item:hover .side-menu-bar-item-img {
    filter: brightness(0) invert(1);
}
.side-menu-bar-item:hover .side-menu-bar-item-text {
  color: #FFF;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-align: left;
  align-self: center;
}

.side-menu-bar-border {
  width: 200px;
  height: 1px;
  flex-shrink: 0;
  background: #DBC48E;
  margin-bottom: 20px;
  margin-top: 20px;
}

.logoutOuter .logout {
  display: none;
  height: 24px;
}
.logoutOuter .logoutActive {
  display: block;
  height: 24px;
}
/* .side-menu-bar-item.logoutOuter:hover .side-menu-bar-item .logout {
  display: none;
}
.side-menu-bar-item.logoutOuter:hover .side-menu-bar-item .logoutActive {
  display: block;
} */
.sidemenuInner .side-menu-bar-item:nth-last-child(1) {
  margin-bottom: 5px;
}
.side-menu-bar-item.logoutOuter .side-menu-bar-item-text {
  color: #fff;
  font-weight: 600;
}



@media screen and (max-width: 767px) {
  .side-menu-bar {
      display: none;
  }
  .main-logo {
      width: 80px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .side-menu-bar {
    width: 100px;
    padding: 15px 0px;
  }
  .main-logo {
    width: 84px;
  }
  .side-menu-bar .side-menu-bar-item-active, 
  .side-menu-bar .side-menu-bar-item {
    width: 95px;
    display: block;
    text-align: center;
    height: auto;
    padding: 10px 0px;
    margin-left: 5px;
  }
  .side-menu-bar .side-menu-bar-item-active .side-menu-bar-item-text, 
  .side-menu-bar .side-menu-bar-item .side-menu-bar-item-text {
    text-align: center;
    padding: 0;
    font-size: 12px;
    margin: 0;
  }
  .side-menu-bar-border {
    width: 100px;
  }

  .header-wrapper{
    padding-left: 130px;
  }
  .side-menu-bar-item-img {
    margin-right: 0px !important;
  }

  .side-menu-bar-logo {
    padding: 0;
  }
  .sidemenuInner {
    padding: 0;
  }
  .side-menu-bar-item .logout {
    margin: 0 auto;
    margin-right: auto !important;
    margin-bottom: 5px;
  }
  .side-menu-bar-item.logoutOuter {
    display: grid;
    justify-items: center;
  }
  
}


@media screen and (min-width: 992px) {
  .header-wrapper{
      padding-left: 270px;
  }
}

