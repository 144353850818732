.playlist-page {
  padding: 0px 15px;
  padding-bottom: 50px;
  padding-top: 105px;
  position: relative;
}

.playlist-page .playlist-page-title-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  width: 100%;
}

.playlist-page .playlist-page-title-outer .playlist-page-title {
  width: 100%;
}

.playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex {
  padding: 0;
  width: 100%;
  gap: 24px;
  align-items: flex-start;
}

.playlist-page .playlist-page-title-outer .list-of-play-search {
  display: none;
}

.playlist-page .top {
  box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
  border: 1px solid #EEAA0C;
  border-radius: 10px;
  padding: 22px;
  display: flex;
  align-items: center;
  gap: 22px;
  width: 100%;
  overflow: auto;
  background: #FFFAF0;
}

.favTooltip:hover .tooltiptext {
  left: auto;
  right: 0;
}


.playlist-page .top .padDetail {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  overflow: auto;
}

.playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex .top .padDetail .category {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  color: #212121;
  margin: 0;
}

.playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex .top .padDetail .label {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  text-align: left;
  color: #212121;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex .top .padDetail .padDetailInner {
  display: flex;
  align-items: center;
  gap: 8px;
}

.playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex .top .padDetail .padDetailInner .year {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
}

.playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex .top .padDetail .padDetailInner .dot {
  width: 7px;
  height: 7px;
  background: #BCBCBC;
  border-radius: 50px;
}

.playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex .top .padDetail .padDetailInner .time {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
}

.playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex .top .padDetail .padDetailInner .count {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
}







.playlist-page .top .topImg {
  width: 131px;
  height: 131px;
  border-radius: 8px;
}

.playlist-page .top .label {
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  color: #212121;
  margin: 0;
  text-transform: uppercase;
  font-family: Poppins;
}

.playlist-page .mainTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
}

.playlist-page .mainTitleOuter .viewMore {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}

.playlist-page .mainTitleOuter .mainTitle {
  letter-spacing: 0em;
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
  margin: 0;
}

.playlist-page .aboutUs-img-div {
  margin-bottom: 15px;
  text-align: center;
}

.playlist-page .aboutUs-img-div .AbousImage {
  width: 100%;
  margin: 15px auto;
  border-radius: 10px;
}

.playlist-page .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  padding: 15px;
  background: #fffcf5;
}

.playlist-page .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;

}

.playlist-page .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.table-responsive {
  overflow: auto;
}




.playlist-page .tableHover:hover {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
}

.srNo {
  position: relative;
}


/* Show the play button on row hover */


/* Table css */
.playlist-page .table-responsive .table th,
.playlist-page .table-responsive .table td {
  border: none;
}

.playlist-page .table-responsive .table th:nth-of-type(1) {
  padding-left: 0;
}

.playlist-page .table-responsive .table td:nth-of-type(1) {
  padding-left: 0;
}

.playlist-page .table-responsive .table td:nth-last-child(1) {
  padding-right: 0;
}

.playlist-page .table-responsive .table th {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
}

.playlist-page .table-responsive .table th {
  border-bottom: 1px solid #DBC48E;
}

.playlist-page .table-responsive .table .srNo {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  margin: 0;
  text-align: left;
}

.playlist-page .table-responsive .table .srNo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.playlist-page .table-responsive .table .srNo .no {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  color: #212121;
}

.playlist-page .table-responsive .table .srNo .bhaktiImg {
  width: 60px;
  height: 60px;
  border-radius: 8px;
}

.playlist-page .table-responsive .table .titleSection .title {
  margin: 0;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
  min-width: 400px;
}

.playlist-page .table-responsive .table .titleSection .subTitle {
  margin: 0;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  color: #6B6B6B;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.playlist-page .table-responsive .table .album {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  margin: 0;
  color: #6B6B6B;
}

.playlist-page .table-responsive .table .duration {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
}

.playlist-page .table-responsive .table .actions {
  display: flex;
  gap: 15px;
  align-items: center;
  position: relative;
}


.playlist-page .top .label {
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  color: #212121;
  margin: 0;
  text-transform: uppercase;
  font-family: Poppins;
}

.playlist-page .mainTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
}

.playlist-page .mainTitleOuter .viewMore {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
}

.playlist-page .mainTitleOuter .mainTitle {
  letter-spacing: 0em;
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
  margin: 0;
}

.playlist-page .aboutUs-img-div {
  margin-bottom: 15px;
  text-align: center;
}

.playlist-page .aboutUs-img-div .AbousImage {
  width: 100%;
  margin: 15px auto;
  border-radius: 10px;
}

.playlist-page .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  padding: 15px;
  background: #fffcf5;
}

.playlist-page .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;

}

.playlist-page .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.table-responsive {
  overflow: auto;
}

.playlist-page .table-responsive tbody tr:nth-last-child(1) .morePopup {
  top: -5px;
  bottom: auto;
  right: 30px;
}

/* Table css */

/* player css */
.playlist-page .padPlay {
  box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
  border-top: 1px solid #FFB200;
  display: inline-flex;
  gap: 15px;
  align-items: center;
  position: fixed;
  bottom: 0;
  padding: 12px 45px;
  background: #FFFAF0;
  justify-content: space-between;
  left: 240px;
  width: 100%;
  overflow: visible;
  max-width: calc(100% - 322px);
}

.playlist-page .padPlay .padPlayImg {
  width: 86px;
  height: 86px;
}

.playlist-page .padPlay .details {
  width: 305px;
}

.playlist-page .padPlay .details .title {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  color: #212121;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.playlist-page .padPlay .details .subTitleOuter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.playlist-page .padPlay .details .subTitleOuter .subTitle {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  margin: 0;
  color: #6B6B6B;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.playlist-page .padPlay .player {
  display: flex;
  flex-direction: column;
}

.playlist-page .padPlay .player .controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.playlist-page .padPlay .player .controls .padPlayInner {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  box-shadow: 1px 3px 15px 0px #00000026;
}

.playlist-page .padPlay .player .controls .padPlayInner .playIcon {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.playlist-page .padPlay .player .playLine {
  display: flex;
  align-items: center;
  gap: 10px;
}

.playlist-page .padPlay .player .playLine .songTime {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #6B6B6B;
  margin: 0;
}

.playlist-page .padPlay .player .playLine .time {
  position: relative;
  display: flex;
  align-items: center;
}

.playlist-page .padPlay .player .playLine .songRemainingTme {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #6B6B6B;
  margin: 0;
}

.playlist-page .padPlay .player .playLine .time .runningBar {
  position: absolute;
  left: 0;
  width: 150px;
  height: 2px;
}

.playlist-page .padPlay .player .playLine .time .playDot {
  position: absolute;
  left: 150px;
  width: 6px;
  height: 6px;
}

.playlist-page .padPlay .player .playLine .time .playBar {
  width: 100%;
  height: 2px;
}

.playlist-page .padPlay .volumeController {
  display: flex;
  gap: 10px;
  align-items: center;
}

.playlist-page .padPlay .volumeController .time {
  position: relative;
  display: flex;
  align-items: center;
  width: 80px;
}

.playlist-page .padPlay .volumeController .time .runningBar {
  position: absolute;
  left: 0;
  width: 30px;
  height: 2px;
}

.playlist-page .padPlay .volumeController .time .playDot {
  position: absolute;
  left: 30px;
  width: 6px;
  height: 6px;
}

.playlist-page .padPlay .volumeController .time .playBar {
  width: 100%;
  height: 2px;
}

/* player css */
.playlist-page .controlsTop {
  display: flex;
  align-items: baseline;
  gap: 15px;
  padding-bottom: 20px;
  padding-top: 15px;
}

.playlist-page .controlsTop .playIcon {
  width: 36px;
  height: 36px;
}

.playlist-page .controlsTop .moreImg {
  /* transform: rotate(90deg); */
}

.playlist-page .controlsTop .padPlayInner {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  box-shadow: 1px 3px 15px 0px #00000026;
}

.playlist-page .controlsTop .padPlayInner .pauseIcon {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 24px;
  height: 24px;
}

.playlist-page .controlsTop .padPlayInner .pauseIcon.playIcon {
  left: 5px;
  width: 16px;
  height: 20px;
}



.playlist-page .padPlay .details .subTitleOuter .infoOuter {
  position: relative;
}

.playlist-page .padPlay .details .subTitleOuter .infoOuter:hover .infoPopup {
  opacity: 1;
}

.playlist-page .padPlay .details .subTitleOuter .infoOuter .infoPopup {
  position: absolute;
  left: -130px;
  bottom: 30px;
  background: #8C8C8C;
  border-radius: 10px;
  padding: 12px 15px;
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 4px;
  opacity: 0;
}

.playlist-page .padPlay .details .subTitleOuter .infoOuter .infoPopup .name {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 22.5px;
  text-align: left;
  color: #fcfcfc;
  margin: 0;
}

.playlist-page .padPlay .details .subTitleOuter .infoOuter .infoPopup .name .subName {
  font-weight: 400;
}

.popupOuter {
  position: relative;
}

.playlist-page .morePopup {
  position: absolute;
  right: 20px;
  top: 50px;
  background: #FDFDFD;
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 4px 10px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 12px 15px;
  z-index: 9;
}

.playlist-page .table-responsive .table tbody tr:nth-last-child(1) .morePopup {
  top: -5px;
  bottom: auto;
  right: 30px;
}

.playlist-page .morePopup .outer {
  display: flex;
  gap: 12px;
  align-items: center;
  width: max-content;
}

.playlist-page .morePopup .outer .label {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8c8c8c;
  margin: 0;
}



@media (max-width: 991px) {
  .playlist-page .table-responsive .table .titleSection .title {
    min-width: auto;
  }

  .playlist-page .morePopup {
    display: none;
  }

  .playlist-page .top .label {
    font-size: 32px;
  }

  .playlist-page .mainTitleOuter .mainTitle {
    font-size: 23px;
    line-height: 34.5px;
  }

  .playlist-page .table-responsive .table th.none {
    display: none;
  }

  .playlist-page .table-responsive .table td.none {
    display: none;
  }

  .playlist-page .table-responsive .table .titleSection {
    max-width: 290px;
  }

  .playlist-page .mainTitleOuter .mainTitle {
    font-size: 23px;
    line-height: 34.5px;
  }

  .playlist-page .padPlay {
    left: 100px;
    max-width: calc(100% - 190px);
  }

  .playlist-page .padPlay .volumeController {
    display: none;
  }

  .playlist-page .padPlay .details {
    width: 220px;
  }

  .playlist-page .playlist-page-title-outer .list-of-play-search .search-input {
    display: none;
  }

  .playlist-page .playlist-page-title-outer .list-of-play-search .searchIcon-outer.searchTooltip {
    display: block;
  }

  .playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex .top .padDetail .label {
    font-size: 36px;
    line-height: 54px;
  }

  .morePopup {
    display: none;
  }
}


@media (max-width: 767px) {
  .playlist-page {
    padding-top: 50px;
  }

  .playlist-page .playlist-page-title-outer {
    display: inline;
  }

  .playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-c-pointer {
    display: none;
  }

  .playlist-page .playlist-page-title-outer .playlist-page-title .top {
    background: no-repeat;
    box-shadow: none;
    border: none;
    flex-direction: column;
    gap: 8px;
  }

  .playlist-page .playlist-page-title-outer .playlist-page-title .top .padDetail {
    justify-content: center;
  }

  .playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex .top .padDetail .category {
    display: none;
  }

  .playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex .top .padDetail .label {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex .top .padDetail .padDetailInner {
    justify-content: center;
  }

  .playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex .top .padDetail .padDetailInner .year {
    font-size: 13px;
    line-height: 19.5px;
  }



  .playlist-page .playlist-page-title-outer .playlist-page-title .my-favorites-d-flex .top .padDetail .padDetailInner .count {
    font-size: 13px;
    line-height: 19.5px;
  }

  .playlist-page .top .topImg {
    width: 156px;
    height: 156px;
  }

  .playlist-page .top .label {
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }

  .playlist-page .mainTitleOuter .mainTitle {
    font-size: 18px;
    line-height: 27px;
  }

  .playlist-page .mainTitleOuter .viewMore {
    height: auto;
    padding: 0;
    font-size: 13px;
    line-height: 19.5px;
  }

  .playlist-page .mainTitleOuter {
    display: none;
  }

  /* .mobHide {
    display: none;
  } */


  .playlist-page .table-responsive .table .actions .more {
    transform: rotate(90deg);
  }

  .playlist-page .table-responsive .table .srNo .bhaktiImg {
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }

  .playlist-page .table-responsive .table .titleSection .title {
    font-size: 15px;
    line-height: 22.5px;
    max-width: 150px;
  }

  .playlist-page .table-responsive .table .titleSection .subTitle {
    font-size: 13px;
    line-height: 19.5px;
  }

  .playlist-page .table-responsive .table td {
    padding: 10px;
  }

  .playlist-page .table-responsive .table .titleSection {
    max-width: 210px;
  }

  .playlist-page .playlist-page-title-outer .list-of-play-search .search-input {
    display: block;
  }

  .playlist-page .playlist-page-title-outer .list-of-play-search .searchIcon-outer.searchTooltip {
    display: none;
  }

  .playlist-page .controlsTop {
    display: flex;
  }

  .playlist-page .padPlay {
    left: 0;
    width: 100%;
    max-width: calc(100% - 14px);
    bottom: 55px;
    padding: 7px;
    gap: 7px;
  }

  .playlist-page .padPlay .padPlayImg {
    width: 42px;
    height: 42px;
    border-radius: 8px;
  }

  .playlist-page .padPlay .details .title {
    font-size: 15px;
    line-height: 22.5px;
    color: #F9B004;
  }

  .playlist-page .padPlay .details {
    width: 200px;
  }

  .playlist-page .padPlay .details .subTitleOuter .info {
    display: none;
  }

  .playlist-page .padPlay .player .controls {
    gap: 12px;
  }

  .playlist-page .padPlay .player .controls .prev {
    display: none;
  }

  .playlist-page .padPlay .player .controls .next {
    display: none;
  }

  .playlist-page .padPlay .player .controls .playAll {
    display: none;
  }

  .playlist-page .padPlay .player .controls .padPlayInner {
    width: 24px;
    height: 24px;
  }

  .playlist-page .padPlay .player .controls .padPlayInner .playIcon {
    width: 12px;
    height: 12px;
  }


  .playlist-page .padPlay .player .playLine {
    display: none;
  }

}

@media (min-width: 768px) {
  .playlist-page .padPlay .player .controls .plus {
    display: none;
  }
}
