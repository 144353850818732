.contactPage {
  background-color: #fff !important;
  /* text-align: center; */
  padding: 90px 0px 50px;

  /* min-height: calc(100vh - 215px); */
  height: 100%;
}

.contactPage .MuiPaper-outlined {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 11px !important;
}

.contactPage .twoColorTagLine {
  margin-bottom: 30px;
  display: inline-flex;
  /* text-align: center; */
  align-items: center;
}

.contactPage .pricingDesc {
  margin-bottom: 34px;
}

.privacyPolicy {
  /* text-indent: 60px; */
  text-align: start;
  margin-top: 0;
  margin-bottom: 0;
}

.contactPage .pricingTitle {
  font-size: 24px;
  font-weight: 400;
  padding: 6px 0px 9px;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #333;
}

.contactPage .pricingTitle1 {
  font-size: 24px;
  font-weight: 400;
  padding: 6px 0px 9px;
  align-items: center;
  display: flex;
}

.contactPage .cardDescLine {
  color: #333;
  font-size: 14px;
  line-height: 23px;
}

.contactPage .smallTagLine {
  color: #333;
  font-weight: 600;
}

.contactPage .seeAllfeature {
  background-color: #605f5f;
  padding: 17px;
  /* text-align: center; */
  font-weight: bold;
}

.contactPage .pricingTitle img {
  width: 30px;
  margin-right: 10px;
}

.contactPage .seeAllfeature a {
  color: #fff;
  text-decoration: none;
}

.contactPage .betBadge {
  background-color: #333;
  color: #fff;
  padding: 1px 4px;
  border-radius: 2px;
}

.contactPage .pricingCard {
  font-size: 14px;

  /* height: 357px; */
  background-color: #fff;
  padding: 18px 26px 0px;
  text-align: left;
}

.contactPage .subTitleCard {
  padding: 0px 3px 8px;
}

.contactPage .descTitle .fa {
  height: 20px;
  width: 5%;
  text-align: center;
  font-size: 17px !important;
  margin-right: 10px;
}

.contactPage .descTitle {
  font-size: 15px;
  font-weight: 400;
  margin: 16px 0px 10px;
  display: flex;
  align-items: baseline;
}

.contactPage .descIcon {
  width: 90%;
}

.contactPage .getStart {
  background-color: #1C4D9A !important;
  width: 100% !important;
  color: #fff !important;
  margin: 5px 0px !important;
  font-weight: bold !important;
}

.contactPage .prcinglabel {
  display: flex;
  align-items: baseline;
  margin: 5px 0px;
}

.contactPage .dollerProce {
  font-size: 47px;
  font-weight: bold;
  font-family: sans-serif;
}

.contactPage .twoColorTitle {
  font-size: 26px;

  font-weight: 600;
  line-height: 1.167;
  letter-spacing: 0em;
  color: #333;
}

.contactPage .textBlue {
  color: #3f51b5;
  font-size: 26px;

  font-weight: 600;
  line-height: 1.167;
  letter-spacing: 0em;
}

.contactPage .MuiTypography-colorPrimary {
  color: #3f51b5;

}

.contactPage .toggleSpacing {
  margin-bottom: 10px;
}

.contactPage .toggleSpacing .optionActive {
  font-size: 17px;

  font-weight: 600;
  line-height: 1.75;
}

.contactPage .text-left {
  text-align: left !important;
  /* padding: 16px; */
}

.contactPage form {
  /* font-family: 'roboto', sans-serif; */
  width: 100%;
  /* margin: 100px auto 0; */
  /* box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.2); */
  padding: 0 0 40px;
  border-radius: 3px;
  color: #333;
}

.form-group1 {
  margin-top: 20px;
}

.contactPage input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font: inherit;
  transition: transform 0.3s ease-in-out,
    font-size 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
}

.contactPage input:focus,
input:valid,
textarea:focus,
textarea:valid {
  box-shadow: none;
  outline: none;
  background-position: 0 0;
  border-width: 1px;
  /* border: 1px solid black; */
}

.contactPage textarea:valid {
  box-shadow: none;
  outline: none;
  background-position: 0 0;
  border-width: 1px;
  background: inherit !important;
}

.contactPage .mapImage {
  width: 100%;
}

.contactPage input:valid {
  box-shadow: none;
  outline: none;
  background-position: 0 0;
  border-width: 1px;
  background: inherit !important;
}

.contactPage .MuiBox-root-1 {
  text-align: left;
}

.contactPage input {
  font: inherit;
  font-size: 18px;
  margin: 10px 0px 10px;
  width: 100%;
  box-sizing: border-box;
  display: block;
  border-width: 0px;
  border: solid 1px;
  padding: 20px 10px 10px;
  border-bottom: solid 1px #333;
  /* background: linear-gradient(to bottom, rgba(255,255,255,0) 96%, #333 4%); */
  background-position: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  resize: none;
  overflow: hidden;
}

.contactPage textarea {
  font: inherit;
  font-size: 18px;
  margin: 10px 0px 10px;
  width: 100%;
  box-sizing: border-box;
  display: block;
  border-width: 0px;
  border: solid 1px;
  padding: 20px 10px 10px;
  border-bottom: solid 1px #333;
  /* background: linear-gradient(to bottom, rgba(255,255,255,0) 96%, #333 4%); */
  background-position: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  resize: none;
  overflow: hidden;
}


.contactPage input[type="email"] {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 96%, #333 4%);
  /* .contactPage input[type="email"]background-position: -400px 0; */
  background-repeat: no-repeat;
  border: solid 1px;
  /* transition: border-bottom 0.3s ease, background 0.3s ease; */
  /* span.validation-text {
      position: absolute;
      visibility: hidden;
      font-family: 'roboto', sans-serif;
      font-size: 0.6em;
      width: 100%;
      margin-left: 25px;
      margin-top:-5px;
      color: white;
      transition: color 0.3s ease-in-out;
    } */
  /* &:not([value=""]) {
      &::-webkit-input-placeholder {
        font-size: 0.8em;
        transform: translateY(-20px);
        visibility: visible !important;
        opacity: 1;
      }
      &:focus::-webkit-input-placeholder {
        color:#333;
      }
      &:focus:not(:valid)::-webkit-input-placeholder {
        color: #333;
      }
    } */
  /* &:focus {
      background-position: 0 0;
       &:not(:valid):not([value=""]) {
        border-bottom: solid 1px #333;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 96%, #333 4%);
      } 
    } */
  /* &:not(:focus):not(:valid):not([value=""]) {
      &::-webkit-input-placeholder {
        color: $invalid;
      }
      border-bottom: solid 1px #333;
      background-position: 0 0 ;
      background: linear-gradient(to bottom, rgba(255,255,255,0) 96%, #333 4%);
      &~span.validation-text {
        visibility: visible;
        color: #333;
      }
    } */
}

.contactPage .flex {
  display: flex;
  align-items: stretch;
  /* textarea {
      line-height: 120%;
    } */
}

.contactPage button {
  font-family: 'roboto', sans-serif;
  transition: transform 0.2s #333,
    box-shadow 0.2s #333;
  border: none;
  background: #333;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  border-radius: 3px;
  padding: 14px;
  width: 100%;
  color: white;
  margin: 25px 0 0 0px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  /* &:hover { 
      transform: translateY(-2px);
      box-shadow: 0 5px 6px 0 rgba(0,0,0,0.2);
    } */
}

/*a {
    display: inline-block;
    width: 100%;
    margin-top: 50px;
    text-align: center;
    font-family: 'roboto', sans-serif;
    text-decoration: none;
    color: $base;
    i {
      margin-right: 5px;
    }
  }*/

@media screen and (max-width: 767px) {
  .contactPage .MuiBox-root-25 {
    padding-bottom: 0px;
  }

  .contactPage .pricingTitle1 {
    font-size: 20px;
  }

  .contactPage button {

    margin: 25px 0 0 0px;
    width: 100%;
  }
}