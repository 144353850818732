.allPlaylist {
  padding-top: 105px;
}

.allPlaylist .outer-wrap {
  padding: 0px 10px 50px;
  column-count: 3;
}

.title-wrapper p.right {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
}

.card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 0px;
  /* height: 375px; */
}

.inside-wrap {
  display: block;
  width: 100%;
  background-color: #FFFCF5;
}

.inside-wrap .list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  background: #000000ba;
}

.inside-wrap .img-banner {
  width: 100%;
  height: 270px;
  height: 170px;
  display: flex;
  border-radius: 0px;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
}

.play-icon {
  position: absolute;
  bottom: -25px;
  right: 0px;
  cursor: pointer;
}

.allPlaylist .card-content-item {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
}

.video-content {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.video-content img {
  margin-left: 10px;
  cursor: pointer;
}

.title-text {
  font-size: 17px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.text-hrs {
  font-size: 15px;
  color: #8C8C8C;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.card {
  width: 100%;
  max-width: 300px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card img {
  width: 100%;
  height: auto;
}

.card-content {
  padding: 10px;
}

.card-content h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.card-content p {
  margin: 10px 0 0;
  font-size: 14px;
  color: #888;
}


.createPlayList {
  background-color: #F6AE06;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
}

.createPlayList:hover {
  background-color: #F6AE06;
}

.createPlayList:active {
  background-color: #F6AE06;
}

.modal-createPlayList {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content-createPlayList {
  background-color: #fefefe;
  margin: 20% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  padding-right: 10px;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.form-container {
  /* max-width: 400px; */
  margin: 0 auto;
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.form-label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-input {
  width: 95%;
  margin-bottom: 20px;
  padding: 15px;
  border: none;
  border-radius: 4px;
}

.form-input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

.file-input {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.file-input-label {
  display: block;
  padding: 8px;
  background-color: #F6AE06;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.file-input-label:hover {
  background-color: #F6AE06;
}

.file-input input[type="file"] {
  display: none;
}

.form-button {
  width: 100%;
  padding: 15px;
  background-color: #F6AE06;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #F6AE06;
}

.logo-preview {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.form-input.invalid {
  border: 1px solid red;
}

.d-none {
  display: contents;
}

/*----- Search Bar Start CSS -----*/
.allPlaylistHead .my-favorites-d-flex {
  padding: 0 15px;
  /* padding-top: 40px; */
  cursor: pointer;
}

.p-list-search-wrap {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px;
  margin-top: 20px; */
  margin-left: 15px;
  margin-right: 15px;
  width: 450px;
  align-items: center;
}

.search-input {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.search-input input {
  font-size: 17px;
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  width: 100%;
}

.filter-icon {
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}

.list-of-play-search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
}

/*----- Search Bar End CSS -----*/
.list-of-like-icon-div {
  padding-top: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.like-icon-div {
  text-align: left;
  padding-top: 10px;
}

/* List View CSS */
.allPlaylist.listView .outer-wrap {
  column-count: 1;
  display: block;
}
.allPlaylist.listView .custom-col-4 {
  width: 100%;
}
.allPlaylist.listView .inside-wrap {
  display: flex;
}
.allPlaylist.listView .inside-wrap .img-banner {
  width: 100px;
}
.allPlaylist.listView .play-icon {
  width: 40px;
  bottom: 0px;
  right: -15px;
}
.allPlaylist.listView .video-content {
  flex-direction: row;
}
/* .allPlaylist.listView .video-content-inner {
  display: flex;
  align-items: center;
  gap: 30px;
} */
.allPlaylist.listView .video-content-inner {

  flex-direction: column;
  display: flex;
  gap: 15px;
  height: 70px;
  max-width: 300px;

  justify-content: center;
}
.allPlaylist.listView .title-text {
  height: auto;
  margin: 0;
}
.allPlaylist.listView .list-of-like-icon-div {
  padding: 0;
}
.allPlaylist.listView .tooltiptext {
  display: none;
}
.allPlaylist.listView .general-text-hrs.time {
  /* width: 66.53px; */
  text-align: right;
}


/* List View CSS */



@media (max-width: 600px) {
  .form-container {
    max-width: 100%;
  }
}



@media screen and (max-width: 767px) {

  .card-wrap {
    box-shadow: 1px 3px 15px 0px #00000026;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
    height: inherit;
  }

  .outer-wrap {
    padding: 5px 10px 30px;
    column-count: 1;
  }

  /* .inside-wrap {
    display: flex;
    width: 100%;
    background-color: #FFFCF5;
  } */

  /* .inside-wrap .list-image {
    max-width: 30%;
    width: 100%;
    position: relative;
    flex-shrink: 0;
    cursor: pointer;
  } */

  .like-icon-div {
    text-align: right;
  }

  .inside-wrap .img-banner {
    width: 100%;
    height: 100%;
    /* height: 120px; */
    display: flex;
    /* max-height: 130px; */
  }

  /* .play-icon {
    position: absolute;
    bottom: -12px;
    right: -30px;
  } */

  .d-none {
    display: none;
  }

  .p-list-search-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
    width: inherit;
  }

  .list-of-play-search {
    display: inherit;
    justify-content: inherit;
  }
  .title-wrapper p.right {
    font-size: 15px;
  }
  .title-text {
    font-size: 15px;
  }
  .text-hrs {
    font-size: 13px;
  }
  .search-input input {
    font-size: 15px;
  }
  .allPlaylistHead {
    padding-top: 50px;
  }
  .allPlaylist {
    padding-top: 0px;
  }


  
}

@media only screen and (max-width: 991px) and (min-width: 767px) {
  .allPlaylist .outer-wrap {
    column-count: 2;
  }
  .allPlaylistHead {
    display: block !important;
  }
  .allPlaylistHead .my-favorites-title-mt-20 {
    padding-bottom: 0;
  }
}

@media (min-width: 768px) {
  .allPlaylistHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; 
    padding-top: 85px; 
  }
  .home-top .allPlaylist {
    padding-top: 0px; 
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .inside-wrap .img-banner {
    /* height: 170px; */
  }
  .p-list-search-wrap {
    justify-content: flex-end;
    width: auto;
  }
  .allPlaylist {
    padding-top: 0px;
  }

}


@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .list-of-play-search .p-list-search-wrap {
    width: 300px;
  }
  .allPlaylistHead .my-favorites-title {
    font-size: 30px;
  }
}