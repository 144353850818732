/*----- New CSS Start -----*/
.video-play-top {
  padding: 40px 15px 40px;
}
.playListPlayPage.video-play-top {
  padding-top: 85px;
}
.video-play-star-home {
  position: absolute;
  top: 50%;
  right: 4%;
  display: flex;
}

.video-play-d-flex .video-play-d-flex-title{
  display: flex;
  flex-direction: column;
  max-width: inherit;
  text-overflow: ellipsis;
}

.video-play-thumbnail-div {
  display: flex;
  position: relative;
  /* height: 600px; */
  justify-content: center;
  align-items: center;
}

.video-play-thumbnail {
  max-height: 100%;
  max-width: 100%;
}

.video-play-layer-image {
  background-color: rgb(193 193 192 / 70%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.video-play-lock-div {
  position: absolute;
  display: flex;
  margin-left: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.video-play-like-count {
  display: flex;
  margin: 0 auto;
}

.video-play-title-div {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.video-play-title-div .video-title {
  font-size: 27px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.video-play-d-flex {
  display: flex;
  align-items: center;
}

.video-play-jc-sb {
  justify-content: space-between;
}

.video-play-hrs-time {
  font-size: 15px;
  color: #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.video-play-hrs-txt {
  font-size: 15px;
  color: #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 4px;
}

.video-play-dot {
  height: 6px;
  width: 6px;
  background-color: rgb(188, 188, 188);
  margin-left: 10px;
  margin-right: 10px;
  align-self: center;
  border-radius: 10px;
}

.video-play-mt-5 {
  margin-top: 5px;
}

.video-play-mt-30 {
  margin-top: 30px;
}

.video-play-mr-10 {
  margin-right: 10px;
}

.video-play-m-15 {
  margin: 15px;
}
.playlist-play-page-video-player-container {
  position: relative;
}

.playlist-play-page-video-play-controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}

.playlist-play-page-video-play-backward-icon,
.playlist-play-page-video-play-forward-icon {
  cursor: pointer;
  font-size: 24px;
  color: #b1b1b1;; /* Adjust the color as needed */
}

.add-my-playlist-modal-content .add-my-playlist-modal-content-list-scroll{
  height: 300px;
  overflow-y: auto;
 
 }
 
.video-play-reference-material {
  font-size: 25px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-bottom: 10px;
  text-align: left;
}

.video-play-reference-material-card {
  min-width: 130px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FFFCF5;
  box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.15);
  padding: 10px;
  margin: 10px;
}

.document_card {
  display: flex;
  overflow: auto;
}

.reference-material-card-title {
  font-size: 17px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}

.reference-material-card-type {
  font-size: 15px;
  color: #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  margin-top: 2px;
}

.reference-material-download-type {
  font-size: 17px;
  color: #FFB200;
  text-align: right;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.video-play-suggested-video {
  font-size: 25px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: left;
}

.suggested-video-column-count-3 {
  column-count: 3;
  padding-bottom: 60px;
}

.suggested-video-play-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  /* height: 395px; */
}

.suggested-video-play-inside-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #FFFCF5;
}

.suggested-video-play-inside-wrap .suggested-video-play-list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
}

.suggested-video-play-inside-wrap .suggested-video-img-banner {
  width: 100%;
  height: 270px;
  height: 170px;
  display: flex;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
}

.suggested-video-play-play-icon {
  position: absolute;
  bottom: -29px;
  right: 0px;
}

.suggested-video-play-card-content-item {
  padding: 15px 10px 10px;
}

.suggested-video-play-video-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.suggested-video-play-video-content img {
  /* padding-right: 13px; */
}

.suggested-video-play-title-text {
  font-size: 17px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.suggested-video-play-text-hrs {
  font-size: 15px;
  color: #8C8C8C;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.suggested-video-mt-10 {
  margin-top: 10px;
}

.video-play-title-mt-20 {
  margin-top: 20px;
  display: block;
}

.video-play-title-d-flex {
  display: flex;
  padding-bottom: 20px;
  cursor: pointer;
  width: fit-content;
}
.video-play-control-d-flex {
  display: flex;
  /* padding-bottom: 20px; */
  justify-content: center;
  cursor: pointer;
  width: fit-content;
}

.video-play-c-pointer {
  cursor: pointer;
}

.video-play-heading-title {
  font-size: 35px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-left: 10px;
}


/*----- Modal Start CSS -----*/
.add-my-playlist-modal-content {
  padding-bottom: 20px;
  min-width: 250px; 
}
.fetch-more-playlist{
  color: #FFB200;
  font-size: medium;
  font-weight: 600;
  cursor: pointer;
  text-align: left;

}


.add-my-playlist-modal-d-flex {
  display: flex;
}
.add-my-playlist-modal-d-flex-outer {
  overflow: auto;
  max-height: 350px;
}
.modal-mb-5 {
  margin-bottom: 5px;
}

.add-my-playlist-modal-msg-text {
  font-size: 25px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  width: 90%;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.add-my-playlist-modal-close-button {
  color: #FFB200;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  width: 10%;
  text-align: end;
}

.add-my-playlist-modal-close-button:hover,
.add-my-playlist-modal-close-button:focus {
  color: #FFB200;
  text-decoration: none;
  cursor: pointer;
}

.modal-mt-15 {
  margin-top: 15px;
}

input[type="checkbox" i] {
  margin: inherit;
  margin-right: 7px;
  margin-top: 0px;
}

.add-my-playlist-checkbox-text {
  font-size: 17px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.add-my-playlist-create-playlist {
  color: #FFB200;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.add-my-playlist-modal-action {
  display: flex;
  padding: 0px 12px;
  margin-top: 10px;
}

.add-my-playlist-modal-cancel-btn {
  font-size: 17px;
  width: 100px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0px;
  border-color: transparent;
  background-color: transparent;
  color: #FFB200;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-right: 15px;
  cursor: pointer;
}

.add-my-playlist-modal-add-btn {
  font-size: 17px;
  width: 100px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid;
  border-color: #FFB200;
  background-color: #FFB200;
  color: #FFFFFF;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  padding: inherit;
}

/*----- Modal End CSS -----*/

.suggestedVideo {
  margin: 0 -5px;
}

.suggestedVideo .video-play-suggested-video {
  padding: 0 5px;
}

.suggestedVideo .suggested-video-mt-10 {
  justify-content: flex-start;
}
.ind-card-img-banner {
  height: 270px;
  height: 170px;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
}
.video-play-thumbnail-div::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  display: block;
  background: #0000009e;
  content: '';
}



@media screen and (max-width: 767px) {
  .suggested-video-play-inside-wrap .suggested-video-img-banner {
    /* height: 120px !important; */
  }
  .ind-card-img-banner {
    /* height: 120px !important; */
  }
  .video-play-title-mt-20 {
    margin-top: 20px;
    display: none;
  }

  .video-play-title-div .video-title {
    font-size: 17px;
    -webkit-line-clamp: 2;
  }

  .video-play-title-div {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .mobile-ref-material {
    padding-left: 15px;
    padding-right: 15px;
  }

  .video-play-top {
    padding: 0px 0px 40px;
  }

  .video-play-star-home {
    position: absolute;
    top: 20%;
    right: 4%;
    display: flex;
  }

  .suggested-video-column-count-3 {
    column-count: 1;
    padding-bottom: 20px;
  }

  .add-my-playlist-modal-content {
    padding-bottom: 20px;
    width: 265px;
  }

  .add-my-playlist-modal-action {
    display: flex;
    padding: 0 7px;
    margin-top: 10px;
  }

  .video-play-hrs-time {
    font-size: 13px;
  }

  .video-play-hrs-txt {
    font-size: 13px;
  }

  .video-play-reference-material {
    font-size: 20px;
  }

  .reference-material-card-title {
    font-size: 15px;
  }

  .reference-material-card-type {
    font-size: 13px;
  }

  .reference-material-download-type {
    font-size: 15px;
  }

  .video-play-suggested-video {
    font-size: 20px;
  }

  .suggestedVideo .custom-col {
    padding-left: 5px;
    padding-right: 5px;
  }

  .add-my-playlist-modal-cancel-btn {
    font-size: 15px;
  }

  .add-my-playlist-modal-add-btn {
    font-size: 15px;
  }

  .add-my-playlist-modal-msg-text {
    font-size: 20px;
  }

  .add-my-playlist-checkbox-text {
    font-size: 15px;
  }

  .suggested-video-play-title-text {
    font-size: 15px;
  }

  .suggested-video-play-text-hrs {
    font-size: 13px;
  }
  .playListPlayPage.video-play-top {
    padding-top: 0;
  }



}

/*----- New CSS End -----*/


@media (max-width: 991px) {
  .video-play-title-mt-20 {
    display: none;
  }

}


@media screen and (min-width: 768px) {
  .videoPlayPageOuter {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .videoPlayPageOuter .videoPlayPage {
    padding-right: 50px;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .video-play-title-div .video-title {
    font-size: 23px;
    -webkit-line-clamp: 2;
  }
  .video-play-reference-material {
    font-size: 23px;
  }
  .video-play-suggested-video {
    font-size: 23px;
  }
  .add-my-playlist-modal-msg-text {
    font-size: 23px;
  }
  .playListPlayPage.video-play-top {
    padding-top: 105px;
  }


}