.sliderTop {
  padding: 75px 0px 40px;
  /* font-family: 'Montserrat'; */
}

.slick-next {
  right: 30px;
  color: #fff;
  font-size: 2rem !important;
  z-index: 99;
  margin: 0 15px;
}

.slick-next:hover {
  color: #fff;
}

.slick-prev:hover {
  color: #fff;
}

.slick-prev {
  left: 30px;
  color: #fff;
  font-size: 2rem !important;
  z-index: 99;
}

.slick-dots {
  bottom: 10px;
}

.slick-track {
  margin-left: inherit;
  margin-right: inherit;
}

.textwrapafter2line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 800px;
  overflow: hidden;
  height: 22px;
}

.slick-dots li button:before {
  content: " ";
  background-color: hsl(0deg 0% 100% / 88%);
  border-radius: 50%;
  box-shadow: 1px 1px 4pxrgba (0, 0, 0, 0.4);
  display: block;
  height: 13px;
  margin-right: 7px;
  overflow: hidden;
  text-indent: -9999px;
  transition: height 0.3s ease;
  width: 13px;
}

.slick-dots li.slick-active button:before {
  background-color: hsl(0deg 0% 100%);
}

.heading-title {
  padding: 15px 30px 3px;
  font-size: 20px !important;
  text-transform: uppercase;
  font-weight: bold !important;
  color: #333;
  margin-bottom: 3px !important;
}

.slick-slide img {
  display: block;
  width: 100%;
}

.single-silder .slick-slide img {
  border-radius: 4px;
}

.slick-list {
  /* margin: 0 -5px; */
}

.slick-slide>div {
  padding: 0 5px;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;

}

.slick-slider.single-silder.slick-initialized {
  padding: 0px 30px;
}

.latestVideo {
  margin: 0 17%;
}

.mainGrid {
  padding: 15px 60px;
}

.home-page .video-item__author {
  font-size: 15px;
  /* margin-right: 14px; */
  color: #606060;
  font-weight: 500;
}

.video-item__date {
  font-size: 14px;
  margin-right: 14px;
}

/* .post-views-count {
  font-size: 12px;
  font-weight: 400;
  color: #7e7e7e;
} */
.video-item__meta {
  /* display: flex; */
  align-items: center;
  width: 100%;
}

.videoDesc {
  padding: 5px 10px 10px;
  width: 80%;
  cursor: pointer;
}

.backGroundLayer .d-flex {
  display: flex;
}

.videoTitle {
  font-size: 30px !important;
  text-transform: initial;
  font-weight: 500;
  color: #1a051d !important;
  margin-bottom: 6px !important;
  padding-top: 5px;
  margin-bottom: 5px !important;
  /* text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap; */
  /* margin-top: 10px; */
}

.live-video-div1 {
  width: 100%;
  height: 600px;
  position: absolute;
}

.live-video-div2 {
  background-color: #000;
  width: 100%;
  height: 600px;
  text-align: center;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
}

.liveVideoScreen {
  width: 100% !important;
  height: 600px !important;
}

.video-div1 {
  width: 100%;
  height: 400px;
  position: "absolute";
}

.video-div2 {
  background-color: #000;
  width: 100%;
  height: 400px;
  text-align: center;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
}

.videoScreen {
  width: 100% !important;
  height: 400px !important;
}

.mainGrid .videoTitle {
  font-size: 15px !important;
  text-transform: initial;
  font-weight: 500;
  color: #1a051d !important;
  margin-bottom: 6px !important;
  padding-top: 5px;
  margin-bottom: 5px !important;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 57px !important;
  /* overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; 
           line-clamp: 2;
   -webkit-box-orient: vertical;  */
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
  /* margin-top: 10px; */
}

.videoTitleMain {
  font-size: 20px !important;
  text-transform: initial;
  font-weight: 500;
  color: #333;
  margin-bottom: 6px !important;
}

.video-item__meta a {
  color: #7c7676;
  text-decoration: none;
}

.video-item__meta .fa {
  color: #918888;
  margin-right: 5px;
  width: 13px;
}

.viewCount:after {
  content: "|";
  margin: 0 7px 0px 7px;
  color: #333;
}

.home-page .video-item__author:after {
  content: "|";
  margin: 0 7px 0px 7px;
  color: #000;
}

.post-views-count {
  display: flex;
  /* float: left; */
  font-size: 13px;
  font-weight: 600;
  color: #606060;
  margin-bottom: 3px;
}

/* .post-views-count .LikeCount{
  margin-left: 10px;
} */

.videoMainTitle {
  color: #1a051d !important;
  font-size: 22px !important;
  /* text-transform: uppercase; */
  font-weight: 600 !important;
  /* background-color: #fbfbfb; */
  margin-bottom: 0px !important;
  padding: 5px 0px;
}

.backGroundLayer {
  /* height: 100%; */
  position: relative;
}

/* .backGroundLayer:hover{
  box-shadow: 1px 1px 6px 1px #e7e4e4;
}
.backGroundLayer{
  box-shadow: 1px 1px 6px 1px #e7e4e4;
} */
.mypy0 {
  padding: 0px;
  margin: 0px;
}

.videoBandge {
  /* position: absolute;
  bottom: -2%;
  right: 1%;
  text-align: center; */
  float: right;
  width: 15%;
}

.Bigstar-home {
  position: absolute;
  top: 65%;
  right: 2%;
  display: flex;
}

.Bigstar-home .LikeCount a {
  color: #fff;
  text-decoration: none;
}

.star1.badgestar {
  position: absolute;
  top: 35%;
  left: 43%;
  display: flex;
  margin-left: 0px;
}

.star1.badgestarInvisible .LikeCount {
  margin: 0 auto;
}

.star1.badgestarInvisible {
  position: absolute;
  /* top: 11vh; */
  display: flex;
  margin-left: 0px;
  left: 0;
  right: 0;
}


.fa.fa-lock {
  font-size: 46px;
}

/* Table lock css */
.star1.badgestarInvisible.tableLock {
  top: 3px;
  right: auto;
  left: 55px;
}

.star1.badgestarInvisible.tableLock:hover .tooltiptext {
  left: 100px;
  top: 6px;
}

.star1.badgestarInvisible.tableLock .fa.fa-lock {
  font-size: 40px;
  opacity: 0.9;
  color: #000000cf;
}

/* ALbum Lock css */
.albumTop.albumLockOuter .albumHover {
  opacity: 1 !important;
}

.star1.badgestarInvisible.albumLock .fa.fa-lock {
  font-size: 40px;
}

.albumTop.albumLockOuter .albumHover .albumHoverInner {
  opacity: 0;
}

.star1.badgestarInvisible.albumLock:hover .tooltiptext {
  left: 60px;
}


/*  */

.star-home {
  position: absolute;
  top: 55%;
  right: 4%;
  display: flex;
}

.star-home .LikeCount a {
  color: #fff;
  text-decoration: none;
}

.close-video-index {
  position: absolute;
  top: 5%;
  right: 4%;
  display: flex;
}

.close-video-index .LikeCount a {
  color: #fff;
  text-decoration: none;
}

img.img-responsive {
  width: 100%;
  height: 400px;
  position: relative;
  /* border-radius: 30px; */
}

.layerImage {
  background-color: rgb(193 193 192 / 70%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 30px; */
}

.star1 .LikeCount a {
  color: #fff;
  text-decoration: none;
}

.BigvideoBandge {
  position: absolute;
  bottom: 1%;
  right: 0%;
  display: flex;
  text-align: center;
  /* bottom: -1%;
  width: 34px; */
}

.badge {
  color: #333;
  /* background-color: rgba(0, 0, 0, 0.7); */
  font-size: 12px;
  font-weight: 600;
  padding: 3px 8px;
  line-height: 18px;
  z-index: 2;
  border-radius: 3px;
  margin-left: 0px;
  text-align: center;
}

.badgestar {
  color: #333;
  background-color: rgb(0 0 0 / 48%);
  font-size: 21px;
  font-weight: 600;
  padding: 8px 13px;
  line-height: 18px;
  z-index: 2;
  border-radius: 3px;
  margin-left: 9px;
}

.badgestarInvisible {
  color: #333;
  background-color: transparent;
  font-size: 21px;
  font-weight: 600;
  padding: 8px 13px;
  line-height: 18px;
  /* z-index: 2; */
  border-radius: 3px;
  margin-left: 9px;
}

.g-icon {
  margin-right: 5px;
  width: 25px;
  background-color: #efe6e6;
  border-radius: 100%;
  padding: 3px;
}

.orange-text {
  color: #333;
}

.badge img {
  width: 42px;
}

.badgeShow {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.badgeShow .tooltiptext {
  width: 120px;
  /* bottom: 100%; */
  /* left: 50%; */
  top: -7px;
  right: 105%;
  margin-left: -60px;
  visibility: hidden;
  background-color: #000;
  font-family: 'Montserrat';
  color: #fff;
  text-align: center;
  border-radius: 0px;
  padding: 8px 0;
  font-size: 13px;
  position: absolute;
  z-index: 1;
}

.star:hover .tooltiptext {
  visibility: visible;
}

.star-home:hover .tooltiptext {
  visibility: visible;
}

.bigVideo {
  width: 100%;
  /* height:512px; */
  height: 600px;
}

.bigVideoPy {
  margin: 19px 30px;
}

::ng-deep .html5-video-player:not(.ytp-big-mode) .ytp-chrome-top .ytp-watch-later-button {
  display: none !important;
}


/* .video-div {
 // background-color: #F2F2F2;
  height: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.video-div {
  background-color: #2c2c2c;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .img-div {
  position: absolute;
  height: 90%;
  width: 100%;
} */

.img-div {
  position: absolute;
  height: 88%;
  width: 65%;
}

.home-page .post-view-count {
  font-weight: 500;
}

.filter-button-home {
  width: 150px;
  margin-top: 50px;
  margin-left: 15px;
  cursor: pointer;
  margin-bottom: 10px;
}

.home-filter {
  margin-left: 30px;
}

.advance-search-inside-wrap .star1.badgestarInvisible {
  top: 12vh;
}

.advance-search .table-responsive .star1.badgestarInvisible.tableLock {
  top: 5px;
}

.advance-search .badgestarInvisible:hover .tooltiptext {
  visibility: visible;
  left: 60px;
  top: -30px;
}

.videoPlayPageOuterMain .star1.badgestarInvisible {
  top: 12vh;
}

.videoPlayPageOuterMain .badgestarInvisible:hover .tooltiptext {
  visibility: visible;
  left: 60px;
  top: -30px;
}

.recent-watched-play-outer-wrap .star1.badgestarInvisible {
  top: 12vh;
}

.recent-watched-play-outer-wrap .badgestarInvisible:hover .tooltiptext {
  visibility: visible;
  left: 60px;
  top: -30px;
}

.individualPlaylist .star1.badgestarInvisible {
  top: 12vh;
}

.individualPlaylist .badgestarInvisible:hover .tooltiptext {
  visibility: visible;
  left: 60px;
  top: -30px;
}

@media screen and (max-width: 1250px) {
  .filter-button-home {
    width: 150px;
    margin-top: 0px;
    margin-left: 5px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .home-filter {
    margin-left: 10px;
  }
}


@media (min-width: 600px) and (max-width: 768px) {
  img.img-responsive {
    height: 290px;
  }

  .star1.badgestarInvisible {
    position: absolute;
    top: 25%;
    display: flex;
    margin-left: 0px;
    left: 0;
    right: 0;
  }

  .star1.badgestarInvisible.albumLock:hover .tooltiptext {
    left: 120px;
    top: -20px;
  }

  /* .layerImage {
    height: 290px;
  } */
}


@media (min-width: 768px) and (max-width: 1200px) {
  .bigVideoPy {
    /* margin: 19px 30px; */
    margin-top: 100px;
  }
}

@media (max-width: 991px) {
  .img-div {
    height: 80%;
  }
}

@media (max-width: 550px) {
  .video-div {
    height: 350px;
  }
}

@media screen and (max-width: 767px) {
  body {
    padding-bottom: 25px;
  }

  .star1.badgestarInvisible {
    position: absolute;
    top: 25%;
    display: flex;
    margin-left: 0px;
    left: 0;
    right: 0;
  }

  .star1.badgestarInvisible.albumLock:hover .tooltiptext {
    left: 120px;
    top: -20px;
  }

  /* .video-div {
    height: 300px;
  } */

  .img-div {
    height: auto;
    width: 100%;
  }

  .live-video-div1 {
    width: 100%;
    height: 300px;
    position: absolute;
  }

  .video-div1 {
    width: 100%;
    height: 300px;
    position: "absolute";
  }

  .liveVideoScreen {
    height: 300px !important;
  }

  .videoScreen {
    height: 300px !important;
  }

  .live-video-div2 {
    height: 300px;
  }

  .video-div2 {
    height: 300px;
  }

  .slick-next,
  .slick-prev {
    display: none !important;
  }

  .home-page .video-item__author {
    font-size: 12px;
  }

  .bigVideoPy {
    margin: 75px 10px 15px;
  }

  .post-views-count {
    font-size: 12px;
  }

  .mainGrid {
    padding: 15px 10px;
  }

  .menu-icon svg {
    color: #333 !important;
  }

  .slick-slide img {
    height: 140px;
  }

  .bigVideo {
    width: 100%;
    height: 310px;
  }

  .sliderTop {
    padding: 83px 0px 40px;

  }

  .badge img {
    width: 35px !important;
  }

  .videoDesc {
    padding: 5px 10px 21px !important;
  }

  .BigvideoBandge {
    bottom: 0% !important;
    right: 2%;
  }

  .textwrapafter2line {
    height: 21px;
  }

  .star1.badgestarInvisible.tableLock {
    top: 2px;
    left: 5px;
  }

  .star1.badgestarInvisible.tableLock .fa.fa-lock {
    font-size: 30px;
  }

}

.ytp-chrome-top.ytp-show-cards-title {
  display: none;
}

.card {
  border-radius: 0.75rem;
  background-color: rgb(246, 236, 218);
  overflow: hidden;
}

.info__padding {
  padding: 15px 40px 30px 30px;
  background-color: #fff;
}

.info__padding h3 {
  font-size: 16px;
  font-weight: 400;
}

.info__padding h3 p {
  font-size: 16px;
  font-weight: 400;
}

.react-confirm-alert .button__group {
  background-color: #fff;
}


h3 {
  font-size: 19px;
  margin-bottom: 12px;
  font-weight: 600;
}

p {
  font-size: 16px;
  font-weight: 400;
}

h3,
p {
  color: #0B1A28;
  text-align: center;
}

.button__group {
  text-align: center;
  /* justify-content: space-around; */
}

.button__group button {
  color: #333;
  background-color: white;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  border: 1px solid;
  border-radius: 10px;
  /* border-color: #00006d; */
  /* border: none;
  border-top: 1px solid rgb(220, 224, 226);
  border-left: 1px solid rgb(220, 224, 226); */
  padding: 10px 10px;
  cursor: pointer;
  width: 25%;
  /* margin-left: 40px; */
  /* margin-right: 20px; */
  margin-bottom: 20px;
}

.button__group:after {
  content: "";
  clear: both;
  display: table;
}

.button__group button:not(:last-child) {
  border-left: none;
}


.button__group .signBtnHome {

  background-color: #fff !important;
  text-transform: none !important;
  color: #ED1C24 !important;
  box-shadow: none !important;
  border: 1px solid #ED1C24 !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  padding: 3px 4px !important;
  font-size: 14px !important;
  margin-top: 16px;
  width: 50%;
}

.button__group .subscriptionBtn {

  background-color: #1C4D9A !important;
  text-transform: none !important;
  color: #FFF !important;
  box-shadow: none !important;
  border: 1px solid #1C4D9A !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  padding: 5px 4px !important;
  font-size: 14px !important;
  margin-top: 16px;
  width: 50%;
}

.no-btn {
  border: 1px solid gray !important;
  color: #0B1A28 !important;
  margin-left: 10px;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.button__group .signBtnHome:hover {
  background-color: #fff !important;
}

.button__group .subscriptionBtn:hover {
  background-color: #1C4D9A !important;
}

.btn-ok {
  background-color: #1C4D9A !important;
  color: #fff !important;
  border: none !important;
}

/* .common-btn1 {
  font-size: 18px !important;
  color: #00006d !important;
  border: 2px solid;
  border-radius: 25px;
  border-color: #00006d;
  height: 42px;
  justify-content: center;
  align-items: center;
} */

.video-div-f {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.infinite-scroll-component {
  overflow: initial !important;
}


@media (max-width: 600px) {

  /* .mainGrid .MuiGrid-spacing-xs-4 > .MuiGrid-item{
    padding: 5px 16px;
  } */
  .videoMainTitle {
    margin-bottom: 15px !important;
  }

  .videoBandge {
    margin-right: 0px;
  }

  .videoTitle2 {
    font-size: 18px !important;
  }

  .videoTitle2 {
    font-family: "Roboto", "Arial", sans-serif;
    font-size: 17px !important;
  }

  .home-mobile-padd {
    padding: 2px 16px 20px !important;
  }
}


@media (max-width: 767px) {
  .home-top .watch-later .slick-slider .slick-list {
    overflow: inherit;
  }
}

































/*----- Desktop Top Banner Start CSS -----*/
.home-top {
  padding: 105px 0px 40px;
}

.home-title {
  color: #212121;
  font-family: Poppins;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 20px;
  margin-left: 15px;
}

.live-satsang-title {
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 15px;
}

.home-top-main-banner {
  position: relative;
}

.home-top-banner {
  height: 320px;
  height: 400px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
  height: auto;
}

.home-top-main-banner .home-top-img-banner {
  width: 100%;
  height: inherit;
  /* object-fit: cover; */
  object-position: center;
  border-radius: 27px;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
}

/* .home-b-radius-27 {
  border-radius: 27px;
} */

.bottom-left {
  position: absolute;
  bottom: 15px;
  left: 25px;
}

.bottom-left p {
  font-size: 27px;
  color: #FFF;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 550px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
}

.top-right {
  position: absolute;
  top: 20px;
  right: 25px;
  cursor: pointer;
}

.bottom-right {
  position: absolute;
  bottom: 20px;
  right: 25px;
  background-color: #FFFFFF;
  height: 34px;
  width: 64px;
  border-radius: 10px;
  cursor: pointer;
}

.home-top .allPlaylist .list-of-like-icon-div {
  justify-content: start;
}

.home-top .allPlaylist .list-of-like-icon-div img {
  margin: 0;
  margin-right: 10px;
}

.bottom-right .live-div {
  display: flex;
  margin-top: -35px;
  margin-left: 10px;
}

.bottom-right .live-div .live {
  color: #DC1212;
  text-align: right;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px;
}

.bottom-right .live-div .live-bullet {
  height: 8px;
  width: 8px;
  background-color: #DC1212;
  border-radius: 50%;
  align-self: center;
}

.mobile-live-satsang {
  display: none;
  cursor: pointer;
}

/*----- Search Bar Start CSS -----*/
.home-search-box-mobile {
  display: none;
}

.home-search-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: auto;
  width: 100%;
  margin-right: auto;
  padding: 0 15px;
}

.search-input {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.search-input input {
  font-size: 17px;
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  width: 100%;
}

.filter-icon {
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}

/*----- Search Bar End CSS -----*/

/*----- Desktop Top Banner End CSS -----*/


/*----- Recently Watched, Watch Later and List of Playlists Start CSS -----*/
.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.title-wrapper p {
  font-size: 25px;
  margin-bottom: 7px;
  font-weight: 400;
  color: #212121;
  line-height: 30px;
}

.title-wrapper p.lef {
  color: #212121;
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 30px;
}

.pl-pr-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.home-top .allPlaylist {
  padding: 0;
}

.c-pointer {
  cursor: pointer;
}

/*----- Recently Watched, Watch Later and List of Playlists End CSS -----*/

.MuiDialog-root.surpriseMe .MuiDialog-container .MuiDialogContent-root {
  padding: 0;
  padding-bottom: 55px;
}

.MuiDialog-root.surpriseMe .qu-history-modal-msg-content {
  position: absolute;
  bottom: 0;
  padding: 15px;
  background: #FDF5E2;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px 0 10px 10px;
}

.MuiDialog-root.surpriseMe .qu-history-modal-msg-content .crossOrange {
  cursor: pointer;
}

.MuiDialog-root.surpriseMe .qu-history-modal-msg-content .fullScreen {
  margin-right: 10px;
  cursor: pointer;
}

.MuiDialog-root.surpriseMe .qu-history-modal-msg-content .pauseOrange {
  cursor: pointer;
}


.surpriseMeThumbnailOuter {
  /* background: #000000ba; */
}

.surpriseMeThumbnailOuter .surpriseMeThumbnailTitle {
  /* background: #fff; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 30px;
}

.surpriseMeThumbnail {
  width: 100%;
  height: 300px;
  border-radius: 10px 10px 0px 0px;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
}

.surpriseMeThumbnail.surpriseMeEbbok {
  aspect-ratio: 9/16;
  object-fit: unset;
}

.read-play-type-btn {
  font-size: 17px;
  color: #FFB200;
  text-align: right;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}


.modal {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 650px;
  background-color: #FFFCF5;
  border-radius: 10px;
  box-shadow: 1px 3px 15px 0px #00000026;
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 100px;
  height: 360px;
  border: none
}

.modal-dialog {
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DBC48E;
  padding-bottom: 10px;
  justify-content: center;
}

.modal-header .modal-title {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #212121;
  margin: 0;
}

.modal-body {
  padding: 10px 0;
  color: #212121;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  line-height: 20px;
}

.modal-footer {
  display: flex;
  justify-content: 'center';
  padding-top: 10px;
  border-top: 1px solid #DBC48E;
}

.buySubscriptionOuter {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
}

.buySubscriptions {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  /* margin-left: 20vh; */
}


.buySubscriptions {
  background: linear-gradient(97.47deg, #FFB200 0%, #EEAA0C 100%);
  color: #fff;
}

.buySubscriptions:hover {
  background-color: #d4ac0d;
}

/* .subscription-renewal-modal {
  position: 'fixed';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: 'rgba(0, 0, 0, 0.5)';
  z-index: 1000
} */


@media screen and (max-width: 767px) {
  .home-top .allPlaylist {
    padding-top: 0;
  }

  .home-top {
    padding: 80px 0px 40px;
  }

  .title-wrapper p {
    font-size: 20px;
  }

  .watch-later .slick-slider {
    padding-right: 5px;
  }

  .allPlaylist .custom-col-4.custom-col {
    margin-bottom: 0;
    padding-left: 5px;
    padding-right: 5px;
  }

  .watch-later .title-wrapper {
    /* padding-right: 0; */
  }

  .home-top-main-banner {
    position: relative;
    display: none;
  }

  .home-title {
    display: none;
  }

  .mobile-live-satsang {
    box-shadow: 1px 3px 15px 0px #00000026;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
    display: inherit;
  }

  .mobile-live-satsang-wrap {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #FFFCF5;
  }

  .mobile-live-satsang-wrap .play-list-image {
    max-width: 100%;
    width: 100%;
    position: relative;
    flex-shrink: 0;
  }

  .mobile-live-satsang-wrap .img-banner {
    width: 100%;
    /* height: 150px; */
    display: flex;
    /* object-fit: cover; */
    object-position: center;
  }

  .play-play-icon {
    position: absolute;
    right: 0px;
    width: 70px;
  }

  .mobile-live-satsang-card-content {
    padding: 15px 10px 10px;
  }

  .mobile-live-satsang-play-video {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .mobile-live-satsang-title {
    font-size: 15px;
    color: #212121;
    text-align: left;
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 19.5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .home-search-box-mobile {
    display: flex;
  }

  .live-satsang-title {
    font-size: 20px;
  }

  .home-top .allPlaylist .list-of-like-icon-div {
    text-align: right;
    justify-content: end;
  }

  .search-input input {
    font-size: 15px;
  }

  .modal {
    width: 94%;
    height: auto;
    padding: 0px 0px 10px 0px;
    margin-top: 50px;
  }

  .buySubscriptionOuter {
    margin: 0 auto;
  }

  .buySubscriptions {
    margin: 0 auto;
  }


}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .home-top-banner {
    height: 267px;
  }

  .home-top-img-banner {
    height: 267px;
  }

  .home-title {
    font-size: 33px;
  }

  .live-satsang-title {
    font-size: 23px;
  }

  .bottom-left p {
    font-size: 25px;
    width: 400px;
  }

  .title-wrapper p {
    font-size: 23px;
  }



}