.editProfile {
  padding-top: 105px;
}

.editProfile .profileInner {
  background: #FFFCF5;
  overflow: auto;
  /* background: url("../../../assets/Phase2/profileBgMob.svg"); */
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
}

.editProfile .profileInner .userImage {
  text-align: center;
  padding: 30px 0px 0px 0px;
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto;
}
.editProfile .userImage .user {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 5px solid #f1ac0a;
}
.editProfile .userImage .editIcon {
  position: absolute;
  top: 120px;
  right: -5px;
  cursor: pointer;
}

.editProfile .userImage .userName {
  font-size: 20px;
  line-height: 30px;
  color: #212121;
  font-weight: 400;
  margin: 0;
  padding-top: 10px;
}
.editProfile .userImage .viewProfile {
  font-size: 15px;
  line-height: 25.5px;
  margin: 0;  
  text-transform: uppercase;
  color: #FFB200;
  font-weight: 600;
}

.editProfile .logout {
  font-size: 15px;
  line-height: 25.5px;
  margin-top: 50px;
  text-transform: uppercase;
  color: #FFB200;
  font-weight: 600;
}

.editProfile button.btn {
  width: 90%;
  border-radius: 10px;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  border: none;
  padding: 10px 10px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.editProfile button.btn .title {
  font-size: 17px;
  line-height: 25.5px;
  margin: 0;
  font-weight: 400;
  text-transform: capitalize;
  color: #212121;
  padding-left: 10px;
  font-family: Poppins;
}
button.btn .arrrowRight {
  position: absolute;
  right: 5px;
  top: 7px;
}




.editProfile .profileInner {
  padding-bottom: 30px;
}

.editProfile .profileInner .SubscriptionCard {
  /* box-shadow: 1px 3px 15px 0px #00000026; */
  border-radius: 10px;
  /* margin: 30px 15px; */
  padding: 10px;
  /* background: #FFFCF5; */
  margin-top: -60px;
  padding-top: 70px;
}
.editProfile .profileInner .SubscriptionCard .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DBC48E;
}
.editProfile .profileInner .SubscriptionCard .header .title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  margin: 0;
  color: #212121;
}
.editProfile .profileInner .SubscriptionCard .header .status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.editProfile .profileInner .SubscriptionCard .header .status .active {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #179C4D;
  margin-right: 5px;
}
.editProfile .profileInner .SubscriptionCard .header .status .text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  margin: 0;
  color: #212121;
}
.editProfile .profileInner .SubscriptionCard .header .status .text.activeText {
  text-transform: uppercase;
}
.editProfile .profileInner .SubscriptionCard .desc {
  font-size: 30px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: #212121;
}
.editProfile .profileInner .SubscriptionCard .desc .amount {
  font-family: Poppins;
}
.editProfile .profileInner .SubscriptionCard .desc .subText {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #8C8C8C;
}

.editProfile .profileInner .SubscriptionCard .benefits ul li {
  list-style-image: url('../../../assets/Phase2/beneIcon.svg');
  padding-inline-start: 1ch;
  padding-bottom: 15px;
}

.editProfile .profileInner .SubscriptionCard .buySubscriptionOuter {
  text-align: center;
}
.editProfile .profileInner .SubscriptionCard .buySubscriptionOuter .btn.buySubscription {
  border: none;
  background: linear-gradient(97.47deg, #FFB200 0%, #EEAA0C 100%);
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 10px;
}

.editProfile .profileInner .historyTitle {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 30px;
  margin-bottom: -20px;
}
.editProfile .profileInner .SubscriptionCard .history {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  gap: 30px;
}
.editProfile .profileInner .SubscriptionCard .history .left {
  width: 50%;
}
.editProfile .profileInner .SubscriptionCard .history .right {
  width: 50%;
}
.editProfile .profileInner .SubscriptionCard .history .title {
  font-size: 15px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 0;
  margin-top: 20px;
}
.editProfile .profileInner .SubscriptionCard .history .subTitle {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  margin: 0;
}

.editProfile .profileInner .mainTitle {
  font-family: Poppins;
  font-size: 35px;
  font-weight: 400;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  display: none;
}
.editProfile .profileInner .subscriptionPlanListInner .first {
  box-shadow: none;
  border: none;
  border-radius: 0;
  padding: 0px;
}
.editProfile .profileInner .subscriptionPlanListInner .first .plansTitle {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
  padding: 0 10px;
  margin-bottom: -20px;
  display: none;
  margin-top: 0;
}
.editProfile .profileInner .SubscriptionCard .benefits .title {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5px;
  color: #8C8C8C;
}
.editProfile .profileInner .SubscriptionCard .benefits ul {
  padding-inline-start: 30px;
}

.editProfile .profileInner .SubscriptionCard .history input {
  font-size: 17px;
  width: 100%;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  border: none;
  border-radius: 10px;
  padding: 11px 10px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: #212121;
  box-sizing: border-box;
  height: 45px;
  margin: 0;
}
.editProfile .profileInner .SubscriptionCard .history .full {
  width: 100%;
}
.editProfile .profileInner .SubscriptionCard .history .full input {
  /* width: 90%; */
}
.editProfile .profileInner .SubscriptionCard .history select {
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  border: none;
  border-radius: 10px;
  padding: 11px 10px;
  width: 100%;
  margin: 0;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: #212121;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../../../assets/Phase2/arrowDownOrange.svg") 90% / 15px no-repeat;
  box-sizing: border-box;
}
.editProfile .profileInner .SubscriptionCard .history.mobNo .left {
  width: 30%;
}
.editProfile .profileInner .SubscriptionCard .history.mobNo .right {
  width: 70%;
}
.editProfile .profileInner .SubscriptionCard .history.mobNo .left select {
  /* width: 90%; */
}
.editProfile .profileInner .SubscriptionCard .history.mobNo .right input {
  /* width: 86%; */
}

.editProfile .saveCancelBtn {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-top: 30px;
}
.editProfile .saveCancelBtn button.btn {
  width: 40%;
  border-radius: 10px;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  border: none;
  background: linear-gradient(97.47deg, #FFB200 0%, #EEAA0C 100%);
  height: 46px;
  display: block;
  margin: inherit;
}
.editProfile .saveCancelBtn button.btn .title {
  padding: 0;
  font-weight: 600;
  text-transform: uppercase;
}
.editProfile .saveCancelBtn button.btn.save .title {
  color: #fff;
}
.editProfile .saveCancelBtn button.btn.saveDisabled .title {
  color: #fff;
}
.editProfile .saveCancelBtn button.btn.cancel {
  background: transparent;
  color: #EEAA0C;
  box-shadow: none;
}
.editProfile .saveCancelBtn button.btn.saveDisabled {
  background:  #b2b2b2;

  box-shadow: none;
}
.editProfile .saveCancelBtn button.btn.cancel .title {
  color: #EEAA0C;
}
.editProfile .heading {
  position: absolute;
  top: 8px;
  display: flex;
  gap: 10px;
  left: 10px;
}
.editProfile .heading p {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}

.editUserImage .modal-msg-content .modal-close-button {
  position: absolute;
  right: 10px;
  top: 5px;
  width: auto;
  color: #FFB200;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.MuiDialog-paper {
  overflow: visible !important;
  border-radius: 10px !important;
}
.editUserImage .userImage .user {
  width: 120px;
  height: 120px; 
  background-size: contain;
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999;
  border-radius: 50%;
  border:5px solid  #FFB200;
}
.editUserImage .options {
  padding-top: 50px;
  padding-bottom: 20px;
}
.editUserImage .options .optionsInner {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  cursor: pointer;
}
.editUserImage .options .optionsInner p {
  font-size: 17px;
  margin: 0;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.editProfile .header-jc-sp-c {
  padding: 0 15px;
}
.editProfile .header-jc-sp-c .mob-header-title {
  font-size: 35px;
}
.editProfile .profileInner .SubscriptionCard .history .history.mobNo {
  gap: 15px;
}

.MuiAutocomplete-popper{
  min-width: 300px !important;
}

.editProfile .profileInner .SubscriptionCard .history .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot {
  padding-right: 0px;
}



@media (min-width: 768px) {
  .editProfile .heading {
    position: relative;
    width: max-content;
  }
  .editProfile .profileInner .SubscriptionCard {
    /* box-shadow: 1px 3px 15px 0px #00000026; */
  }
  .editProfile .profileInner .mainTitle {
    display: block;
  }
  .editProfile .profileInner .SubscriptionCard .benefits .title {
    font-size: 17px;
  }
  .editProfile .profileInner .subscriptionPlanListInner .first {
    box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
    border: 1px solid #EEAA0C;
    border-radius: 10px;
    padding: 30px 20px;
    width: 65%;
  }
  .editProfile .profileInner .subscriptionPlanListInner {
    display: flex;
    justify-content: space-between;
  }
  .editProfile .profileInner .subscriptionPlanListInner .second {
    width: 60%;
  }
  .editProfile .profileInner .subscriptionPlanListInner .second .SubscriptionCard {
    margin-right: 0;
  }
  .editProfile .profileInner .subscriptionPlanListInner .first .plansTitle {
    display: block;
  }
  .editProfile .profileInner .subscriptionPlanListInner .second .historyTitle {
    margin-top: 0;
    padding-left: 15px;
    font-size: 25px;
  }
  .editProfile .profileInner .SubscriptionCard .header .title {
    font-size: 21px;
  }
  .editProfile .profileInner .SubscriptionCard .header .status .text {
    font-size: 15px;
  }
  .editProfile .profileInner .SubscriptionCard .buySubscriptionOuter .btn.buySubscription {
    font-size: 17px;
  }
  .editProfile .profileInner {
    background: url("../../../assets/Phase2/profileBg.svg");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    position: relative;
    margin-right: -30px;
  }
  .editProfile .saveCancelBtn {
    display: none;
  }
  .editProfile .m-hide .saveCancelBtn {
    width: 300px;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }



}

@media (min-width: 991px) {
  .editProfile .profileInner {
    /* padding: 0 100px; */
  }
}




@media (max-width: 767px) {
  .editProfile {
    height: 100vh;
    max-height: calc(100vh - 80px);
    padding-top: 85px;
    padding-bottom: 50px;
  }
  .editProfile .profileInner .SubscriptionCard .history .title {
    font-size: 13px;
  }
  .editProfile .profileInner .SubscriptionCard .history input {
    font-size: 15px;
  }
  .editProfile .header-jc-sp-c .mob-header-title {
    font-size: 20px;
  }
  .editProfile .profileInner .SubscriptionCard .history {
    gap: 20px;
  }

  .editProfile .profileInner .SubscriptionCard .history.mobFull {
    display: block;
  }
  .editProfile .profileInner .SubscriptionCard .history.mobFull .left {
    width: 100%;
  }
  .editProfile .profileInner .SubscriptionCard .history.mobFull .right {
    width: 100%;
  }
  .editProfile .profileInner .SubscriptionCard .history.mobFull .right .history.mobNo .left {
    width: 30%;
  }
  .editUserImage .options .optionsInner p {
    font-size: 15px;
  }



}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .editProfile .header-jc-sp-c .mob-header-title {
    font-size: 33px;
  }
  .editProfile .profileInner .subscriptionPlanListInner .second {
    width: 100%;
    padding-right: 30px;
  }
  .editProfile .profileInner {
    height: 100vh;
    max-height: calc(100vh - 190px);
    background-size: 60% 40%;
    background-position: 135% 100%
  }



}