.albumList-page {
  padding: 0px 15px;
  padding-bottom: 50px;
  padding-top: 105px;
}

.albumList-page .albumList-page-title-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.albumList-page .albumList-page-title-outer .albumList-page-title {
  /* margin-top: 20px; */
}

.albumList-page .albumList-page-title-outer .albumList-page-title .my-favorites-d-flex {
  padding: 0;
}

.albumList-page .top {
  margin-top: 43px;
  box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
  border: 1px solid #EEAA0C;
  border-radius: 10px;
  padding: 22px;
  display: flex;
  align-items: center;
  gap: 22px;
}

.albumList-page .top .topImg {
  width: 131px;
  height: 131px;
  border-radius: 8px;
}

.albumList-page .top .label {
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  color: #212121;
  margin: 0;
  text-transform: uppercase;
  font-family: Poppins;
}

.albumList-page .mainTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
}

.albumList-page .mainTitleOuter .viewMore {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}

.albumList-page .mainTitleOuter .mainTitle {
  letter-spacing: 0em;
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
  margin: 0;
}

.albumList-page .aboutUs-img-div {
  margin-bottom: 15px;
  text-align: center;
}

.albumList-page .aboutUs-img-div .AbousImage {
  width: 100%;
  margin: 15px auto;
  border-radius: 10px;
}

.albumList-page .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  padding: 15px;
  background: #fffcf5;
}

.albumList-page .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;

}

.albumList-page .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.table-responsive {
  overflow: auto;
}


.albumList-page .albumOuter {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 25px;
}

.albumList-page .albumOuter.noData {
  display: flex;
}

.albumList-page .albumOuter .album {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.albumList-page .albumOuter .album .albumTop {
  background: #fff;
  border-radius: 10px;
  position: relative;
  box-shadow: 1px 3px 15px 0px #00000026;
}

.albumList-page .albumOuter .album .albumTop .albumImg {
  width: 100%;
  height: 100%;
}

.albumList-page .albumOuter .album .albumTop .albumHover {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  border-radius: 10px;
}

.albumList-page .albumOuter .album .albumTop .albumHover::before {
  content: '';
  position: absolute;
  background: #000;
  opacity: 0.5;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  border-radius: 10px;
}

.albumList-page .albumOuter .album .albumTop:hover .albumHover {
  opacity: 1;
}

.albumList-page .albumOuter .album .albumTop .albumHover .albumInnerPlayIconOuter {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: -webkit-fill-available;
}

.albumList-page .albumOuter .album .albumTop .albumHover .albumInnerPlayIconOuter .albumInnerPlayIcon {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 48px;
  height: 48px;
}

.albumList-page .albumOuter .album .albumTop .albumHover .albumHoverInner {
  position: absolute;
  z-index: 2;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.albumList-page .albumOuter .album .albumBottom .name {
  color: #212121;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.albumList-page .albumOuter .album .albumBottom .desc {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}

.albumList-page .albumOuter .album .albumBottom .desc .time {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* width: 70px; */
}

.albumList-page .albumOuter .album .albumBottom .desc .dot {
  width: 7px;
  height: 7px;
  background: #BCBCBC;
  border-radius: 50px;
  margin: 0;
}

.albumList-page .albumOuter .album .albumBottom .desc .count {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-responsive .table {
  margin: 0;
}

.albumList-page .favTooltip:hover .tooltiptext {
  left: auto;
  right: 0;
  cursor: pointer;
}


/* 
.albumList-page.ytPlayerOpen .albumOuter {
  padding-bottom: 90px;
} */

@media (max-width: 991px) {
  .albumList-page .top .label {
    font-size: 32px;
  }

  .albumList-page .mainTitleOuter .mainTitle {
    font-size: 23px;
    line-height: 34.5px;
  }

  .albumList-page .albumOuter {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .albumList-page .albumOuter .album .albumBottom .desc .time {
    width: 75px;
  }

  .albumList-page .albumOuter {
    gap: 20px;
  }
}


@media (max-width: 767px) {
  /* .popular-bhaktiPads-page .table-responsive .table .actions .favTooltip {
    display: none;
  } */

  .albumList-page {
    padding-top: 50px;
  }

  .albumList-page .albumList-page-title-outer {
    display: inline;
  }

  .albumList-page .albumList-page-title-outer .albumList-page-title {
    display: none;
  }

  .albumList-page .top .label {
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }

  .albumList-page .mainTitleOuter .mainTitle {
    font-size: 18px;
    line-height: 27px;
  }

  .albumList-page .mainTitleOuter .viewMore {
    height: auto;
    padding: 0;
    font-size: 13px;
    line-height: 19.5px;
  }

  .albumList-page .mainTitleOuter {
    display: none;
  }

  /* .mobHide {
    display: none;
  } */


  .albumList-page .albumOuter .album .albumBottom .name {
    font-size: 12px;
    line-height: 18px;
  }

  .albumList-page .albumOuter .album .albumBottom .desc .time {
    font-size: 10px;
    line-height: 15px;
  }

  .albumList-page .albumOuter .album .albumBottom .desc .dot {
    width: 4px;
    height: 4px;
  }

  .albumList-page .albumOuter .album .albumBottom .desc .count {
    font-size: 10px;
    line-height: 15px;
  }

  .albumList-page .albumOuter {
    gap: 7.5px;
  }

  .albumList-page .albumOuter .album .albumBottom .desc .time {
    width: 60px;
  }

}

@media (min-width: 768px) {
  .albumList-page .albumList-page-title-outer .list-of-play-search {
    display: none;
  }
}
