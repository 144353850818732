/*----- New CSS Start -----*/
.myCreatedTrackAddButton {
  padding-top: 65px;
}

.create-playlist-add-track {
  padding-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.create-playlist-add-track .create-playlist-add-track-top-div,
.create-playlist-add-track .add-any-add-track-div,
.create-playlist-add-track .suggested-add-track-div .suggested-add-track-title {
  padding: 0 5px;
}

.add-any-add-track-div {
  text-align: center;
  margin-top: 20px;
}

.add-any-add-track-txt {
  font-size: 23px;
  color: #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.add-add-track-btn {
  font-size: 17px;
  width: 140px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid;
  border-color: #FFB200;
  background-color: #FFB200;
  color: #FFFFFF;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 10px;
}

.suggested-add-track-title {
  font-size: 25px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: left;
}

.bhaktiPads-suggested-add-track {
  padding: 0px 15px;
}

/* Table css */
.bhaktiPads-suggested-add-track .table-responsive .table th,
.bhaktiPads-suggested-add-track .table-responsive .table td {
  border: none;
}

.bhaktiPads-suggested-add-track .table-responsive .table th:nth-of-type(1) {
  padding-left: 0;
}

.bhaktiPads-suggested-add-track .table-responsive .table td:nth-of-type(1) {
  padding-left: 0;
}

.bhaktiPads-suggested-add-track .table-responsive .table td:nth-last-child(1) {
  padding-right: 0;
}

.bhaktiPads-suggested-add-track .table-responsive .table th {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
}

.bhaktiPads-suggested-add-track .table-responsive .table th {
  border-bottom: 1px solid #DBC48E;
}

.bhaktiPads-suggested-add-track .table-responsive .table .srNo {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  margin: 0;
  text-align: left;
}

.bhaktiPads-suggested-add-track .table-responsive .table .srNo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.bhaktiPads-suggested-add-track .table-responsive .table .srNo .no {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  color: #212121;
}

.bhaktiPads-suggested-add-track .table-responsive .table .srNo .bhaktiImg {
  width: 60px;
  height: 60px;
  border-radius: 8px;
}

.bhaktiPads-suggested-add-track .table-responsive .table .titleSection .title {
  margin: 0;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bhaktiPads-suggested-add-track .table-responsive .table .titleSection .subTitle {
  margin: 0;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  color: #6B6B6B;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bhaktiPads-suggested-add-track .table-responsive .table .album {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  margin: 0;
  color: #6B6B6B;
}

.bhaktiPads-suggested-add-track .table-responsive .table .duration {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
}

.bhaktiPads-suggested-add-track .table-responsive .table .actions {
  display: flex;
  gap: 15px;
  align-items: center;
}


.bhaktiPads-suggested-add-track .top .label {
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  color: #212121;
  margin: 0;
  text-transform: uppercase;
  font-family: Poppins;
}

.bhaktiPads-suggested-add-track .mainTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
}

.bhaktiPads-suggested-add-track .mainTitleOuter .viewMore {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
}

.bhaktiPads-suggested-add-track .mainTitleOuter .mainTitle {
  letter-spacing: 0em;
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
  margin: 0;
}

.bhaktiPads-suggested-add-track .aboutUs-img-div {
  margin-bottom: 15px;
  text-align: center;
}

.bhaktiPads-suggested-add-track .aboutUs-img-div .AbousImage {
  width: 100%;
  margin: 15px auto;
  border-radius: 10px;
}

.bhaktiPads-suggested-add-track .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  padding: 15px;
  background: #fffcf5;
}

.bhaktiPads-suggested-add-track .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;

}

.bhaktiPads-suggested-add-track .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.table-responsive {
  overflow: auto;
}

/* Table css */

.table-responsive .table {
  margin: 0;
}

/* three dot css start*/
.popupOuter {
  position: relative;
  z-index: 1;
}

.popular-bhaktiPads-page .morePopup {
  position: absolute;
  right: 20px;
  top: 20px;
  background: #FDFDFD;
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 4px 10px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 12px 15px;
  z-index: 9;
}

.popular-bhaktiPads-page .table-responsive .table tbody tr:nth-last-child(1) .morePopup {
  top: -35px;
}

.popular-bhaktiPads-page .morePopup .outer {
  display: flex;
  gap: 10px;
  align-items: center;
  width: max-content;
}

.popular-bhaktiPads-page .morePopup .outer .label {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8c8c8c;
  margin: 0;
}


@media (max-width: 991px) {
  .popular-bhaktiPads-page .morePopup {
    display: none;
  }

  .bhaktiPads-suggested-add-track .table-responsive .table th.none {
    display: none;
  }

  .bhaktiPads-suggested-add-track .table-responsive .table td.none {
    display: none;
  }

  .bhaktiPads-suggested-add-track .table-responsive .table .titleSection {
    max-width: 290px;
  }

  .bhaktiPads-suggested-add-track .mainTitleOuter .mainTitle {
    font-size: 23px;
    line-height: 34.5px;
  }
}


@media (max-width: 767px) {
  .bhaktiPads-suggested-add-track .mainTitleOuter .mainTitle {
    font-size: 18px;
    line-height: 27px;
  }

  .bhaktiPads-suggested-add-track .mainTitleOuter .viewMore {
    height: auto;
    padding: 0;
    font-size: 13px;
    line-height: 19.5px;
  }

  .bhaktiPads-suggested-add-track .mainTitleOuter {
    padding-top: 30px;
  }

  /* .mobHide {
    display: none;
  } */

  .bhaktiPads-suggested-add-track .table-responsive .table .actions .more {
    transform: rotate(90deg);
  }

  .bhaktiPads-suggested-add-track .table-responsive .table .srNo .bhaktiImg {
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }

  .bhaktiPads-suggested-add-track .table-responsive .table .titleSection .title {
    font-size: 15px;
    line-height: 22.5px;
  }

  .bhaktiPads-suggested-add-track .table-responsive .table .titleSection .subTitle {
    font-size: 13px;
    line-height: 19.5px;
  }

  .bhaktiPads-suggested-add-track .table-responsive .table td {
    padding: 10px;
  }

  .bhaktiPads-suggested-add-track .table-responsive .table .titleSection {
    max-width: 210px;
  }
}

/* @media screen and (max-width: 767px) {
  
} */

/*----- New CSS End -----*/
