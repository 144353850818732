.bhaktiPadsTrackAllList {
  padding: 0px 15px;
  margin-top: 85px;
}

/* Table css */
.bhaktiPadsTrackAllList .table-responsive .table th,
.bhaktiPadsTrackAllList .table-responsive .table td {
  border: none;
}

.bhaktiPadsTrackAllList .table-responsive .table th:nth-of-type(1) {
  padding-left: 0;
}

.bhaktiPadsTrackAllList .table-responsive .table td:nth-of-type(1) {
  padding-left: 0;
}

.bhaktiPadsTrackAllList .table-responsive .table td:nth-last-child(1) {
  padding-right: 0;
}

.bhaktiPadsTrackAllList .table-responsive .table th {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
}

.bhaktiPadsTrackAllList .table-responsive .table th {
  border-bottom: 1px solid #DBC48E;
}

.bhaktiPadsTrackAllList .table-responsive .table .srNo {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  margin: 0;
  text-align: left;
}

.bhaktiPadsTrackAllList .table-responsive .table .srNo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.bhaktiPadsTrackAllList .table-responsive .table .srNo .no {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  color: #212121;
}

.bhaktiPadsTrackAllList .table-responsive .table .srNo .bhaktiImg {
  width: 60px;
  height: 60px;
  border-radius: 8px;
}

.bhaktiPadsTrackAllList .table-responsive .table .titleSection .title {
  margin: 0;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bhaktiPadsTrackAllList .table-responsive .table .titleSection .subTitle {
  margin: 0;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  color: #6B6B6B;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bhaktiPadsTrackAllList .table-responsive .table .album {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  margin: 0;
  color: #6B6B6B;
}

.bhaktiPadsTrackAllList .table-responsive .table .duration {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
}

.bhaktiPadsTrackAllList .table-responsive .table .actions {
  display: flex;
  gap: 15px;
  align-items: center;
}


.bhaktiPadsTrackAllList .top .label {
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  color: #212121;
  margin: 0;
  text-transform: uppercase;
  font-family: Poppins;
}

.bhaktiPadsTrackAllList .mainTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
}

.bhaktiPadsTrackAllList .mainTitleOuter .viewMore {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
}

.bhaktiPadsTrackAllList .mainTitleOuter .mainTitle {
  letter-spacing: 0em;
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
  margin: 0;
}

.bhaktiPadsTrackAllList .aboutUs-img-div {
  margin-bottom: 15px;
  text-align: center;
}

.bhaktiPadsTrackAllList .aboutUs-img-div .AbousImage {
  width: 100%;
  margin: 15px auto;
  border-radius: 10px;
}

.bhaktiPadsTrackAllList .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  padding: 15px;
  background: #fffcf5;
}

.bhaktiPadsTrackAllList .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;

}

.bhaktiPadsTrackAllList .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.table-responsive {
  overflow: auto;
}

/* Table css */

.table-responsive .table {
  margin: 0;
}

.bhaktiPadsTrackAllList .bhaktiPads-track-list-d-flex {
  display: flex;
  cursor: pointer;
}

.bhaktiPadsTrackAllList .bhaktiPads-track-list-c-pointer {
  cursor: pointer;
}

.bhaktiPadsTrackAllList .bhaktiPads-track-list-title {
  font-size: 35px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-left: 10px;
}

.bhaktiPadsTrackAllList .bhaktiPads-track-list-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bhaktiPadsTrackAllList .bhaktiPads-track-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .bhaktiPadsTrackAllList .table-responsive .table th.none {
    display: none;
  }

  .bhaktiPadsTrackAllList .table-responsive .table td.none {
    display: none;
  }

  .bhaktiPadsTrackAllList .table-responsive .table .titleSection {
    max-width: 290px;
  }

  .bhaktiPadsTrackAllList .mainTitleOuter .mainTitle {
    font-size: 23px;
    line-height: 34.5px;
  }
}


@media (max-width: 767px) {
  .bhaktiPadsTrackAllList .mainTitleOuter .mainTitle {
    font-size: 18px;
    line-height: 27px;
  }

  .bhaktiPadsTrackAllList .mainTitleOuter .viewMore {
    height: auto;
    padding: 0;
    font-size: 13px;
    line-height: 19.5px;
  }

  .bhaktiPadsTrackAllList .mainTitleOuter {
    padding-top: 30px;
  }

  /* .mobHide {
    display: none;
  } */

  .bhaktiPadsTrackAllList .table-responsive .table .actions .more {
    transform: rotate(90deg);
  }

  .bhaktiPadsTrackAllList .table-responsive .table .srNo .bhaktiImg {
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }

  .bhaktiPadsTrackAllList .table-responsive .table .titleSection .title {
    font-size: 15px;
    line-height: 22.5px;
  }

  .bhaktiPadsTrackAllList .table-responsive .table .titleSection .subTitle {
    font-size: 13px;
    line-height: 19.5px;
  }

  .bhaktiPadsTrackAllList .table-responsive .table td {
    padding: 10px;
  }

  .bhaktiPadsTrackAllList .table-responsive .table .titleSection {
    max-width: 210px;
  }

  .bhaktiPadsTrackAllList .bhaktiPads-track-list-title-mt-20 {
    display: block;
    margin: 0;
  }

  .bhaktiPadsTrackAllList .bhaktiPads-track-list-header .header-create-btn {
    color: #F6AE06;
    text-align: right;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-block-start: inherit;
    margin-block-end: inherit;
    margin-left: 20px;
    cursor: pointer;
  }
}