.player-container-outer .player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.player-container-outer .player-image img {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
}
.react-pdf__Document {
  /* left: 29vw;
  right: 29vw; */
}
.player-container-outer .player-details {
  margin-top: 15px;
  text-align: center;
}

.player-container-outer .player-controls {
  margin-top: 15px;
  display: flex;
  gap: 15px;
}

.player-container-outer .player-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}

.player-container-outer progress {
  flex-grow: 1;
  margin: 0 10px;
  appearance: none;
  height: 10px;
}

.player-container-outer progress::-webkit-progress-bar {
  background-color: #e0e0e0;
  border-radius: 5px;
}

.player-container-outer progress::-webkit-progress-value {
  background-color: #3498db;
  border-radius: 5px;
}

.player-container-outer button {
  background-color: #FFB200;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.player-container-outer button:hover {
  background-color: orange;
}

.player-container-outer span {
  font-size: 14px;
}
.player-container-outer {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 0px auto;
  align-items: center;
  height: 100vh;
  vertical-align: middle;
  display: flex;
  justify-content: center;
}
.player-container-outer .player-container {
  max-width: 300px;
  /* margin: 50px auto;
  vertical-align: middle; */
}

.player-container-outer .player-controls button img {
  height: 10px;
}
.pdf-view-styles {

   
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin: auto;
  overflow-y: auto;
  max-height: 100%;


}
@media screen and (min-width: 767px) {
  .d-hide {
    display: none;
  }

  .m-hide {
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  .pdf-view-styles {

   
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin:auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin: auto;
    overflow-y: auto;
    max-height: 100%;
  
  
  }
}