.sparsh-page {
  padding: 0px 15px;
  padding-bottom: 100px;
  padding-top: 65px;
}

.sparsh-page .top {
  margin-top: 24px;
  box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
  border: 1px solid #EEAA0C;
  border-radius: 10px;
  padding: 22px;
  display: flex;
  align-items: center;
  gap: 22px;
}

.sparsh-page .top .topImg {
  width: 131px;
  height: 131px;
  border-radius: 8px;
}

.sparsh-page .list-of-play-search {
  display: none;
}


.table-responsive {
  overflow: auto;
}


.sparsh-page .tableHover:hover {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
}

.srNo {
  position: relative;
}

/* Show the play button on row hover */



.sparsh-page .favTooltip:hover .tooltiptext {
  left: auto;
  right: 0;
  cursor: pointer;
}




/* Table css */
.sparsh-page table {
  width: 100%;
}

.sparsh-page .titleSection {
  width: 600px;
  display: block;
  overflow: hidden;
}

.sparsh-page .table-responsive .table th,
.sparsh-page .table-responsive .table td {
  border: none;
}

.sparsh-page .table-responsive .table th:nth-of-type(1) {
  padding-left: 0;
}

.sparsh-page .table-responsive .table td:nth-of-type(1) {
  padding-left: 0;
}

.sparsh-page .table-responsive .table td:nth-last-child(1) {
  padding-right: 0;
}

.sparsh-page .table-responsive .table th {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
}

.sparsh-page .table-responsive .table th {
  border-bottom: 1px solid #DBC48E;
}

.sparsh-page .table-responsive .table .srNo {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  margin: 0;
  text-align: left;
}

.sparsh-page .table-responsive .table .srNo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.sparsh-page .table-responsive .table .srNo .no {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  color: #212121;
}

.sparsh-page .table-responsive .table .srNo .bhaktiImg {
  width: 60px;
  height: 60px;
  border-radius: 8px;
}

.sparsh-page .table-responsive .table .titleSection {
  max-width: 400px;
}

.sparsh-page .table-responsive .table .titleSection .title {
  margin: 0;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
  min-width: 400px;
}

.sparsh-page .table-responsive .table .titleSection .subTitle {
  margin: 0;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  color: #6B6B6B;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sparsh-page .table-responsive .table .album {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  margin: 0;
  color: #6B6B6B;
}

.sparsh-page .table-responsive .table .duration {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
}

.sparsh-page .table-responsive .table .actions {
  display: flex;
  gap: 15px;
  align-items: center;
  position: relative;
}


.sparsh-page .top .label {
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  color: #212121;
  margin: 0;
  text-transform: uppercase;
  font-family: Poppins;
}

.sparsh-page .mainTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
}

.sparsh-page .mainTitleOuter .viewMore {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
}

.sparsh-page .mainTitleOuter .mainTitle {
  letter-spacing: 0em;
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
  margin: 0;
}

.sparsh-page .aboutUs-img-div {
  margin-bottom: 15px;
  text-align: center;
}

.sparsh-page .aboutUs-img-div .AbousImage {
  width: 100%;
  margin: 15px auto;
  border-radius: 10px;
}

.sparsh-page .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  padding: 15px;
  background: #fffcf5;
}

.sparsh-page .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;

}

.sparsh-page .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.table-responsive {
  overflow: auto;
}

/* Table css */

.sparsh-page .albumOuter {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 25px;
}

.sparsh-page .albumOuter .album {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sparsh-page .albumOuter .album .albumTop {
  background: #fff;
  border-radius: 10px;
  position: relative;
  box-shadow: 1px 3px 15px 0px #00000026;
}

.sparsh-page .albumOuter .album .albumTop .albumImg {
  width: 100%;
  height: 100%;
}

.sparsh-page .albumOuter .album .albumTop .albumHover {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  border-radius: 10px;
}

.sparsh-page .albumOuter .album .albumTop .albumHover::before {
  content: '';
  position: absolute;
  background: #000;
  opacity: 0.5;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  border-radius: 10px;
}

.sparsh-page .albumOuter .album .albumTop:hover .albumHover {
  opacity: 1;
}

.sparsh-page .albumOuter .album .albumTop .albumHover .albumInnerPlayIconOuter {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  /* height: -webkit-fill-available; */
  top: 50%;
}

.sparsh-page .albumOuter .album .albumTop .albumHover .albumInnerPlayIconOuter .albumInnerPlayIcon {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 48px;
  height: 48px;
}

.sparsh-page .albumOuter .album .albumTop .albumHover .albumHoverInner {
  position: absolute;
  z-index: 1;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.sparsh-page .albumOuter .album .albumBottom .name {
  color: #212121;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sparsh-page .albumOuter .album .albumBottom .desc {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}

.sparsh-page .albumOuter .album .albumBottom .desc .time {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* width: 70px; */
}

.sparsh-page .albumOuter .album .albumBottom .desc .dot {
  width: 7px;
  height: 7px;
  background: #BCBCBC;
  border-radius: 50px;
  margin: 0;
}

.sparsh-page .albumOuter .album .albumBottom .desc .count {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sparsh-page .table-responsive .table .srNo .no {
  width: 30px;
}


/* .table-responsive .table {
  margin: 0;
} */

/* three dot css start*/
.popupOuter {
  position: relative;
  z-index: 1;
}

.popupOuterForCurrentPlaylist {
  position: relative;
  z-index: 1;
  display: none;
}



/*.popupOuter .morePopup */
.sparsh-page .morePopup {
  position: absolute;
  right: 20px;
  top: 50px;
  background: #FDFDFD;
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 4px 10px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 12px 15px;
  z-index: 9;
}

.sparsh-page .table-responsive .table tbody tr:nth-last-child(1) .morePopup {
  top: -5px;
}

.sparsh-page .morePopup .outer {
  display: flex;
  gap: 10px;
  align-items: center;
  width: max-content;
}

.sparsh-page .morePopup .outer .label {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8c8c8c;
  margin: 0;
}

.popupVisibleOuter {
  position: absolute;
}

.actionPopupMobForCurrentPlaylist {
  right: 10px;
  top: 30px;
  border-radius: 4px 10px;
  display: flex;
  /* gap: 24px; */
  flex-direction: column;
  padding: 0px 15px 12px 2px;
}

/* three dot css END*/


@media (max-width: 991px) {



  .sparsh-page .albumOuter .album .albumTop.albumLockOuter .albumHover .albumInnerPlayIconOuter {
    top: 35%;
  }

  .sparsh-page .table-responsive .table .titleSection .title {
    min-width: auto;
  }

  .sparsh-page .morePopup {
    display: none;
  }

  .sparsh-page .table-responsive .table th.none {
    display: none;
  }

  .sparsh-page .table-responsive .table td.none {
    display: none;
  }

  .sparsh-page .table-responsive .table .titleSection {
    max-width: 290px;
  }

  .sparsh-page .mainTitleOuter .mainTitle {
    font-size: 23px;
    line-height: 34.5px;
  }

  .sparsh-page .albumOuter {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sparsh-page .albumOuter .album:nth-of-type(n+4) {
    display: none;
  }

  .sparsh-page .albumOuter .album .albumBottom .desc .time {
    width: 75px;
  }

  .sparsh-page .albumOuter {
    gap: 20px;
  }


  .popupOuterForCurrentPlaylist {
    display: none;
  }
}


@media (max-width: 767px) {
  .sparsh-page .albumOuter .album .albumTop.albumLockOuter .albumHover .albumInnerPlayIconOuter {
    top: 25%;
  }


  .sparsh-page .top .label {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }

  .sparsh-page .mainTitleOuter .mainTitle {
    font-size: 18px;
    line-height: 27px;
  }

  .sparsh-page .mainTitleOuter .viewMore {
    height: auto;
    padding: 0;
    font-size: 13px;
    line-height: 19.5px;
  }

  .sparsh-page .mainTitleOuter {
    padding-top: 30px;
  }

  /* .mobHide {
    display: none;
  } */

  /* .sparsh-page .table-responsive .table .actions .more {
    transform: rotate(90deg);
  } */

  .sparsh-page .table-responsive .table .srNo .bhaktiImg {
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }

  .sparsh-page .table-responsive .table .titleSection .title {
    font-size: 15px;
    line-height: 22.5px;
    max-width: 150px;
  }

  .sparsh-page .table-responsive .table .actions .favTooltip {
    display: none;
  }

  .sparsh-page .table-responsive .table .titleSection .subTitle {
    font-size: 13px;
    line-height: 19.5px;
  }

  .sparsh-page .table-responsive .table td {
    padding: 10px;
  }

  .sparsh-page .table-responsive .table .titleSection {
    max-width: 150px;
  }

  .sparsh-page .albumOuter .album .albumBottom .name {
    font-size: 12px;
    line-height: 18px;
  }

  .sparsh-page .albumOuter .album .albumBottom .desc .time {
    font-size: 10px;
    line-height: 15px;
  }

  .sparsh-page .albumOuter .album .albumBottom .desc .dot {
    width: 4px;
    height: 4px;
  }

  .sparsh-page .albumOuter .album .albumBottom .desc .count {
    font-size: 10px;
    line-height: 15px;
  }

  .sparsh-page .albumOuter {
    gap: 7.5px;
  }

  .sparsh-page .albumOuter .album .albumBottom .desc .time {
    width: 60px;
  }

  .sparsh-page .list-of-play-search {
    display: block;
  }


  .popupOuterForCurrentPlaylist {
    display: block;
  }

  .popupOuterForCurrentPlaylist {
    position: relative;
    z-index: 1;
    display: block;
  }

  .sparsh-page .albumOuter .album .albumTop .albumHover .albumHoverInner {
    bottom: 0px;
    right: 5px;
    gap: 5px;
  }

}

@media (min-width: 1400px) {
  .sparsh-page .albumOuter .album .albumBottom .desc .time {
    /* min-width: 96px; */
  }
}
