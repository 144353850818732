/*----- New CSS Start -----*/
.ebook-heading-mt-40 {
  margin-top: 40px;
  display: block;
}

.ebook-heading-d-flex {
  display: flex;
  padding: 0 15px;
}

.ebook-heading-title {
  font-size: 35px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
}


/*----- Search Bar Desktop Start -----*/
.ebook-search-div {
  display: flex;
  justify-content: flex-end;
}

.ebook-search-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  width: 450px;
}

.ebook-search-input {
  position: relative;
  width: 100%;
}

.ebook-search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
  cursor: pointer;
}

.ebook-search-input input {
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  font-size: 15px;
  width: 100%;
}

.ebook-filter-icon {
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}
.ebook-filter-icon img { 
  cursor: pointer;
}
/*----- Search Bar Desktop End -----*/


.ebook-outer-wrap {
  padding: 5px 10px 50px;
  column-count: 3;
}

.ebook-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 0px;
}

.ebook-inside-wrap {
  display: block;
  width: 100%;
  background-color: #FFFCF5;
}

.ebook-inside-wrap .list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
  background: #000000ba;
}

.ebook-inside-wrap .img-banner {
  width: 100%;
  height: 270px;
  height: 170px;
  display: flex;
  height: 100%;
  aspect-ratio: 9/14;
  object-fit: unset;
}

.ebook-play-icon {
  position: absolute;
  bottom: -32px;
  right: 0px;
}

.ebook-card-content-item {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.ebook-video-content {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.ebook-video-content img {
  margin-right: 10px;
  cursor: pointer;
}

.ebook-video-content .ebook-ta-right {
  text-align: right;
}


.ebook-title-text {
  font-size: 17px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 40px;
}

.ebook-text-hrs {
  font-size: 15px;
  color: #8C8C8C;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

.ebook-textDetails {
  display: flex;
  align-items: center;
}

.ebook-textDetails .dot {
  padding: 0 5px;
}

.ebook-icon-read-div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ebook-read {
  font-size: 17px;
  color: #F6AE06;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  cursor: pointer;
}

/* Ebbok design change */
.ebook-outer-wrap.custom-row .custom-col-4 {
  width: 20%;
}


/* Ebbok design change */

/* List View CSS */
.listView.ebook-outer-wrap.custom-row {
  display: block;
  column-count: 1;
}
.listView.ebook-outer-wrap.custom-row .custom-col-4 {
  width: 100%;
}
.listView .ebook-inside-wrap {
  display: flex;
}
.listView .list-image {
  width: auto;
}
.listView .ebook-inside-wrap .img-banner {
  width: 100px;
  aspect-ratio: 16/9;
}
.listView .ebook-video-content {
  flex-direction: row;
}
.listView .ebook-video-content-inner {
  display: flex;
  flex-direction: column;
  /* max-width: 300px; */
  /* align-items: center; */
  justify-content: center;
  gap: 15px;
  height: 70px;
}
.listView .ebook-title-text {
  height: auto;
  margin: 0;
}
.listView .ebook-icon-read-div {
  margin: 0;
}
.listView .tooltiptext {
  display: none;
}



/* List View CSS */



@media screen and (max-width: 767px) {
  .ebook-heading-mt-40 {
    margin-top: 40px;
    display: none;
  }

  .ebook-outer-wrap {
    padding: 5px 15px 30px;
    column-count: 1;
  }

  .ebook-card-wrap {
    box-shadow: 1px 3px 15px 0px #00000026;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 0px;
    height: inherit;
  }

  .ebook-inside-wrap {
    display: flex;
    width: 100%;
    background-color: #FFFCF5;
  }

  .ebook-inside-wrap .list-image {
    max-width: 30%;
    width: 100%;
    position: relative;
    flex-shrink: 0;
  }

  .ebook-inside-wrap .img-banner {
    width: 100%;
    height: 100%;
    /* height: 120px; */
    display: flex;
    /* max-height: 120px; */
  }
  .ebook-heading-title {
    font-size: 20px;
  }
  .ebook-title-text {
    font-size: 15px;
    -webkit-line-clamp: 2;
  }
  .ebook-text-hrs {
    font-size: 13px;
  }
  .ebook-read {
    font-size: 15px;
  }
  .ebook-search-div-header-outer {
    margin-top: 50px;
  }
  .ebook-outer-wrap.custom-row .custom-col-4 {
    width: 100%;
  }



}


@media (min-width: 768px) {
  .ebook-search-div-header-outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 85px;
  }
  .ebook-search-div-header-outer .ebook-heading-mt-40 {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .ebook-heading-title {
    font-size: 33px;
  }
  .ebook-search-div-header-outer {
    display: block !important;
  }
  .ebook-search-wrap {
    width: 400px;
  }
  .ebook-title-text {
    -webkit-line-clamp: 2;
  }
  .ebook-outer-wrap.custom-row .custom-col-4 {
    width: 33.33%;
  }



}
/*----- New CSS End -----*/



@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .ebook-search-wrap {
    width: 300px;
  }
  .ebook-heading-title {
    font-size: 30px;
  }
  .ebook-outer-wrap.custom-row .custom-col-4 {
    width: 33.33%;
  }
}