/* The customRadio  */
.customRadio {
  font-size: 17px;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.g-6 {
  gap: 24px;
}

/* Hide the browser's default radio button */
.customRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.view-toggle {
  border: 2px solid #F6AE06;
  border-radius: 25%;
  display: flex;
  margin-left: 3px;
  cursor: pointer;
  box-shadow: 1px 2px 3px 0px #00000026; /* Adjust the shadow values as needed */
}

/* Create a custom radio button */
.customRadio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.customRadio:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.customRadio input:checked~.checkmark {
  background-color: #FFB200;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.customRadio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.customRadio input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.customRadio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #FFB200;
  border: 5px solid #fff;
}

/* Custom Radio */
.sortBy .MuiDialog-paperWidthSm {
  max-width: 500px;
  width: 100%;
}

.sortBy ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #fffcf5;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #FDF5E2;
  background-color: #ffd87c;
}

.search-wrap {
  max-width: 450px;
}

.orange-text {
  color: #F9C100;
}

/* button:hover {
  background-color: #FFB200;
} */
.css-134ktx4-MuiButtonBase-root-MuiTab-root.MuiTab-root.Mui-selected {
  color: #FFB200;
  font-weight: 600 !important;
}

.css-134ktx4-MuiButtonBase-root-MuiTab-root.MuiTab-root {
  font-size: 17px !important;
  color: #8C8C8C;
  font-weight: 400 !important;
  cursor: pointer !important;
}

.css-134ktx4-MuiButtonBase-root-MuiTab-root.MuiTab-root:hover {
  background: inherit;
}

.loader {
  width: 1100%;
  text-align: center;
}

.search-input input::-webkit-input-placeholder {
  color: #21212159;
}

.search-input-header input::-webkit-input-placeholder {
  color: #21212159;
}

.search-input input {
  font-size: 17px;
  font-weight: 400;
}

.search-input-header input {
  font-size: 17px;
  font-weight: 400;
}

.g-icon {
  margin-right: 5px;
  width: 25px;
  background-color: #efe6e6;
  border-radius: 100%;
  padding: 3px;
}

.u-icon {
  margin-right: 5px;
  width: 25px;
  background-color: #efe6e6;
  border-radius: 100%;
  padding: 3px;
}

.l-icon {
  margin-right: 5px;
  width: 25px;
  background-color: #efe6e6;
  border-radius: 100%;
  padding: 3px;
}

/* .google-button{
  background-color: #275CB0 !important;
  text-transform: none !important;
  color: white !important;
  box-shadow: none !important;
} */
.f-20 {
  font-size: 20px !important;
}

.f-30 {
  font-size: 30px !important;
}

.fw-600 {
  font-weight: 600;
}

body,
html {
  overflow-x: hidden;
}

button.button {
  cursor: pointer;
}

table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  font-size: larger;
}

/* .MuiBackdrop-root {
  background-color: #000000BF !important;
} */

tr {
  display: table-row;
  /* vertical-align: inherit; */
  /* border-color: inherit; */
  border-color: #dee2e6;
}

.hoverable-row:hover {
  background-color: #E8E8E8;
}

table th,
td {
  padding: 0.75rem;
  /* vertical-align: top; */
  border: 1px solid #dee2e6;
}

/* .table>tbody>tr:nth-of-type(odd) {
  background-color: #f1d5d5;
} */

.hand {
  cursor: pointer;
}

table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  text-indent: initial;
  border-spacing: 2px;
  padding: 50px 100px 50px 100px;
}

.container {
  max-width: 1680px;
  margin: 0 auto;
}

th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: left;
}


td {
  font-size: 20px;
  /* font-family: 'Montserrat', sans-serif; */
  color: #000;
  text-align: left;
}

.ytp-overflow-icon {
  margin: auto;
  width: 36px;
  height: 36px;
  vertical-align: middle;
  display: none;
}



.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* .video-index-media{
  top: 50%;
  left: 50%;
  width: 800px;
  padding: 5px;
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
} */

.react-confirm-alert-overlay {
  background: rgb(0 0 0 / 35%);
}

.loader-size {
  width: 80px;
  height: 80px;
}

.loader-size-search {
  width: 20px;
  height: 20px;
  margin-left: 15px;
}

.video-date-text {
  font-size: 15px;
  color: #606060;
  font-weight: 500;
}

.pt-15 {
  padding-top: 15px !important;
}

.termView {
  padding-left: 12px;
  padding-right: 12px;
}

.routes-w-80 {
  padding: 0px 30px;
  margin-left: 241px;
  float: right;
  /* padding-top: 65px; */
}
.general-textDetails {
  display: flex;
  align-items: center;
}
.general-text-hrs {
  font-size: 15px;
  color: #8C8C8C;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
}


.custom-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.custom-col-4 {
  width: 33.33%;
  margin-bottom: 15px;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
}


.custom-col-3 {
  width: 33.33%;
  margin-bottom: 15px;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
}

.modalFull .MuiDialog-container .MuiDialogContent-root>div {
  width: 320px !important;
  max-height: calc(100vh - 150px);
}

.modalFull .MuiDialog-container .MuiPaper-root.MuiDialog-paper {
  max-height: fit-content;
  max-width: 1000px;
}

.MuiDialog-container .MuiPaper-root.MuiDialog-paper {
  background: #FFFCF5;
  min-width: 350px;
}

.filterPopup .MuiPaper-root {
  max-width: 430px;
}

.share-popup .MuiPaper-root {
  max-width: 100px;

}

.share-popup .share-div .share-image-div{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.share-popup .share-div .close-sharemodal-button{
  font-size: 17px;
    width: 100px;
    /* height: 35px; */
    flex-shrink: 0;
    border-radius: 10px;
    border: 0px;
    border-color: transparent;
    background-color: transparent;
    color: #FFB200;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;
}
.share-popup .share-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

}

.toggleText {
  font-size: 15px;
  color: #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-block-start: inherit;
  margin-block-end: inherit;
  padding-bottom: 10px;
}

.css-1w5fbjm-MuiTabs-root .MuiTabs-indicator {
  margin-left: 5px;
}

.cursor {
  cursor: pointer;
}

.filter-icon img {
  cursor: pointer;
}

.search-icon img {
  cursor: pointer;
}

.filter-icon-header img {
  cursor: pointer;
}

.search-icon-header img {
  cursor: pointer;
}

.custom-input {
  font-size: 15px;
  color: #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-block-start: inherit;
  margin-block-end: inherit;
  border-radius: 10px;
  width: 100%;
  height: 35px;
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 20px;
}

.react-pdf__Document {
  position: absolute;
  height: 100vh;
  left: 32vw;
  right: 32vw;
  left: 29vw;
  right: 29vw;
}

.pdfReaderOuter {
  position: absolute;
  height: 100vh;
  left: 32vw;
  right: 32vw;
}


/* #rc-anchor-container {
  width: 99%;
}
.MuiDialog-root.sortBy .MuiDialogContent-root>div>div>div {
  width: 100% !important;
}
.MuiDialog-root.sortBy .MuiDialogContent-root>div>div>div>div iframe {
  width: 100%;
} */
.MuiDialog-root.sortBy.captcha .MuiPaper-root.MuiDialog-paper {
  width: 330px;
  background: #fff;
  min-width: auto;
}

.MuiDialog-root.sortBy.captcha .MuiPaper-root.MuiDialog-paper .MuiDialogContent-root {
  padding: 15px;
}

.searchIcon-outer .searchIcon {
  cursor: pointer;
}

.tableHover .bhaktipadPlayIconTooltip:hover .bhaktipadPlayIconTooltipInner .tooltiptext {
  left: 90px;
  top: -40px;
  visibility: visible;
}

.tooltipWrapper {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  /* Position the tooltip above the icon */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.tooltipWrapper:hover .tooltiptext {
  visibility: visible;
  top: auto;
  left: 130px;
}

.tableHover .moreTooltip:hover .moreTooltipInner .tooltiptext {
  right: 0;
  top: -40px;
  visibility: visible;
}


.albumList-page .albumOuter .album .albumTop .albumHover .albumInnerPlayIconOuter .bhaktipadPlayIconTooltip .albumInnerPlayIcon {
  margin-top: -20px;
}

.sparsh-page .albumOuter .album .albumTop .albumHover .albumInnerPlayIconOuter .bhaktipadPlayIconTooltip .albumInnerPlayIcon {
  margin-top: -20px;
}

.albumOuter .album .albumTop .albumHover .bhaktipadPlayIconTooltip:hover .bhaktipadPlayIconTooltipInner .tooltiptext {
  top: 50px;
  visibility: visible;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.sparsh-page .albumOuter .album .albumTop .albumHover .bhaktipadPlayIconTooltip:hover .bhaktipadPlayIconTooltipInner .tooltiptext {
  top: -70px;
}

/* .albumOuter .album .albumTop .albumHover .moreTooltip:hover .moreTooltipInner .tooltiptext {
  right: 0;
  top: -40px;
  visibility: visible;
} */

/* .bhaktipadPlayIconTooltip {
  position: relative;
} */

/* .bhaktipadPlayIconTooltip .trackPlayButton {
  left: 16px;
  top: -33px;
} */




@media only screen and (min-width: 768px) {
  .orange-text {
    width: 50%;
  }

  .footerStrip {
    display: none;
  }

  .routes-w-80 {
    width: calc(100% - 160px);
    margin-left: 100px;
  }
  /* .general-text-hrs {
    font-size: 13px;
  } */

  .modalFull .MuiDialog-container .MuiDialogContent-root>div {
    width: 800px !important;
    max-height: calc(100vh - 90px);
  }

}

@media only screen and (min-width: 992px) {
  .routes-w-80 {
    width: calc(100% - 300px);
    margin-left: 241px;
  }
}



@media screen and (max-width: 600px) {
  .loader-size {
    width: 50px;
    height: 50px;
  }

  .loader-size-search {
    width: 20px;
    height: 20px;
    margin-left: 15px;
  }

  #user .MuiGrid-spacing-xs-6>.MuiGrid-item {
    padding: 15px;
  }

  .mt-150 {
    margin-top: 150px !important;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  /* .video-index-media{
   
    width: 350px;
    padding: 5px;
 
  } */

}

@media (max-width: 991px) {
  .tableHover .moreTooltip:hover .moreTooltipInner .tooltiptext {
    visibility: hidden;
  }

  .tableHover .bhaktipadPlayIconTooltip:hover .bhaktipadPlayIconTooltipInner .tooltiptext {
    visibility: hidden;
  }

  .albumOuter .album .albumTop .albumHover .bhaktipadPlayIconTooltip:hover .bhaktipadPlayIconTooltipInner .tooltiptext {
    visibility: hidden;
  }

  .custom-col {
    width: 50%;
  }

  .pdfReaderOuter {
    left: 50px;
    right: 50px;
  }

  .react-pdf__Document {
    left: 50px;
    right: 50px;
  }
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  max-height: 300px !important;
  top: 180px !important;
  left: 0px !important;
  right: 0 !important;
  margin: 0 auto;
  width: 300px;
}



/* Tooltip css */
.tooltiptext {
  width: max-content;
  top: -7px;
  right: 105%;
  margin-left: -60px;
  visibility: hidden;
  background-color: #000;
  font-family: 'Montserrat';
  color: #fff;
  text-align: center;
  border-radius: 0px;
  padding: 8px 10px;
  font-size: 13px;
  position: absolute;
  z-index: 1;
  border-radius: 5px;
}

.iconsOuter {
  display: flex;
}

.favTooltip {
  position: relative;
  display: flex;
  align-items: center;
}

.favTooltip:hover .tooltiptext {
  visibility: visible;
  left: 20px;
  top: -40px;
}

.timerTooltip {
  position: relative;
}

.timerTooltip:hover .tooltiptext {
  visibility: visible;
  left: 55px;
  top: -40px;
}

.copyTooltip {
  position: relative;
}

.copyTooltip:hover .tooltiptext {
  visibility: visible;
  left: 55px;
  top: -40px;
}

.backTooltip {
  position: relative;
}

.backTooltip:hover .tooltiptext {
  visibility: visible;
  left: 55px;
  top: -40px;
}

.deleteTooltip {
  position: relative;
}

.deleteTooltip:hover .tooltiptext {
  visibility: visible;
  left: 55px;
  top: -40px;
}

.playIconTooltip {
  position: relative;
}

.playIconTooltip:hover .tooltiptext {
  visibility: visible;
  right: 5px;
  top: -65px;
}

.badgestarInvisible {
  position: relative;
}

.badgestarInvisible:hover .tooltiptext {
  visibility: visible;
  left: 0;
  top: -30px;
}

.liveTooltip {
  position: relative;
}

.liveTooltip:hover .tooltiptext {
  visibility: visible;
  left: 70px;
  top: -35px;
}

.searchTooltip {
  position: relative;
}

.searchTooltip:hover .tooltiptext {
  visibility: visible;
  left: -25px;
  top: -5px;
}

.sortbyTooltip {
  position: relative;
}

.sortbyTooltip:hover .tooltiptext {
  visibility: visible;
  right: -20px;
  top: 25px;
}

.filterTooltip {
  position: relative;
}

.filterTooltip:hover .tooltiptext {
  visibility: visible;
  right: -60px;
  top: -5px;
}

.notificationTooltip {
  position: relative;
}

.notificationTooltip:hover .tooltiptext {
  visibility: visible;
  left: -40px;
  top: 0px;
}

.userTooltip {
  position: relative;
}

.userTooltip:hover .tooltiptext {
  visibility: visible;
  left: 70px;
  top: 40px;
}

.titleTooltip {
  position: relative;
}

.titleTooltip:hover .tooltiptext {
  visibility: visible;
  left: 50px;
  top: -40px;
}

.downaloadTooltip {
  position: relative;
}

.downaloadTooltip:hover .tooltiptext {
  visibility: visible;
  left: 50px;
  top: -40px;
}

.readTooltip {
  position: relative;
}

.readTooltip:hover .tooltiptext {
  visibility: visible;
  right: 0px;
  top: -40px;
}

.addVideoTooltip {
  position: relative;
}

.addVideoTooltip:hover .tooltiptext {
  visibility: visible;
  right: 0px;
  top: -40px;
}


.list-of-like-icon-div .timerTooltip:hover .tooltiptext {
  left: auto;
  right: 0;
}

.home-top .allPlaylist .list-of-like-icon-div .timerTooltip:hover .tooltiptext {
  right: auto;
  left: 60px;
}

.list-of-like-icon-div .favTooltip:hover .tooltiptext {
  left: auto;
  right: 0;
}

.home-top .allPlaylist .list-of-like-icon-div .favTooltip:hover .tooltiptext {
  right: auto;
  left: 60px;
}

.my-space .myCreatedPlaylist .deleteTooltip:hover .tooltiptext {
  left: auto;
  right: 0;
}

.my-created-playlist-like-icon-div .deleteTooltip:hover .tooltiptext {
  left: auto;
  right: 0;
}

.customDate {
  position: relative;
}

.customDate .MuiInputAdornment-positionEnd {
  position: absolute;
  right: 10px;
}
.musicIcons{
  width: 25px;
  height: 25px;
  /* object-fit: contain; */
}

.customDate input {
  background: #fffcf5;
  box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.15) !important;
  border: none !important;
  border-radius: 10px;
  padding: 14px 10px;

}
.customIndianDate {
  position: relative;
}
.customIndianDate .MuiInputAdornment-positionEnd {
  position: absolute;
  right: 10px;

}
.customIndianDate .rmdp-container {
   display: inline-block;
    height: max-content;
    background: #fffcf5;
    box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.15) !important;
    width: 100%;
}
.customIndianDate .rmdp-input {
  background: #fffcf5;

  border: none !important;
  border-radius: 10px;
  padding: 14px 10px;
  
  /* width: -webkit-fill-available; */
  font-size: medium;
  
}

.table-responsive .table tr {
  position: relative;
}
.table-responsive .table tr td {
  position: relative;
}

.image-resolution {
  object-fit: unset !important;
}

/* .PrivateTabIndicator-colorSecondary-13 {
  background-color: #FFB200;
} */
.MuiTabs-indicator {
  background-color: #FFB200 !important;
}


.createPlaylistModal.save-video-info .language select {
  width: 100%;
  height: 46px;
  border-radius: 10px;
  box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.15);
  padding: 10px;
  box-sizing: border-box;
  font-size: 15px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("assets/Phase2/arrowDownOrange.svg") 95% / 15px no-repeat;
}

@media (max-width: 991px) {
  .table-responsive .mobHide .none {
    display: none;
  }
}

@media (max-width: 767px) {
  .badgestarInvisible:hover .tooltiptext {
    left: 130px;
    top: -50px;
  }

  .table-responsive .table thead.mobHide {
    display: none;
  }

  .table-responsive .mobHide .srNo .no.mobHide {
    display: none;
  }

  .home-top .allPlaylist .list-of-like-icon-div .timerTooltip:hover .tooltiptext {
    right: -30px;
    left: auto;
  }

  .home-top .allPlaylist .list-of-like-icon-div .favTooltip:hover .tooltiptext {
    right: 0px;
    left: auto;
  }
  .view-toggle{
    display: none;
  }
}

/* Tooltip css */

.align-center {
  align-items: center;
}

.react-pdf__Document .react-pdf__Page canvas.react-pdf__Page__canvas {
  margin: 0 auto;
}

.login-modal-popup .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  background: transparent;
  box-shadow: none;
}
.login-modal-popup .card {
  background: #fff;
}

.trackPlayButton {
  display: none;
}

.tableHover:hover .trackPlayButton {
  display: inline-block;
  /* position: absolute;
  left: 46px;
  top: -2px; */
}

.trackPlayButton {
  position: absolute;
  left: 46px;
  top: -2px;
}

.ytPlayerOpen {
  padding-bottom: 100px !important;
}

@media (max-width: 991px) {
  .ytPlayerOpen {
    padding-bottom: 150px !important;
  }

  .ytPlayerOpen.home {
    padding-bottom: 100px !important;
  }
}

@media (max-width:767px) {
  .MuiDialog-container .MuiPaper-root.MuiDialog-paper {
    max-width: 300px;
    min-width: 300px;
  }

  .ytPlayerOpen.home {
    padding-bottom: 130px !important;
  }

  .ytPlayerOpen {
    padding-bottom: 170px !important;
  }

  .trackPlayButton {
    position: absolute;
    width: 60px;
    left: -4px;
    top: -2px;
  }

  .react-pdf__Document .react-pdf__Page .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
  .custom-col {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    box-sizing: border-box;
    /* margin-bottom: 0; */
  }

  .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    max-height: 390px !important;
    top: 250px !important;
    left: 20px !important;
  }

  .toggleText {
    font-size: 13px;
  }

  .my-favorites .watch-later.my-space-mt-40 {
    margin-top: 0;
  }

  .PrivacyPolicy {
    display: none;
  }

  .termView {
    padding-left: 14px;
    padding-right: 14px;
  }

  .routes-w-80 {
    padding: 0px;
    width: 100%;
    margin-left: 0;
    /* padding-top: 60px; */
  }

  .all-search-wrap-outer {
    width: 100%;
    padding: 0 5px;
  }

  .all-search-wrap-outer>div {
    width: 100%;
  }

  .css-134ktx4-MuiButtonBase-root-MuiTab-root.MuiTab-root {
    font-size: 15px !important;
  }

  .customRadio {
    font-size: 15px;
  }

  .search-input input {
    font-size: 15px;
  }

  .react-pdf__Document {
    left: 0px;
    right: 0px;
  }

  .pdfReaderOuter {
    left: 0;
    right: 0;
  }





}

@media (min-width: 768px) and (max-width: 991px) {
  .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    max-height: 420px !important;
    top: 300px !important;
  }
}

@media (min-width: 1400px) {
  .customRadio .checkmark {
    left: 1px;
    height: 25px;
    width: 25px;
  }
}

@media (min-width: 768px) {
  .filterPopup .MuiPaper-root {
    width: 430px;
  }
  .share-popup .MuiPaper-root {
    width: 430px;
  }
}