.subscriptionPlanList {
  padding-bottom: 30px;
}

.subscriptionPlanList .SubscriptionCard {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  margin: 25px 25px;
  padding: 15px;
  background: #FFFCF5;
}

.subscriptionPlanList .SubscriptionCard .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DBC48E;
}

.subscriptionPlanList .SubscriptionCard .header .title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  margin: 0;
  color: #212121;
}

.subscriptionPlanList .SubscriptionCard .header .status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.subscriptionPlanList .SubscriptionCard .header .status .active {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #179C4D;
  margin-right: 5px;
}

.subscriptionPlanList .SubscriptionCard .header .status .text {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  margin: 0;
  color: #212121;
}

.subscriptionPlanList .SubscriptionCard .header .status .text.activeText {
  text-transform: uppercase;
}

.subscriptionPlanList .SubscriptionCard .desc {
  font-size: 30px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: #212121;
}

.subscriptionPlanList .SubscriptionCard .desc .amount {
  font-family: Poppins;
  font-size: 30px;
}

.subscriptionPlanList .SubscriptionCard .desc .subText {
  font-size: 15px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #8C8C8C;
}

.subscriptionPlanList .SubscriptionCard .benefits ul li {
  font-size: 17px;
  list-style-image: url('../../../assets/Phase2/beneIcon.svg');
  padding-inline-start: 1ch;
  padding-bottom: 15px;
  font-family: Poppins;
}

.subscriptionPlanList .SubscriptionCard .buySubscriptionOuter {
  display: flex;
  text-align: center;
  justify-content: center;
}

.subscriptionPlanList .SubscriptionCard .buySubscriptionOuter .btn.buySubscription {
  border: none;
  background: linear-gradient(97.47deg, #FFB200 0%, #EEAA0C 100%);
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 10px;
}

.subscriptionPlanList .SubscriptionCard .subscription-not-available{
  border: none;
  /* background: linear-gradient(97.47deg, #FFB200 0%, #EEAA0C 100%); */
  background: #e8e8e8;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 10px;
}

.subscriptionPlanList .historyTitle {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 30px;
  /* margin-bottom: -20px; */
}

.subscriptionPlanList .SubscriptionCard .history {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.subscriptionPlanList .SubscriptionCard .history .left {
  width: 50%;
}

.subscriptionPlanList .SubscriptionCard .history .right {
  width: 50%;
}

.subscriptionPlanList .SubscriptionCard .history .title {
  font-size: 15px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 0;
}

.subscriptionPlanList .SubscriptionCard .history .subTitle {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  margin: 0;
}

.subscriptionPlanList .mainTitle {
  font-size: 35px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: left;
  display: none;
  margin-bottom: 0;
}

.subscriptionPlanList .subscriptionPlanListInner .first {
  box-shadow: none;
  border: none;
  border-radius: 0;
  padding: 0px;
  box-sizing: border-box;
}

.subscriptionPlanList .subscriptionPlanListInner .first .plansTitle {
  font-size: 25px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
  padding: 0 25px;
  margin-bottom: -20px;
  display: none;
  margin-top: 0;
}

.subscriptionPlanList .SubscriptionCard .benefits .title {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5px;
  color: #8C8C8C;
}

.subscriptionPlanList .SubscriptionCard .benefits ul {
  padding-inline-start: 30px;
}


@media (min-width: 991px) {
  .subscriptionPlanList .subscriptionPlanListInner .first {
    box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
    border: 1px solid #EEAA0C;
    border-radius: 10px;
    padding: 30px 20px;
    width: 60%;
  }

  .subscriptionPlanList .subscriptionPlanListInner {
    display: flex;
    justify-content: space-between;
    padding: 30px 0px;
  }

  .subscriptionPlanList .subscriptionPlanListInner .second {
    width: 40%;
  }
}

@media (min-width: 768px) {
  .subscriptionPlanList .mainTitle {
    display: block;
  }

  .subscriptionPlanList .SubscriptionCard .benefits .title {
    font-size: 17px;
  }

  .subscriptionPlanList .subscriptionPlanListInner .second .SubscriptionCard {
    margin-right: 0;
  }

  .subscriptionPlanList .subscriptionPlanListInner .first .plansTitle {
    display: block;
  }

  .subscriptionPlanList .subscriptionPlanListInner .second .historyTitle {
    margin-top: 0;
    padding-left: 25px;
    font-size: 25px;
  }

  .subscriptionPlanList .SubscriptionCard .header .title {
    font-size: 21px;
  }

  .subscriptionPlanList .SubscriptionCard .header .status .text {
    font-size: 17px;
  }

  .subscriptionPlanList .SubscriptionCard .buySubscriptionOuter .btn.buySubscription {
    font-size: 17px;
  }

  .subscriptionPlanList {
    padding: 0 15px;
    padding-top: 65px;
  }
}

@media (max-width: 991px) {
  .subscriptionPlanList .subscriptionPlanListInner .first .plansTitle {
    padding: 0;
  }

  .subscriptionPlanList .SubscriptionCard {
    margin: 30px 5px;
  }

}


@media (max-width: 767px) {
  .subscriptionPlanList {
    padding: 0 15px 30px;
    padding-top: 50px;
  }
  .subscriptionPlanList .SubscriptionCard .history .title {
    font-size: 13px;
  }
  .subscriptionPlanList .SubscriptionCard .history .subTitle {
    font-size: 15px;
  }
  .subscriptionPlanList .historyTitle {
    color: #212121;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 8px;
    margin-bottom: -20px;
  }
  .subscriptionPlanList .SubscriptionCard .desc .subText {
    font-size: 13px;
  }
  .subscriptionPlanList .SubscriptionCard .benefits ul li {
    font-size: 15px;
  }
  .subscriptionPlanList .SubscriptionCard {
    margin: 30px 0px;
  }


}


@media screen and (min-width: 768px) and (max-width: 991px) {
  .subscriptionPlanList .mainTitle {
    font-size: 33px;
  }
  .subscriptionPlanList .subscriptionPlanListInner .first .plansTitle {
    font-size: 23px;
  }
  
  .subscriptionPlanList .subscriptionPlanListInner .first {
    box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
    border: 1px solid #EEAA0C;
    border-radius: 10px;
    padding: 30px 20px;
    width: 100%;
  }
  .subscriptionPlanList .subscriptionPlanListInner .second .historyTitle {
    margin-top: 30px;
    padding-left: 0px;
    font-size: 23px;  
    margin-bottom: -15px;
  }
  .subscriptionPlanList .subscriptionPlanListInner {
    padding: 30px 0px;
  }


}