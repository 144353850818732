.activeSubscriptionPlanList {
  padding-bottom: 30px;
  padding-top: 45px;
}

.activeSubscriptionPlanList .SubscriptionCard {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  margin: 30px 15px;
  padding: 15px;
  background: #FFFCF5;
}

.activeSubscriptionPlanList .SubscriptionCard .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DBC48E;
}

.activeSubscriptionPlanList .SubscriptionCard .header .title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  margin: 0;
  color: #212121;
}

.activeSubscriptionPlanList .SubscriptionCard .header .statusOuter {
  padding-bottom: 10px;
}

.activeSubscriptionPlanList .SubscriptionCard .header .status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.activeSubscriptionPlanList .SubscriptionCard .header .status .active {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #179C4D;
  margin-right: 5px;
}

.activeSubscriptionPlanList .SubscriptionCard .header .status .text {
  font-size: 15px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  margin: 0;
  color: #212121;
}

.activeSubscriptionPlanList .SubscriptionCard .header .status .text.activeText {
  text-transform: uppercase;
}

.activeSubscriptionPlanList .SubscriptionCard .desc {
  font-size: 30px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: #212121;
}

.activeSubscriptionPlanList .SubscriptionCard .desc .amount {
  font-family: Poppins;
  font-size: 30px;
}

.activeSubscriptionPlanList .SubscriptionCard .desc .subText {
  font-size: 13px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #8C8C8C;
}

.activeSubscriptionPlanList .SubscriptionCard .benefits ul li {
  font-size: 15px;
  list-style-image: url('../../../assets/Phase2/beneIcon.svg');
  padding-inline-start: 1ch;
  padding-bottom: 15px;
}

.activeSubscriptionPlanList .SubscriptionCard .buySubscriptionOuter {
  text-align: center;
  margin-left: 30%;
}

.activeSubscriptionPlanList .SubscriptionCard .buySubscriptionOuter .btn.buySubscription {
  border: none;
  background: linear-gradient(97.47deg, #FFB200 0%, #EEAA0C 100%);
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
}

.activeSubscriptionPlanList .historyTitle {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 30px;
  margin-bottom: -20px;
}

.activeSubscriptionPlanList .SubscriptionCard .history {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.activeSubscriptionPlanList .SubscriptionCard .history .left {
  width: 50%;
}

.activeSubscriptionPlanList .SubscriptionCard .history .right {
  width: 50%;
}

.activeSubscriptionPlanList .SubscriptionCard .history .title {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 0;
}

.activeSubscriptionPlanList .SubscriptionCard .history .subTitle {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.activeSubscriptionPlanList .mainTitle {
  font-size: 35px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  display: none;
  margin-bottom: 0;
}

.activeSubscriptionPlanList .subscriptionPlanListInner .first {
  box-shadow: none;
  border: none;
  border-radius: 0;
  padding: 0px;
}

.activeSubscriptionPlanList .subscriptionPlanListInner .first .plansTitle {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
  padding: 0 10px;
  margin-bottom: -20px;
  display: none;
  margin-top: 0;
}

.activeSubscriptionPlanList .SubscriptionCard .benefits .title {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5px;
  color: #8C8C8C;
}

.activeSubscriptionPlanList .SubscriptionCard .benefits ul {
  padding-inline-start: 30px;
}



@media (min-width: 991px) {
  .activeSubscriptionPlanList .mainTitle {
    display: block;
  }

  .activeSubscriptionPlanList .subscriptionPlanListInner .first {
    box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
    border: 1px solid #EEAA0C;
    border-radius: 10px;
    padding: 30px 20px;
    width: 60%;
    height: fit-content;
  }

  .activeSubscriptionPlanList .subscriptionPlanListInner {
    display: flex;
    justify-content: space-between;
    padding: 30px 0px;
  }

  .activeSubscriptionPlanList .subscriptionPlanListInner .second {
    width: 40%;
    padding-left: 10px;
  }

  .activeSubscriptionPlanList .subscriptionPlanListInner .second .SubscriptionCard {
    margin-right: 0;
  }

  .activeSubscriptionPlanList .subscriptionPlanListInner .second .historyTitle {
    margin-top: 0;
    padding-left: 15px;
    font-size: 25px;
  }

  .activeSubscriptionPlanList .SubscriptionCard .buySubscriptionOuter .btn.buySubscription {
    font-size: 17px;
  }

  .activeSubscriptionPlanList {
    padding: 0 15px;
    padding-top: 65px;
  }



}


@media (min-width: 768px) {
  .activeSubscriptionPlanList .subscriptionPlanListInner .first .plansTitle {
    display: block;
  }

  .activeSubscriptionPlanList .SubscriptionCard .header .title {
    font-size: 21px;
  }

  .activeSubscriptionPlanList .SubscriptionCard .header .status .text {
    font-size: 15px;
  }

  .activeSubscriptionPlanList .SubscriptionCard .desc .subText {
    font-size: 15px;
  }

  .activeSubscriptionPlanList .SubscriptionCard .benefits .title {
    font-size: 17px;
  }

  .activeSubscriptionPlanList .SubscriptionCard .benefits ul li {
    font-size: 17px;
  }

  .activeSubscriptionPlanList .SubscriptionCard .history .title {
    font-size: 15px;
  }

  .activeSubscriptionPlanList .SubscriptionCard .history .subTitle {
    font-size: 17px;
  }



}


@media (max-width: 767px) {
  .activeSubscriptionPlanList .SubscriptionCard {
    margin: 30px 10px;
  }



}



@media screen and (min-width: 768px) and (max-width: 991px) {
  .activeSubscriptionPlanList .mainTitle {
    font-size: 33px;
  }

  .activeSubscriptionPlanList .subscriptionPlanListInner {
    padding: 30px 15px;
  }

  .activeSubscriptionPlanList .subscriptionPlanListInner .first {
    box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
    border: 1px solid #EEAA0C;
    border-radius: 10px;
    padding: 30px 20px;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    box-sizing: border-box;
  }

  .activeSubscriptionPlanList .historyTitle {
    font-size: 23px;
  }

  .activeSubscriptionPlanList .subscriptionPlanListInner .second .historyTitle {
    padding: 0;
    padding-top: 30px;
  }

  .activeSubscriptionPlanList .subscriptionPlanListInner .second .SubscriptionCard {
    margin: 0px 0px;
    flex: 1 0 45%;
    box-sizing: border-box;
  }

  .activeSubscriptionPlanList .subscriptionPlanListInner .second .SubscriptionCardOuter {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 30px;
  }




}


.SubscriptionCardOuter .left .playIconTooltip:hover .tooltiptext {
  top: 30px;
  right: 20px;
}