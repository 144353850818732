.MyCreatePlayListSelectVideo {
  padding-top: 85px;
}
.MyCreatePlayListSelectVideo .my-created-playlist-card-wrap {
  height: auto;
}
.MyCreatePlayListSelectVideo .my-created-playlist-inside-wrap .img-banner {
  height: 100%;
  height: 270px;
  height: 170px;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
}

.MyCreatePlayListSelectVideo .my-created-playlist-title-search-div .my-created-playlist-search-wrap-outer .my-created-playlist-search-wrap .search-input .notification-icon {
  display: none;
}
.my-created-playlist-like-icon-div-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MyCreatePlayListSelectVideo .ebook-title-text {
  padding-right: 40px;
}
.MyCreatePlayListSelectVideo .my-created-playlist-col-3.custom-row {
  padding: 0 10px;
  padding-top: 10px;
}

.MyCreatePlayListSelectVideo .my-created-playlist-video-content img {
  margin-left: 0;
}
.MyCreatePlayListSelectVideo .my-created-playlist-video-content .my-created-playlist-like-icon-div img {
  margin-left: 0;
  margin-right: 10px;
}



@media (max-width: 767px) {
  .MyCreatePlayListSelectVideo .my-created-playlist-col-3.custom-row .custom-col-4.custom-col {
    margin-bottom: 0;
  }
  .MyCreatePlayListSelectVideo .my-created-playlist-title-search-div {
    margin-top: 0;
    padding-left: 0;
    border-bottom: 1px solid #DBC48E;
  }
  .MyCreatePlayListSelectVideo .my-created-playlist-title-search-div .my-created-playlist-search-wrap-outer {
    width: 100%;
  }
  .MyCreatePlayListSelectVideo .my-created-playlist-title-search-div .my-created-playlist-search-wrap-outer .my-created-playlist-search-wrap {
    width: 100%;
    margin-bottom: 0;
  }
  .MyCreatePlayListSelectVideo .my-created-playlist-title-search-div .my-created-playlist-search-wrap-outer .my-created-playlist-search-wrap .search-input input {
    box-shadow: none;
    padding-right: 30px;
  }
  .MyCreatePlayListSelectVideo .my-created-playlist-title-search-div .my-created-playlist-search-wrap-outer .my-created-playlist-search-wrap .search-input .notification-icon {
    position: absolute;
    top: 7px;
    left: 15px;
    display: block;
  }

  .MyCreatePlayListSelectVideo .my-created-playlist-inside-wrap {
    display: block;
  }
  .MyCreatePlayListSelectVideo .my-created-playlist-inside-wrap .list-image {
    max-width: 100%;
  }
  .MyCreatePlayListSelectVideo .my-created-playlist-inside-wrap .my-created-playlist-play-icon {
    position: absolute;
    bottom: -30px;
    right: 0px;
  }
  .MyCreatePlayListSelectVideo .my-created-playlist-col-3.custom-row {
    padding-left: 5px;
    padding-right: 5px;
  }
  .MyCreatePlayListSelectVideo .my-created-playlist-col-3.custom-row {
    padding-top: 30px;
  }
  .MyCreatePlayListSelectVideo .my-created-playlist-title-search-div .my-created-playlist-search-wrap-outer .my-created-playlist-search-wrap .search-icon {
    top: 13px;
    position: absolute;
    left: auto;
    right: 0px;
  }
  .MyCreatePlayListSelectVideo {
    padding-top: 0px;
  }
  .MyCreatePlayListSelectVideo .my-created-playlist-inside-wrap .img-banner {
    /* height: 150px; */
  }





}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .MyCreatePlayListSelectVideo .my-created-playlist-search-wrap {
    width: auto;
  }
  

}