.main-banner{
  position: relative;
/* box-shadow: 1px 3px 15px 0px #00000026; */
}
.individualUserPlaylist {
  padding-top: 105px;
}

.main-banner::after{
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0px;
  display: block;
  background: #0000009e;
  content: '';
  border-radius: 27px;
}
.img-banner{
  width: 100%;
  height: 320px;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
  /* border-radius: 27px; */
}
.individualUserPlaylist .infinite-scroll-component
.pl-15{
 padding-left: 15px;
}
.top-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 15px;
  z-index: 1;
}
.individualUserPlaylist .infinite-scroll-component .main-banner .img-banner {
  border-radius: 27px;
  height: auto;
  aspect-ratio: 16/9;
}
.banner-content{
  position: absolute;
  bottom: 40px;
  right: 0;
  left: 0;
  padding: 15px;
  z-index: 1;
}
.banner-title{
  font-size: 22px;
  text-align: left;
  color: white;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.truncate {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  line-height: 19.5px;
  color: #B1AFAB;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
}
.truncate + a {
display: inline-block;
  vertical-align: top;
  margin-top: -1px;
  margin-bottom: 0;
  color: #F6AE06;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  width: 95px;
}
.banner-text{
  display: flex;
  align-items: end;
}
.get-referal{
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
  padding: 15px;
  z-index: 1;
}
.yellow-text{
  margin-bottom: 0;
  color: #F6AE06;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none
}
.get-referal{
  color: white;
  font-size: 15px;
}
.play-icon-img{
  position: absolute;
  bottom: -25px;
  right: 5px;
  z-index: 1;
  cursor: pointer;
}

.text{
   display: inline;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   line-height: 24px;     /* fallback */
   max-height: 48px;      /* fallback */
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}
.text > div {
    display: inline;
    padding-right: 10px;
}

.text a {
    position: absolute;
}
.play-outer-wrap{
  padding: 10px;
}
.play-title-wrapper{
  display: flex;
  justify-content: space-between;
}
.play-title-wrapper p{
 margin-bottom: 10px;
 font-weight: 400;
}
.play-title-wrapper p.lef{
color:#212121;
margin-bottom: 0;
font-weight: 400;

}
.play-title-wrapper p.right{
  margin-bottom: 0;
  color: #F6AE06;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
}

.play-card-wrap{
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
}
.play-inside-wrap{
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #FFFCF5;

}
.play-inside-wrap .play-list-image{
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  background: #000000ba;
}
.play-inside-wrap .img-banner{
  width: 100%;
  height: 100%;
  height: 170px;
  display: flex;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
}
.play-play-icon{
  position: absolute;
  bottom: -29px;
  right: 0px;
  cursor: pointer;
}
.play-card-content-item{
  padding: 15px 10px 0px;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}
.play-card-content-item .userPlaylistOptions{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userPlaylistOptions .playlistRemoveContentBtn p{
  color: #F6AE06;
  font-weight: 600;
  cursor: pointer;
}
.play-card-list {
  display: flex;
  flex-wrap: wrap;
} 
.play-video-content{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
} 
.play-video-content img{
  margin-right: 13px;
  cursor: pointer;
}
.mt-10{
  margin-top: 10px;
}
.play-title-text{
  font-size: 15px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}
.play-text-hrs{
  color: #8C8C8C;
  font-size: 13px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;

}
.search-wrap{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
  width: 100%;
}
.searchIcon-outer {
  display: none;
}

.search-input{
  position: relative;
  width: 100%;
}
.search-icon{
  position: absolute;
  top: 13px;
  left: 15px;
}
.search-input input{
  font-size: 17px;
  height: 46px;  
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;   
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  width: 100%;
}
.filter-icon{
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}

.individualHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.individualHeader .search-wrap {
  width: 480px;
}

.header-create-btn {
  color: #F6AE06;
  text-align: right;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-left: 20px;
  cursor: pointer;
}

.individualUserPlaylist .infinite-scroll-component {
  padding: 0 10px;
}
.individualUserPlaylist .infinite-scroll-component .main-banner {
  margin: 0 5px;
}
.individualUserPlaylist .infinite-scroll-component .play-outer-wrap {
  padding: 10px 0px;
}
/* .individualUserPlaylist .play-outer-wrap {
  padding: 10px 0px;
} */
.top-header .backArrow {
  cursor: pointer;
}




@media (max-width: 767px) {
  .search-input input {
    font-size: 15px;
  }
  .play-outer-wrap .custom-col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .individualUserPlaylist .individualHeader {
    padding: 0 5px;
  }
  .individualUserPlaylist {
    padding-top: 0px;
  }
  .play-inside-wrap .img-banner{
    /* height: 150px; */
  }
  .main-banner::after{
    border-radius: 0px;
  }
  .img-banner{
    border-radius: 0px;
  }
  .individualUserPlaylist .infinite-scroll-component {
    padding: 0px;
  }
  .individualUserPlaylist .infinite-scroll-component .main-banner {
    margin: 0px;
  }
  .individualUserPlaylist .infinite-scroll-component .play-outer-wrap {
    padding: 10px 15px;
  }
  .individualUserPlaylist .infinite-scroll-component .main-banner .img-banner {
    height: auto;
    border-radius: 0;
  }
  .individualUserPlaylist .infinite-scroll-component .main-banner::after {
    bottom: 0px;
  }
  .ind-list-play-icon-img {
    bottom: -35px;
  }
  
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  /* .search-input {
    display: none;
    width: 0;
  } */
  /* .searchIcon-outer {
    display: block;
  } */
  .search-wrap {
    justify-content: flex-end;
  }
  .individualHeader .search-wrap {
    width: auto;
  }



}