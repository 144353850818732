.videoPlan {
  padding-bottom: 30px;
  padding: 0 15px;
  padding-top: 65px;
}

.videoPlan .SubscriptionCard {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  /* margin: 30px 15px; */
  padding: 15px;
  background: #FFFCF5;
}
.table-responsive {
  overflow: auto;
}
.videoPlan .SubscriptionCard .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DBC48E;
}

.videoPlan .SubscriptionCard .header .title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  margin: 0;
  color: #212121;
 
}
.title .mobile-play-now-title{
  font-weight: 600;
  color: #FFB200;
  

}
.mobile-play-now{
  margin-left: 8px;
  align-self: self-end;
}

.videoPlan .SubscriptionCard .header .status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.videoPlan .SubscriptionCard .header .status .active {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #179C4D;
  margin-right: 5px;
}

.videoPlan .SubscriptionCard .header .status .text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  margin: 0;
  color: #212121;
}

.videoPlan .SubscriptionCard .header .status .text.activeText {
  text-transform: uppercase;
}

.videoPlan .SubscriptionCard .desc {
  font-size: 30px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: #212121;
}

.videoPlan .SubscriptionCard .desc .amount {
  font-family: Poppins;
}

.videoPlan .SubscriptionCard .desc .subText {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #8C8C8C;
}

.videoPlan .SubscriptionCard .benefits ul li {
  list-style-image: url('../../../assets/Phase2/beneIcon.svg');
  padding-inline-start: 1ch;
  padding-bottom: 15px;
}

.videoPlan .SubscriptionCard .buySubscriptionOuter {
  text-align: center;
}

.videoPlan .SubscriptionCard .buySubscriptionOuter .btn.buySubscription {
  border: none;
  background: linear-gradient(97.47deg, #FFB200 0%, #EEAA0C 100%);
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 10px;
}

.videoPlan .historyTitle {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 30px;
  margin-bottom: -20px;
}

.videoPlan .SubscriptionCard .history {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.videoPlan .SubscriptionCard .history .left {
  width: 50%;
}

.videoPlan .SubscriptionCard .history .right {
  width: 50%;
}

.videoPlan .SubscriptionCard .history .title {
  font-size: 15px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 0;
  width: fit-content;
  height: fit-content;
}

.videoPlan .SubscriptionCard .history .subTitle {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  margin: 0;
}

.videoPlan .mainTitle {
  font-family: Poppins;
  font-size: 35px;
  font-weight: 400;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  display: none;
  margin: 0;
}

.videoPlan .subscriptionPlanListInner .first {
  box-shadow: none;
  border: none;
  border-radius: 0;
  padding: 0px;
}

.videoPlan .subscriptionPlanListInner .first .plansTitle {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
  padding: 0 10px;
  margin-bottom: -20px;
  display: none;
  margin-top: 0;
}

.videoPlan .SubscriptionCard .benefits .title {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5px;
  color: #8C8C8C;
}

.videoPlan .SubscriptionCard .benefits ul {
  padding-inline-start: 30px;
}

/*----- Search Bar Start CSS -----*/
.video-plan-search-box-mobile {
  display: contents;
}

.video-plan-search-wrap {
  display: flex;
  justify-content: center;
  width: 335px;
  /* margin-left: auto;
  margin-right: auto; */
}

.search-input {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.search-input input {
  font-size: 17px;
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  width: 100%;
}

.filter-icon {
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}
.subscriptionPlanListInner {
  padding: 30px 5px;
}
/*----- Search Bar End CSS -----*/
.videoPlan .qu-history-title-search-div {
  /* padding: 0 15px; */
}

/* table CSS */
.videoPlan .table-response-videoIndex .table {
  box-shadow: 1px 3px 15px 0px #00000026;
}
.videoPlan .table thead {
  background: #FFFAF0;
}
.videoPlan .table thead tr th {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  vertical-align: middle;
  border: 1px solid #DBC48E;
}
.videoPlan .table tbody tr td {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  color: #212121;
  vertical-align: top;
  border: 1px solid #DBC48E;
}
.videoPlan .table thead tr th .tableHead {
  display: flex;
  width: max-content;
}
.videoPlan .table tbody tr td .tableData.playNow {
  color: #FFB200;
  font-size: 15px;
  font-weight: 600;
}

/* table CSS */





@media (min-width: 768px) {
  .videoPlan .mainTitle {
    display: block;
    padding-bottom: 15px;
  }

  .videoPlan .SubscriptionCard .benefits .title {
    font-size: 17px;

  }

  .videoPlan .subscriptionPlanListInner .first {
    box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
    border: 1px solid #EEAA0C;
    border-radius: 10px;
    padding: 30px 20px;
    width: 65%;
  }

  .videoPlan .subscriptionPlanListInner {
    /* box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
    border: 1px solid #EEAA0C;
    border-radius: 10px;
    padding: 30px; */
    /* margin-top: 75px; */
  }

  .videoPlan .subscriptionPlanListInner .second {
    width: 50%;
  }

  .videoPlan .subscriptionPlanListInner .second .SubscriptionCard {
    margin-right: 0;
    /* width: 50%; */
    
  }


  .videoPlan .subscriptionPlanListInner .first .plansTitle {
    display: block;
  }

  .videoPlan .subscriptionPlanListInner .second .historyTitle {
    margin-top: 0;
    padding-left: 15px;
    font-size: 25px;
  }

  .videoPlan .SubscriptionCard .header .title {
    font-size: 21px;
  }

  .videoPlan .SubscriptionCard .header .status .text {
    font-size: 15px;
  }

  .videoPlan .SubscriptionCard .buySubscriptionOuter .btn.buySubscription {
    font-size: 17px;
  }

  .video-plan-search-box-mobile {
    display: none;
  }
  .videoPlan .qu-history-title-search-div {
    /* display: block;
    margin-bottom: 50px; */
  }
  .videoPlan .qu-history-title-search-div .video-plan-search-wrap-outer {
    float: right;
  }
}


@media (min-width: 992px) {
  .videoPlan .subscriptionPlanListInner {
    display: none;
  }
}


@media (max-width: 767px) {
  .video-plan-search-wrap {
    width: 100%;
  }
  .videoPlan .qu-history-title-search-div {
    display: block;
  }
  .videoPlan .subscriptionPlanListInner .custom-col {
    padding: 0;
  }
  .videoPlan .subscriptionPlanListInner {
    padding: 30px 0;
  }
  .search-input input {
    font-size: 15px;
  }
  .videoPlan .SubscriptionCard .history .title {
    font-size: 13px;
  
  }
  .videoPlan .SubscriptionCard .history .subTitle {
    font-size: 15px;
  }
  .videoPlan {
    padding-top: 55px;
  }
  

}

@media (max-width: 991px) {
  .table-response-videoIndex {
    display: none;
  }
}


@media screen and (min-width: 768px) and (max-width: 991px) {
  .videoPlan {
    padding: 0 10px;
    padding-top: 55px;
  }
  .videoPlan .qu-history-title-search-div {
    padding: 0 5px;
    align-items: center;
    margin-bottom: 30px;
  }
  .videoPlan .subscriptionPlanListInner {
    padding: 0px 0px;
    display: flex;
    flex-wrap: wrap;
  }
  .videoPlan .mainTitle {
    padding-bottom: 0px;
  }
  .videoPlan .subscriptionPlanListInner .second .custom-col {
    width: 100%;
  }
  .video-plan-search-wrap {
    justify-content: flex-end;
  }

}
