/*----- New CSS Start -----*/
.my-space {
  padding: 105px 5px 60px 5px;
}

.ml-0 {
  margin-left: 0;
}

.my-space .title-section-div {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  padding-bottom: 20px;
}

.my-space .watch-later .downloadEBook .download-ebook-col-count-3.custom-row {
  padding: 0 5px;
}

.my-space .watch-later .my-space-empty-card-wrap-outer {
  padding: 0 10px;
  /* margin-top: -30px; */
}

.py-pl-prr-5 {
  padding: 0 10px;
}

.my-space .watch-later .slick-slider {
  padding: 0 5px;
}

.my-space .title-section {
  font-size: 25px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.my-space .title-view-more {
  font-size: 17px;
  color: #F6AE06;
  text-align: right;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  align-self: center;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.my-space .title-create {
  font-size: 15px;
  color: #F6AE06;
  text-align: right;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  align-self: center;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-right: 30px;
}

.my-space .d-flex {
  display: flex;
}

.my-space-tab-a-center {
  align-self: center;
}

.my-space-ta-left {
  text-align: left;
}

.my-space-ta-right {
  text-align: right;
}

.my-space .watch-later .download-ebook-col-count-3 {
  padding: 0 5px;
}

.my-space .watch-later .qu-history {
  padding: 0 5px;
}

.my-space .watch-later .my-created-playlist-col-3 {
  padding: 0 5px;
}

.my-space .watch-later .shared-playlist-outer-wrap {
  padding: 0 5px;
}

.my-space-play-list-ta-right {
  text-align: left;
  display: flex;
}

.my-space-mt-10 {
  margin-top: 10px;
}

.my-space-mt-20 {
  margin-top: 20px;
}

.my-space-mt-40 {
  margin-top: 40px;
}

.my-space-col-count-3 {
  column-count: 3;
  margin-top: 10px;
}

.my-space-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  /* height: 390px; */
}

.my-space-qh-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  height: 175px;
}

.my-space-inside-wrap {
  display: block;
  width: 100%;
  background-color: #FFFCF5;
}

.my-space-inside-wrap .my-space-list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  background: #000000ba;
}

.my-space-inside-wrap .my-space-img-banner {
  width: 100%;
  height: 270px;
  height: 170px;
  display: flex;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
}

.my-space-ebook .my-space-inside-wrap .my-space-img-banner {
  height: 100%;
  aspect-ratio: 9/14;
  object-fit: unset;
}

.my-space-play-icon {
  position: absolute;
  bottom: -32px;
  right: 0px;
  cursor: pointer;
}

.my-space-card-content-item {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.my-space-qh-card-content-item {
  padding: 10px;
  width: 95%;
}

.my-space-video-content {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.my-space-video-content img {
  margin-right: 10px;
  cursor: pointer;
}

.my-space-title-text {
  font-size: 15px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.my-space-qh-title-text {
  font-size: 17px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.my-space-qh-title-description {
  font-size: 13px;
  color: #212121;
  text-align: left;
  margin-top: 8;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.my-space-qh-text-hrs {
  color: #8C8C8C;
  font-size: 13px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.my-space-text-hrs {
  color: #8C8C8C;
  font-size: 13px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.my-space-read {
  color: #FFB200;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  cursor: pointer;
}

.my-space-d-none {
  display: none;
}

/*----- My Space Empty Msg CSS Start -----*/
.my-space-empty-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  width: 100%;
  background-color: #FFFCF5;
  padding: 10px;
  margin-top: 15px;
  /* margin-left: 5px;
  margin-right: 5px; */
  box-sizing: border-box;
}

.my-space-empty-msg {
  color: #8C8C8C;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  text-overflow: ellipsis;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

/*----- My Space Empty Msg CSS End -----*/
.my-space .mainTitle {
  color: #212121;
  font-family: Poppins;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-bottom: 20px;
  text-align: left;
  display: none;
  padding: 0px 10px;
}

.my-space .my-space-col-count-3.custom-row.my-space-playlists {
  padding: 0 5px;
}


.my-space .downloadEBook {
  padding: 0;
}

.my-space .myCreatedPlaylist {
  padding: 0;
  margin-top: -20px;
}

.my-space .myCreatedPlaylist .my-created-playlist-title-search-div {
  padding: 0;
  padding-bottom: 35px;
  justify-content: flex-start;
}

.my-space .sharedPlaylist {
  padding: 0;
}

.my-space .questionHistory {
  padding: 0;
}

.my-space .custom-col-4.my-space-ebook {
  width: 20%;
}

.my-space .downloadEBook .download-ebook-col-count-3 .custom-col-4 {
  width: 20%;
}












.popular-bhaktiPads-page {
  padding: 0px 15px;
  padding-bottom: 50px;
  padding-top: 105px;
  position: relative;
}

.popular-bhaktiPads-page .popular-bhaktiPads-page-title-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}

.popular-bhaktiPads-page .popular-bhaktiPads-page-title-outer .popular-bhaktiPads-page-title {
  /* margin-top: 20px; */
}

.popular-bhaktiPads-page .popular-bhaktiPads-page-title-outer .popular-bhaktiPads-page-title .my-favorites-d-flex {
  padding: 0;
}

.popular-bhaktiPads-page .top {
  margin-top: 43px;
  box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
  border: 1px solid #EEAA0C;
  border-radius: 10px;
  padding: 22px;
  display: flex;
  align-items: center;
  gap: 22px;
}

.popular-bhaktiPads-page .top .topImg {
  width: 131px;
  height: 131px;
  border-radius: 8px;
}

.popular-bhaktiPads-page .top .label {
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  color: #212121;
  margin: 0;
  text-transform: uppercase;
  font-family: Poppins;
}

.popular-bhaktiPads-page .mainTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
}

.popular-bhaktiPads-page .mainTitleOuter .viewMore {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}

.popular-bhaktiPads-page .mainTitleOuter .mainTitle {
  letter-spacing: 0em;
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
  margin: 0;
}

.popular-bhaktiPads-page .aboutUs-img-div {
  margin-bottom: 15px;
  text-align: center;
}

.popular-bhaktiPads-page .aboutUs-img-div .AbousImage {
  width: 100%;
  margin: 15px auto;
  border-radius: 10px;
}

.popular-bhaktiPads-page .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  padding: 15px;
  background: #fffcf5;
}

.popular-bhaktiPads-page .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;

}

.popular-bhaktiPads-page .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.table-responsive {
  overflow: auto;
}


/* Table css */
.popular-bhaktiPads-page table {
  width: 100%;
}

.popular-bhaktiPads-page .titleSection {
  max-width: 600px;
  display: block;
  overflow: hidden;
}

.popular-bhaktiPads-page .table-responsive .table th,
.popular-bhaktiPads-page .table-responsive .table td {
  border: none;
}

.popular-bhaktiPads-page .table-responsive .table th:nth-of-type(1) {
  padding-left: 0;
}

.popular-bhaktiPads-page .table-responsive .table td:nth-of-type(1) {
  padding-left: 0;
}

.popular-bhaktiPads-page .table-responsive .table td:nth-last-child(1) {
  padding-right: 0;
}

.popular-bhaktiPads-page .table-responsive .table th {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
}

.popular-bhaktiPads-page .table-responsive .table th {
  border-bottom: 1px solid #DBC48E;
}

.popular-bhaktiPads-page .table-responsive .table .srNo {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  margin: 0;
  text-align: left;
}

.popular-bhaktiPads-page .table-responsive .table .srNo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.popular-bhaktiPads-page .table-responsive .table .srNo .no {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  color: #212121;
}

.popular-bhaktiPads-page .table-responsive .table .srNo .bhaktiImg {
  width: 60px;
  height: 60px;
  border-radius: 8px;
}

.popular-bhaktiPads-page .table-responsive .table .titleSection .title {
  margin: 0;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.popular-bhaktiPads-page .table-responsive .table .titleSection .subTitle {
  margin: 0;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  color: #6B6B6B;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.popular-bhaktiPads-page .table-responsive .table .album {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  margin: 0;
  color: #6B6B6B;
}

.popular-bhaktiPads-page .table-responsive .table .duration {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
}

.popular-bhaktiPads-page .table-responsive .table .actions {
  display: flex;
  gap: 15px;
  align-items: center;
}

.watch-later.popular-bhaktiPads-page {
  padding-top: 0;
}

.popular-bhaktiPads-page .top .label {
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  color: #212121;
  margin: 0;
  text-transform: uppercase;
  font-family: Poppins;
}

.popular-bhaktiPads-page .mainTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
}

.popular-bhaktiPads-page .mainTitleOuter .viewMore {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
}

.popular-bhaktiPads-page .mainTitleOuter .mainTitle {
  letter-spacing: 0em;
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
  margin: 0;
}

.popular-bhaktiPads-page .aboutUs-img-div {
  margin-bottom: 15px;
  text-align: center;
}

.popular-bhaktiPads-page .aboutUs-img-div .AbousImage {
  width: 100%;
  margin: 15px auto;
  border-radius: 10px;
}

.popular-bhaktiPads-page .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  padding: 15px;
  background: #fffcf5;
}

.popular-bhaktiPads-page .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;

}

.popular-bhaktiPads-page .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.table-responsive {
  overflow: auto;
}

.popular-bhaktiPads-page .tableHover:hover {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
}


/* Table css */

/* player css */


/* player css */

.popular-bhaktiPads-page .controlsTop {
  display: none;
  align-items: center;
  gap: 12px;
  padding-bottom: 20px;
  padding-top: 15px;
}

.popular-bhaktiPads-page .controlsTop .playIcon {
  width: 36px;
  height: 36px;
}

.popular-bhaktiPads-page .controlsTop .moreImg {
  transform: rotate(90deg);
}


@media (max-width: 991px) {
  .popular-bhaktiPads-page .top .label {
    font-size: 32px;
  }

  .popular-bhaktiPads-page .mainTitleOuter .mainTitle {
    font-size: 23px;
    line-height: 34.5px;
  }

  .popular-bhaktiPads-page .table-responsive .table th.none {
    display: none;
  }

  .popular-bhaktiPads-page .table-responsive .table td.none {
    display: none;
  }

  .popular-bhaktiPads-page .table-responsive .table .titleSection {
    max-width: 290px;
  }

  .popular-bhaktiPads-page .mainTitleOuter .mainTitle {
    font-size: 23px;
    line-height: 34.5px;
  }



  .popular-bhaktiPads-page .popular-bhaktiPads-page-title-outer .list-of-play-search .search-input {
    display: none;
  }

  .popular-bhaktiPads-page .popular-bhaktiPads-page-title-outer .list-of-play-search .searchIcon-outer.searchTooltip {
    display: block;
  }
}


@media (max-width: 767px) {
  .popular-bhaktiPads-page {
    padding-top: 50px;
  }

  .popular-bhaktiPads-page .popular-bhaktiPads-page-title-outer {
    display: inline;
  }

  .popular-bhaktiPads-page .popular-bhaktiPads-page-title-outer .popular-bhaktiPads-page-title {
    display: none;
  }

  .popular-bhaktiPads-page .top .label {
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }

  .popular-bhaktiPads-page .mainTitleOuter .mainTitle {
    font-size: 18px;
    line-height: 27px;
  }

  .popular-bhaktiPads-page .mainTitleOuter .viewMore {
    height: auto;
    padding: 0;
    font-size: 13px;
    line-height: 19.5px;
  }

  .popular-bhaktiPads-page .mainTitleOuter {
    display: none;
  }

  /* .mobHide {
    display: none;
  } */


  .popular-bhaktiPads-page .table-responsive .table .actions .more {
    transform: rotate(90deg);
  }

  .popular-bhaktiPads-page .table-responsive .table .srNo .bhaktiImg {
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }

  .popular-bhaktiPads-page .table-responsive .table .titleSection .title {
    font-size: 15px;
    line-height: 22.5px;
  }

  .popular-bhaktiPads-page .table-responsive .table .titleSection .subTitle {
    font-size: 13px;
    line-height: 19.5px;
  }

  .popular-bhaktiPads-page .table-responsive .table td {
    padding: 10px;
  }

  .popular-bhaktiPads-page .table-responsive .table .titleSection {
    max-width: 120px;
  }

  .my-space .popular-bhaktiPads-page .table-responsive .table .titleSection {
    max-width: 130px;
  }

  .popular-bhaktiPads-page .popular-bhaktiPads-page-title-outer .list-of-play-search .search-input {
    display: block;
  }

  .popular-bhaktiPads-page .popular-bhaktiPads-page-title-outer .list-of-play-search .searchIcon-outer.searchTooltip {
    display: none;
  }

  .popular-bhaktiPads-page .controlsTop {
    display: flex;
  }



}

@media (min-width: 768px) {
  .popular-bhaktiPads-page .padPlay .player .controls .plus {
    display: none;
  }
}


.iframe {
  border-radius: 12px
}

/* three dot css start*/
.popupOuter {
  position: relative;
  z-index: 1;
}

.my-space .morePopup {
  position: absolute;
  right: 20px;
  top: 50px;
  background: #FDFDFD;
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 4px 10px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 12px 15px;
  z-index: 9;
}

.my-space .table-responsive .table tbody tr:nth-last-child(1) .morePopup {
  top: -5px;
}

.my-space .morePopup .outer {
  display: flex;
  gap: 10px;
  align-items: center;
  width: max-content;
}

.my-space .morePopup .outer .label {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8c8c8c;
  margin: 0;
}

/* three dot css END*/

@media (min-width: 768px) {
  .my-space .mainTitle {
    display: block;
  }
}

@media (max-width: 991px) {

  .my-space .morePopup {
    display: none;
  }

  .my-space-col-count-3 {
    column-count: 2;
  }

  .my-space .custom-col-4.my-space-ebook {
    width: 33.33%;
  }

  .my-space .downloadEBook .download-ebook-col-count-3 .custom-col-4 {
    width: 33.33%;
  }
}

@media screen and (max-width: 767px) {
  .my-space .sharedPlaylist .my-favorites-tab-div {
    padding: 0 5px;
  }

  .my-space .MuiTabs-flexContainer {
    overflow: auto;
  }

  .my-space .py-pl-prr-5 .css-1w5fbjm-MuiTabs-root .MuiTabs-indicator {
    background: transparent;
  }

  .my-space {
    padding: 70px 10px 60px 10px;
  }

  .my-space .custom-col-4.my-space-ebook {
    width: 100%;
  }

  .my-space .downloadEBook .download-ebook-col-count-3 .custom-col-4 {
    width: 100%;
  }

  .my-space .title-section-div {
    padding: 0 5px;
    padding-bottom: 10px;
  }

  .my-space .watch-later .slick-slider {
    padding: 0 0px;
  }

  .py-pl-prr-5 {
    padding: 0 5px;
  }

  .my-space .watch-later .slick-slider .slick-list {
    overflow: inherit;
  }

  .my-space .watch-later .custom-col {
    padding: 0;
    margin-bottom: 0;
  }

  .my-space-card-wrap {
    box-shadow: 1px 3px 15px 0px #00000026;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
    height: inherit;
  }

  .my-space-qh-card-wrap {
    box-shadow: 1px 3px 15px 0px #00000026;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
    height: inherit;
  }

  .my-space-col-count-3 {
    column-count: 1;
    margin-top: 10px;
  }


  /* .my-space-inside-wrap {
    display: flex;
    width: 100%;
    background-color: #FFFCF5;
  } */

  /* .my-space-inside-wrap .my-space-list-image {
    max-width: 30%;
    width: 100%;
    position: relative;
    flex-shrink: 0;
  } */

  .my-space-inside-wrap .my-space-img-banner {
    width: 100%;
    /* height: 120px; */
    display: flex;
  }

  /* .my-space-play-icon {
    position: absolute;
    bottom: -12px;
    right: -30px;
  } */

  .my-space-d-none {
    display: contents;
  }

  .my-space-play-list-ta-right {
    text-align: right;
  }

  .my-space-mlr-10 {
    /* margin-left: -10px;
    margin-right: -10px; */
  }

  /*----- My Space Empty Msg CSS Start -----*/
  .my-space-empty-card-wrap {
    box-shadow: 1px 3px 15px 0px #00000026;
    border-radius: 10px;
    overflow: hidden;
    background-color: #FFFCF5;
  }

  .my-space-empty-msg {
    color: #8C8C8C;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0px;
    margin-block-start: inherit;
    margin-block-end: inherit;
  }

  .my-space .title-section {
    font-size: 20px;
  }

  .my-space .title-view-more {
    font-size: 15px;
  }

  .my-space .watch-later .my-space-empty-card-wrap-outer {
    padding: 0 5px;
  }






}

/*----- My Space Empty Msg CSS End -----*/
@media screen and (min-width: 768px) and (max-width: 991px) {
  .my-space .title-section {
    font-size: 23px;
  }

  .my-space .mainTitle {
    font-size: 33px;
  }

}

/*----- New CSS End -----*/

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .my-space .downloadEBook .download-ebook-col-count-3 .custom-col-4 {
    width: 33.33%;
  }

  .my-space .custom-col-4.my-space-ebook {
    width: 33.33%;
  }
}
