/*----- New CSS Start -----*/
.my-favorites {
  padding: 105px 10px 30px;
}

.my-favorites-title-mt-20 {
  margin-top: 20px;
  display: contents;
}

.my-favorites-d-flex {
  display: flex;
  padding: 0 5px;
  cursor: pointer;
  width: fit-content;
}

.my-favorites-c-pointer {
  cursor: pointer;
}

.my-favorites-title {
  font-size: 35px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-left: 10px;
}

.my-favorites-tab-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 5px;
}

.my-favorites-tab-a-center {
  align-self: center;
}

.search-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
}
.searchIcon-outer {
  display: none;
}

.search-input {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.search-input input {
  font-size: 17px;
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  width: 100%;
}

.filter-icon {
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}

.my-favorites-column-count-3 {
  column-count: 3;
  padding-bottom: 60px;
}

.my-favorites-play-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  /* height: 395px; */
}

.my-favorites-play-inside-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #FFFCF5;
}

.my-favorites-play-inside-wrap .my-favorites-play-list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  background: #000000ba;
}

.my-favorites-play-inside-wrap .my-favorites-img-banner {
  width: 100%;
  height: 270px;
  height: 170px;
  display: flex;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
}

.my-favorites-play-play-icon {
  position: absolute;
  bottom: -25px;
  right: 0px;
  cursor: pointer;
}

.my-favorites-play-card-content-item {
  padding: 15px 10px 10px;
}

.my-favorites-play-video-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.my-favorites .my-favorites-tab-div .search-wrap {
  max-width: 450px;
}
.my-favorites-play-video-content img {
  margin-right: 13px;
  cursor: pointer;
}

.my-favorites-play-title-text {
  font-size: 17px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.my-favorites-play-text-hrs {
  font-size: 15px;
  color: #8C8C8C;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.my-favorites-mt-10 {
  margin-top: 10px;
}
.myFavouritesEbbok .my-space-inside-wrap .my-space-img-banner {
  height: 100%;
  aspect-ratio: 9/14;
  object-fit: unset;
}

.myFavouritesEbbok .custom-col-4 {
  width: 20%;
}

.my-favorites-title-mt-20 .search-wrap {
  display: none;
}

/* three dot css start*/
.popupOuter {
  position: relative;
  z-index: 1;
}

.my-favorites .morePopup {
  position: absolute;
  right: 20px;
  top: 50px;
  background: #FDFDFD;
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 4px 10px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 12px 15px;
  z-index: 9;
}

.my-favorites .table-responsive .table tbody tr:nth-last-child(1) .morePopup {
  top: -5px;
}

.my-favorites .morePopup .outer {
  display: flex;
  gap: 10px;
  align-items: center;
  width: max-content;
}

.my-favorites .morePopup .outer .label {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8c8c8c;
  margin: 0;
}

/* three dot css END*/

@media (max-width: 991px) {
  .my-favorites .morePopup {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .my-favorites {
    padding: 50px 15px 30px;
  }
  .myFavouritesEbbok .custom-col-4 {
    width: 100%;
  }
  .my-favorites-title-mt-20 {
    margin-top: 20px;
    display: none;
  }

  .my-favorites-column-count-3 {
    column-count: 1;
    padding-bottom: 20px;
  }

  .my-favorites-tab-div {
    display: block;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0;
  }
  .my-favorites .my-favorites-column-count-3 .custom-col {
    padding: 0;
  }
  .search-input input {
    font-size: 15px;
  }
  .my-favorites-play-title-text {
    font-size: 15px;
  }
  .my-favorites-play-text-hrs {
    font-size: 13px;
  }
  .my-favorites-play-inside-wrap .my-favorites-img-banner {
    /* height: 150px; */
  }
  .my-favorites-tab-div .MuiTabs-flexContainer {
    overflow: auto;
  }
  .my-favorites-tab-div .css-1w5fbjm-MuiTabs-root .MuiTabs-indicator {
    background: transparent;
  }


}
.my-favorites-tab-div .my-favorites-tab-a-center {
  width: 100%;
}
/*----- New CSS End -----*/


/* List View CSS */
.watch-later.listView .my-space-col-count-3 {
  flex-direction: column;
  column-count: 1;
}
.watch-later.listView .custom-col-4 {
  width: 100%;
}
.watch-later.listView .my-space-inside-wrap {
  display: flex;
}
.watch-later.listView .my-space-list-image {
  width: 100px;
}
.watch-later.listView .my-space-img-banner {
  width: 100px;
  aspect-ratio: 16/9;
}
.watch-later.listView .my-space-play-icon {
  width: 40px;
  bottom: 0;
  right: -15px;
}
.watch-later.listView .my-space-video-content {
  flex-direction: row;
  align-items: center;
}
.watch-later.listView .my-space-video-content-inner {
  display: flex;
  flex-direction: column;
  height: 70px;
  gap: 15px;
}
.watch-later.listView .my-space-mt-10 {
  margin: 0;
}
.watch-later.listView .download-ebook-like-icon-div {
  padding: 0;
}


/* List View CSS */

@media screen and (min-width: 768px) and (max-width: 991px) {
  /* .search-input {
    display: none;
    width: 0;
  } */
  /* .searchIcon-outer {
    display: block;
  } */
  .search-wrap {
    justify-content: flex-end;
  }
  .my-favorites-title {
    font-size: 30px;
  }
  .myFavouritesEbbok .custom-col-4 {
    width: 33.33%;
  }
  
  .my-favorites-tab-div .search-wrap {
    display: none;
  }
  .my-favorites-title-mt-20 {
    padding-bottom: 70px;
    display: block;
  }
  .my-favorites-title-mt-20 .my-favorites-d-flex {
    align-items: center;
  }
  .my-favorites-title-mt-20 .my-favorites-d-flex .my-favorites-c-pointer {
    width: 28px;
  }
  .my-favorites-title-mt-20 .search-wrap {
    display: flex;
    float: right;
    margin-bottom: 0;
  }

}


@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .my-favorites .my-favorites-tab-div .search-wrap {
    top: -70px;
    position: absolute;
    max-width: 300px;
    right: 0;
  }
  .my-favorites-tab-div {
    position: relative;
  }
  .myFavouritesEbbok .custom-col-4 {
    width: 33.33%;
  }
}