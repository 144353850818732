.carousel {
  width: 100%;
}

.carousel-item {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fffcf5;
  border-radius: 10px;
  box-shadow: 1px 3px 15px 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  max-width: 216px;
  height: 250px;
  margin-right: 15px;
  position: relative;
}

.thumbnail-container {
  height: 50%;
  width: 100%;
  position: relative;
}

.thumbnail {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.details-container {
  /* width: 100%; */
  text-align: start;
  height: 50%;
  padding: 10px;
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.details-container .title-container {
  height: 60px;
  /* Set the desired height */
  overflow: hidden;
  text-overflow: ellipsis;
}

.details-container h3 {
  color: #212121;
  font-size: 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
}

.details-container p {
  color: #8c8c8c;
  font-size: 13px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.bullet-point {
  margin-right: 5px;
  list-style-type: disc;
  list-style-position: inside;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: #8c8c8c;
  color: #8c8c8c;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.playButtonContainer {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(-10%, -15%);
  width: 40px;
  height: 40px;
}

.playButton {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 14px solid #ffd700;
}



/* .watch-later{
  padding: 10px;
} */
.watch-later .play-title-wrapper {
  display: flex;
  justify-content: space-between;
}

.watch-later .play-title-wrapper p {
  margin-bottom: 10px;
  font-weight: 400;
}

.watch-later .play-title-wrapper p.lef {
  color: #212121;
  margin-bottom: 0;
  font-weight: 400;

}

.watch-later .play-title-wrapper p.right {
  margin-bottom: 0;
  color: #F6AE06;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
}

.carousel-play-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  /* height: 375px; */
}

.watch-later .play-inside-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #FFFCF5;
  position: relative;
}

.watch-later .play-inside-wrap .play-list-image {
  max-width: 100%;
  width: 100%;
  position: static;
  flex-shrink: 0;
  cursor: pointer;
  background: #000000ba;
}

.watch-later .play-inside-wrap .img-banner {
  width: 100%;
  height: 270px;
  /* height: 170px; */
  display: flex;
  max-height: 270px;
  border-radius: 0px;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
}

.watch-later .play-play-icon {
  position: absolute;
  right: 0px;
  width: 70px;
}

.watch-later .play-card-content-item {
  padding: 15px 10px 10px;
}

.watch-later .play-card-list {
  display: flex;
  flex-wrap: wrap;
}

.watch-later .play-video-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.watch-later .play-video-content img {
  margin-right: 13px;
  height: 22px;
  display: inline-block;
  width: auto;
}

.watch-later .mt-10 {
  margin-top: 10px;
}

.watch-later .play-title-text {
  font-size: 17px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.watch-later .play-text-hrs {
  font-size: 15px;
  color: #8C8C8C;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.watch-later .search-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
}
.searchIcon-outer {
  display: none;
}




.search-input {
  position: relative;
  width: 100%;
}

.watch-later .search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.watch-later .search-input input {
  font-size: 17px;
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  width: 100%;
}

.watch-later .filter-icon {
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}

@media screen and (max-width: 767px) {
  .watch-later .play-play-icon {
    position: absolute;
    bottom: -25px;
    right: 0px;
    width: 70px;
    height: 70px;
  }
  .watch-later .play-inside-wrap .img-banner {
    /* max-height: 170px; */
    /* height: 120px; */
  }
  .watch-later .play-title-text {
    font-size: 15px;
  }
  .watch-later .play-text-hrs {
    font-size: 13px;
  }
  .watch-later .search-input input {
    font-size: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .watch-later .play-inside-wrap .img-banner {
    /* height: 170px; */
    /* max-height: 170px; */
  }
  /* .search-input {
    display: none;
    width: 0;
  } */
  /* .searchIcon-outer {
    display: block;
  } */
  .search-wrap {
    justify-content: flex-end;
  }
}

