.liveSatsangMain {
  padding: 0 15px;
  padding-top: 105px;
}
.liveSatsangMain .liveSatsang-title {
  font-family: Poppins;
  font-size: 35px;
  font-weight: 400;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  padding-left: 10px;
  margin: 0;
}
.focused-question{
  border: 2px solid #EEAA0C; /* You can change the color code (#3498db) to your preferred color */
  padding: 10px; /* Add padding if needed */
  border-radius: 10px

  
}

.liveSatsang .liveChat {
  position: relative;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  border-radius: 10px 10px 0px 0px;
}
.liveSatsang .liveChat .closeDash {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.liveSatsang .liveChat .header {
  padding: 30px 15px 5px 15px;
  border-bottom: 1px solid #DBC48D;
}
.liveSatsang .liveChat .headerTop {
  display: flex;
  justify-content: space-between;
}
.liveSatsang .liveChat .header .headerTop .title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  margin: 0;
}
.liveSatsang .liveChat .header .subTitle {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
  color: #8C8C8C;
  margin-top: 10px;
}



.liveSatsang .liveChat .chatBox {
  padding: 15px;
  overflow: auto;
  height: 100vh;
  max-height: calc(100vh - 580px);
  padding-bottom: 30px;
  min-height: 250px;
}
.liveSatsang .liveChat .chatBox .chatCard {
  display: flex;
  /* justify-content: flex-start; */
  padding: 15px 0px;
}
.liveSatsang .liveChat .chatBox .chatCard .chatUser {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.liveSatsang .liveChat .chatBox .chatCard .chatMsg {
  padding-left: 10px;
}
.liveSatsang .liveChat .chatBox .chatCard .chatMsg .userName {
  margin: 0;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #8C8C8C;
}
.liveSatsang .liveChat .chatBox .chatCard .chatMsg .userName .time {
  padding-left: 15px;
}
.liveSatsang .liveChat .chatBox .chatCard .chatMsg .msg {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: #212121;
  word-break: break-all;
}
.liveSatsang .liveChat .chatBox .chatCard .chatMsg .comment {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: #8C8C8C;
  cursor: pointer;
}
/* .user-interraction-section{
  display: flex;
  flex-direction: row;
  align-items: center;
  
} */
.user-interraction-section .more{
  cursor: pointer;
  
  /* margin-left: 20px; */

}

.liveSatsang .liveChat .chat {
  position: relative;
  border-top: 1px solid #DBC48E;
}
.liveSatsang .liveChat .chat input {
  width: 100%;
  background: #FEFAF0;
  border: none;
  height: 55px;
  padding: 0 15px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}
.liveSatsang .liveChat .chat .msgSend {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.liveStreamDetails {
  display: none;
}
.liveSatsang .liveChat .headerTop .closeOrange {
  cursor: pointer;
}

.home-top-img-banner {
  height: 430px;
  width: 100%;
}

.liveVideoPlay {
  width: 100% !important;
  height: 430px !important;
}
.liveStream {
  position: relative;
}
.liveStream .liveStreamBackArrow {
  position: absolute;
  left: 11px;
  top: 11px;
  display: none;
  background: #00000094;
  border-radius: 50%;
  padding: 5px;
  z-index: 9999;
}
#player .ytp-chrome-top {
  left: 60px;
}


.multiline-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.sender {
  justify-content: flex-end; /* Align sender messages to the right */
  /* Add other sender-specific styles as needed */
}

/* Style for receiver messages */
.receiver {
  justify-content: flex-start; /* Align receiver messages to the left */
  /* Add other receiver-specific styles as needed */
}
.load-more{
  font-size: medium;
  font-weight: 600;
  padding:20px 20px 0px 0px;
  cursor: pointer;
  color: #EEAA0C;
}


@media screen and (min-width: 768px) and (max-width: 991px) {
  .liveSatsang .liveChatOuter {
    padding: 30px 0px;
  }
  .home-top-img-banner {
    height: 364px;
  }
  .liveVideoPlay {
    height: 364px !important;
  }
  .liveSatsang .liveChat .chatBox {
    max-height: calc(100vh - 710px);
  }
}

@media (max-width: 991px) {
  .liveSatsangMain .liveSatsangHeader {
    display: none;
  }
  .liveSatsang .liveChat .chat input {
    padding-right: 50px;
    box-sizing: border-box;
  }  


}

@media (max-width: 767px) {
  .home-top-img-banner {
    height: 203px;
  }
  .liveStream .liveStreamBackArrow {
    display: block;
  }
  .liveVideoPlay {
    height: 250px !important;
  }
  .liveSatsang .liveChat .chatBox {
    /* max-height: calc(100vh - 405px); */
    max-height: calc(100vh - 448px);
  }
  .liveSatsangMain {
    padding: 0px;
  }
}

@media (min-width: 992px) {
  .liveStreamDetails {
    display: block;
    padding-top: 30px;
  }
  .liveSatsang .liveStream {
    width: 70%;
  }
  .liveSatsang .liveChatOuter {
    width: 30%;
  }
  .liveSatsang {
    display: flex;
    gap: 30px;
  }
  .liveSatsang .liveChat .chat input {
    padding-right: 50px;
    box-sizing: border-box;
  }
  .liveSatsang .liveChat .chatBox {
    max-height: calc(100vh - 400px);
  }
  .liveSatsang .liveChatOuter .liveChatInner {
    box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
    border: 1px solid #EEAA0C;
    border-radius: 10px;
    padding: 15px;
  }
  .liveStreamDetails .streamTitle {
    font-family: Poppins;
    font-size: 27px;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    color: #212121;
    margin: 0;
  }
  .liveStreamDetails .streamDesc {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #212121;
    margin: 0;

  }
  .liveStreamDetails .streamDesc .streamDescText {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin: 0;
  }
  .liveStreamDetails .streamDesc .streamDescReadMore {
    font-weight: 600;
    color: #FFB200;
    margin: 0;
    text-align: left;
  }
  
  .liveSatsang .liveChat .chatBox .chatCard {
    padding: 5px 0px;
  }
}






