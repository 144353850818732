.footerStrip {
  position: fixed;
  bottom: 0;
  left: -1px;
  right: -1px;
  padding: 0px;
  border: 1px solid #dcbb6f;
  border-radius: 20px 20px 0px 0px;
  background-color: var(--headerBg);
  height: 55px;
  border-bottom: none;
  z-index: 2;
}

.footer-wtapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.menu-item {
  text-align: center;
  text-align: center;
  width: 20%;
  position: relative;
  height: 100%;
  cursor: pointer;
}

.inside-menu {
  position: relative;
  height: 100%;
}

.icon1 {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.icon2 {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.icon3 {
  position: absolute;
  bottom: 21px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.icon4 {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.icon5 {
  position: absolute;
  bottom: 21px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.menu-item p.menu-text {
  margin: 0;
  font-size: 11px;
  line-height: 16.5px;
  color: #000;
  text-transform: uppercase;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 3px;
}

.menu-text-active {
  font-size: 11px;
  line-height: 16.5px;
  color: var(--yellow);
}

.active-yellow {
  position: absolute;
  bottom: 27px;
  width: 40px;
  height: 40px;
  background-color: #f7ae05;
  box-shadow: 1px 3px 15px 0px #0000004d;
  border-radius: 50%;
  right: 0;
  left: 0;
  text-align: center;
  margin: 0 auto;
  display: none;
}

.active-yellow img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.menu-item.activeClass .active-yellow {
  display: block;
}

.menu-item.activeClass .active-yellow {
  display: block;
}

.menu-item.activeClass .icon {
  display: none;
}

.menu-item.activeClass .menu-text {
  color: var(--yellow);
  font-weight: 600;
}


.surpriseMe.menu-item.activeClass {
  display: none;
  z-index: 1;
}

@media (max-width: 767px) {
  .surpriseMe.menu-item.activeClass {
    position: fixed;
    bottom: 45px;
    right: 0;
    height: auto;
    display: block;
  }

  .surpriseMe.menu-item.activeClass.playerOpen {
    bottom: 160px;
  }
}
