/*----- New CSS Start -----*/
.my-created-playlist-col-3 {
  padding: 0 15px;
  padding-top: 10px;
  column-count: 3;
}

.myCreatedPlaylist {
  padding-top: 85px;
}

.my-created-playlist-title-mt-20 {
  margin-top: 20px;
  display: contents;
}

.my-created-playlist-d-flex {
  display: flex;
  cursor: pointer;
}

.my-created-playlist-c-pointer {
  cursor: pointer;
}

.invalid {
  border: 2px solid red !important;
  box-sizing: border-box;
  border-radius: 11px;
}

.my-created-playlist-title {
  font-size: 35px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-left: 10px;
}

.my-created-playlist-title-search-div {
  display: flex;
  justify-content: space-between;
  /* margin-top: 40px; */
  padding: 0 15px;
}

.my-created-playlist-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  /* height: 375px; */
  position: relative;
}

.my-created-playlist-inside-wrap {
  display: block;
  width: 100%;
  background-color: #FFFCF5;
}

.my-created-playlist-inside-wrap .list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
  background-color: #212121;
  cursor: pointer;
  background: #000000ba;
}

.my-created-playlist-inside-wrap .img-banner {
  width: 100%;
  height: 270px;
  height: 170px;
  display: flex;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
}

.myCreatedBhaktiPad .my-created-playlist-inside-wrap .img-banner {
  aspect-ratio: 1/1;
}

.my-created-playlist-play-icon {
  position: absolute;
  bottom: -32px;
  right: 0px;
  cursor: pointer;
}

.my-created-playlist-content-item {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.my-created-playlist-video-content {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.my-created-playlist-video-content img {
  margin-left: 10px;
  cursor: pointer;
}

.my-created-playlist-title-text {
  font-size: 17px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
  word-break: break-all;
}

.my-created-playlist-text-hrs {
  color: #8C8C8C;
  font-size: 13px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.my-created-playlist-like-icon-div {
  text-align: right;
  padding-top: 10px;
}

.my-created-playlist-c-pointer {
  cursor: pointer;
}

/*----- Search Bar Start CSS -----*/
.my-created-playlist-search-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 400px;
}

.my-created-playlist-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.search-input {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.search-input input {
  font-size: 17px;
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  width: 100%;
}

.filter-icon {
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}

/*----- Search Bar End CSS -----*/


/*----- Desktop Delete and Created Modal Start CSS -----*/
.my-created-playlist-modal-content {
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
}

.my-created-playlist-modal-msg-text {
  font-size: 25px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  width: 90%;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.my-created-playlist-modal-close-btn {
  color: #FFB200;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  height: fit-content;
  text-align: end;
}

.my-created-playlist-modal-close-btn:hover,
.my-created-playlist-modal-close-btn:focus {
  color: #FFB200;
  text-decoration: none;
  cursor: pointer;
}

.my-created-playlist-modal-action-content {
  display: flex;
  padding: 12px;
  margin-top: 10px;
  align-items: center;
}

.my-created-playlist-modal-yes-btn {
  /* width: 80px; */
  height: fit-content;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0px;
  border-color: transparent;
  background-color: transparent;
  color: #FFB200;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 20px;
}

.my-created-playlist-modal-dont-delete-btn {
  width: 141px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid;
  border-color: #FFB200;
  background-color: #FFB200;
  color: #FFFFFF;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

/*----- Desktop Delete Modal End CSS -----*/


/*----- Desktop Created Modal Start CSS -----*/
.my-create-playlist-modal-content {
  padding-bottom: 20px;
}

.modal-d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-create-playlist-modal-playlist-name {
  font-size: 15px;
  color: #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-top: 20px;
  margin-bottom: 4px;
}

.my-create-playlist-modal-search-input {
  width: 100%;
  height: 46px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FFFCF5;
  box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.15);
  line-height: 46px;
  padding: 10px;
  box-sizing: border-box;
  color: #212121;
  font-size: 15px;
  text-align: left;
}

.my-create-playlist-create-btn {
  font-size: 17px;
  width: 120px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid;
  border-color: #FFB200;
  background-color: #FFB200;
  color: #FFFFFF;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.my-create-playlist-cancel-btn {
  font-size: 17px;
  /* width: 80px; */
  height: fit-content;
  padding: 0px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0px;
  border-color: transparent;
  background-color: transparent;
  color: #FFB200;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 20px;
}

input:valid,
textarea:focus,
textarea:valid {
  border: 1px solid #d1d1d1;
}

.playlist-thumbnail-image-div {
  margin-top: 15px;
  text-align: center;
}

.playlist-thumbnail-image {
  height: 180px;
  width: 200px;
}

/*----- Desktop Created Modal End CSS -----*/

.my-created-playlist-title-search-div-second-inner {
  display: flex; 
  justify-content: space-between;
}


@media (min-width: 768px) {
  .createPlaylistModal .MuiDialog-container .MuiPaper-root.MuiDialog-paper {
    min-width: 600px;
  }

  .my-created-playlist-title-mt-20 {
    display: block;
    margin: 0;
  }

  .my-created-playlist-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .my-created-playlist-search-wrap-outer {
    display: flex;
    justify-content: flex-end;
  }

  .my-created-playlist-title-search-div {
    display: block !important;
  }

  .my-created-playlist-search-wrap {
    margin-top: 20px;
  }

  .my-created-playlist-header .header-create-btn {
    color: #F6AE06;
    text-align: right;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-block-start: inherit;
    margin-block-end: inherit;
    margin-left: 20px;
    cursor: pointer;
  }



}


@media screen and (max-width: 767px) {
  .my-created-playlist-col-3 {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    column-count: 1;
  }

  .my-created-playlist-card-wrap {
    box-shadow: 1px 3px 15px 0px #00000026;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
    height: inherit;
  }

  .my-created-playlist-inside-wrap .img-banner {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .my-created-playlist-like-icon-div {
    text-align: right;
  }

  .my-created-playlist-title-search-div {
    display: flex;
    justify-content: center;
  }

  .my-created-playlist-title-mt-20 {
    margin-top: 20px;
    display: none;
  }

  .my-created-playlist-search-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  }

  /*----- Mobile Modal Start -----*/
  .my-created-playlist-modal-content {
    display: flex;
    padding-bottom: 20px;
  }

  .my-create-playlist-modal-content {
    padding-bottom: 20px;
  }

  .my-created-playlist-modal-action-content {
    display: flex;
    padding: 7px;
    margin-top: 10px;
  }

  .my-created-playlist-header .header-create-btn {
    display: none;
  }

  .search-input input {
    font-size: 15px;
  }

  .my-created-playlist-title-text {
    font-size: 15px;
  }

  /*----- Mobile Modal End -----*/
  .my-created-playlist-modal-msg-text {
    font-size: 20px;
  }

  .my-create-playlist-modal-playlist-name {
    font-size: 13px;
  }

  .my-create-playlist-cancel-btn {
    font-size: 15px;
  }

  .my-create-playlist-create-btn {
    font-size: 15px;
  }

  .my-created-playlist-title {
    font-size: 20px;
  }

  .myCreatedPlaylist .my-created-playlist-header {
    width: 100%;
  }

  .myCreatedPlaylist .my-created-playlist-header .my-created-playlist-search-wrap-outer {
    width: 100%;
  }

  .myCreatedPlaylist .custom-col-4.custom-col {
    margin-bottom: 0;
  }

  .myCreatedPlaylist .my-created-playlist-col-3.custom-row {
    padding: 0;
    padding: 0 3px;
  }

  .myCreatedPlaylist {
    padding-top: 75px;
  }

  .myCreatedPlaylist .my-created-playlist-title-search-div {
    justify-content: space-between;
    flex-direction: column;
  }

  .my-created-playlist-title-search-div-second-inner {
    flex-direction: column;
    gap: 20px;
  }

  .myCreatedPlaylist .my-created-playlist-col-3.custom-row {
    padding: 0 20px;
  }
  


}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .my-created-playlist-header-right .my-created-playlist-search-wrap {
    justify-content: flex-end;
  }

  .my-created-playlist-header-right .my-created-playlist-search-wrap {
    width: auto;
  }

  .my-created-playlist-modal-msg-text {
    font-size: 23px;
  }

  .my-created-playlist-title {
    font-size: 33px;
  }

  .my-created-playlist-header {
    display: flex !important;
  }

  .my-created-playlist-search-wrap-outer .my-created-playlist-search-wrap {
    width: auto;
  }

}

/*----- New CSS End -----*/


@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .my-created-playlist-title {
    font-size: 30px;
  }

  .my-created-playlist-search-wrap {
    width: 300px;
  }
}
