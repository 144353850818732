.header-wrapper {
  padding: 10px 30px;
  background-color: #FDF5E2;
  position: fixed;
  width: 100%;
  z-index: 999;
  box-sizing: border-box;
}
.invalid {
  border: 2px solid red; 
  box-sizing: border-box;
  border-radius: 11px;
}
.epubinvalid .custom-input{
  border: 2px solid red; 
  box-sizing: border-box;
  border-radius: 11px;
}
.header-inside-wrapper {
  display: flex;
  justify-content: space-between;
}

.search-wrap-header {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}

.search-input-header {
  position: relative;
  width: 100%;
}

.search-icon-header {
  position: absolute;
  top: 13px;
  left: 15px;
}
.login_modal{
  display: flex;
  justify-content: center;
  align-items: center;

}


.search-input-header input {
  font-size: 17px;
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none !important;
  box-shadow: 1px 3px 15px 0px #00000026 !important;
  background: #fffcf5;
  color: #b1afab;
  padding-left: 50px;
  width: 472px;
  padding-right: 55px;
}

.filter-icon-header {
  width: 42px;
  position: absolute;
  right: -10px;
  top: 14px;
}

.search-bar-desktop {
  display: none;
}

.notification-icon {
  height: 30px;
  width: 30px;
  align-self: center;
  cursor: pointer;
}

.logout-icon {
  height: 26px;
  width: 26px;
  align-self: center;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #efe6e6;
  border-radius: 100%;
  padding: 3px;
  cursor: pointer;
}

.multiLangSearch-icon {
  margin-right: 15px;
  cursor: pointer;
  width: 28px;
}

.d-flex {
  display: flex;
  /* align-items: center;
  justify-content: center; */
}

.mob-header-title {
  color: #212121;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-left: 6px;
}

.m-hide {
  display: none;
}

.profile .header-create-btn {
  font-size: 17px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid;
  border-color: #FFB200;
  background-color: #FFB200;
  color: #FFFFFF;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0;
  padding: 10px 10px;
  width: 140px;
}

/* dictionaryPopup */
.dictionaryPopup .filter-search-modal-mt-15 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dictionaryPopup .filter-search-modal-comm-font-13 {
  color: #212121;
  font-size: 17px;
  margin: 0;
  padding-right: 10px;
}
.dictionaryPopup .filter-search-modal-comm-font-13:nth-of-type(2) {
  padding-left: 20px;
}
.dictionaryPopup .filter-search-modal-location-div {
  width: 110px;
}
.dictionaryPopup .filter-icon-header {
  right: 15px;
  display: flex;
  justify-content: flex-end;
}
.dictionaryPopup .filter-icon-header .keyboard {
  margin-right: 15px;
}
.dictionaryPopup .search-input-header {
  margin-top: 30px;
}
.dictionaryPopup .search-input-header input {
  padding-left: 15px;
  padding-right: 130px;
  color: #212121;
}
.dictionaryPopup .search-input-header input::-webkit-input-placeholder {
  /* color: #212121; */
}
.dictionaryPopup .translation .translationBox {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
}
.dictionaryPopup .translation p {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}
.dictionaryPopup .translation .translationBox p {
  padding: 15px;
  margin: 0;
  border-top: 1px solid #dbc48e47;
}
.dictionaryPopup .translation .translationBox p:nth-of-type(1) {
  border: none;
}


/* dictionaryPopup */

.customToggle .switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
}

.customToggle .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.customToggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.customToggle .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.customToggle input:checked + .slider {
  background-color: #FFB200;
}

.customToggle input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.customToggle input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.customToggle .slider.round {
  border-radius: 34px;
}

.customToggle .slider.round:before {
  border-radius: 50%;
}
.indian-calender .rmdp-container {

    position: absolute;
    right: 5px;
    top: 5px;
    
}







@media screen and (max-width: 767px) {
  .search-wrap-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
  }

  .logout-icon {
    margin-right: 0;
    margin-left: 10px;
  }

  .header-wrapper {
    padding: 5px 15px;
    border-bottom: 1px solid #DBC48E;
  }

  .search-bar-mobile {
    display: none;
  }

  .search-bar-desktop {
    display: inherit;
  }

  .mob-header-title.mob-header-title-ml-0 {
    margin-left: 0px;
  }

  .search-input-header input {
    width: 100%;
  }

  .search-input-header {
    margin-top: 15px;
  }

  .header-inside-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .m-hide {
    display: none;
  }

  .min-h {
    min-height: 45px;
  }

  .min-h .d-flex {
    align-items: center;
  }

  .header-create-btn {
    color: #F6AE06;
    text-align: right;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-block-start: inherit;
    margin-block-end: inherit;
  }

  .header-jc-sp-c {
    justify-content: space-between;
    align-items: center;
  }

  .header-advacepage {
    display: none;
  }

  .dictionaryPopup .filter-search-modal-comm-font-13 {
    font-size: 15px;
  }
  .dictionaryPopup .translation p {
    font-size: 15px;
  }
  .dictionaryPopup .MuiDialog-container .MuiPaper-root.MuiDialog-paper {
    width: 100%;
  }





}



@media screen and (min-width: 767px) {
  .d-hide {
    display: none;
  }

  .m-hide {
    display: flex;
  }
}


@media (min-width: 1550px) {
  .header-inside-wrapper .d-flex {
    padding-right: 25px;
  }
}

@media (min-width: 1750px) {
  .header-inside-wrapper .d-flex {
    padding-right: 135px;
  }
}

@media (min-width: 1850px) {
  .header-inside-wrapper .d-flex {
    padding-right: 365px;
  }
}



