/*----- New CSS Start -----*/
.questionHistory {
  padding: 105px 10px 30px;
}
.qu-history {
  padding: 0 10px;
  padding-bottom: 60px;
}
.qu-history .qu-history-title-search-div {
  padding: 0 5px;
}
.qu-history-col-count-3 {
  column-count: 3;
  padding-top: 10px;
}

.qu-history-title-mt-20 {
  margin-top: 20px;
  display: contents;
}

.qu-history-d-flex {
  display: flex;
  cursor: pointer;
  width:fit-content;  
}
/* .qu-history-modal-yes-btn:hover {
  background: transparent;
} */
.qu-history-c-pointer {
  cursor: pointer;
}

.qu-history-title {
  font-size: 35px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-left: 10px;
}

.qu-history-title-search-div {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.qu-history-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  /* height: 175px; */
}

.qu-history-inside-wrap {
  display: block;
  width: 100%;
  background-color: #FFFCF5;
}

.qu-history-inside-wrap .my-space-list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
}

.qu-history-inside-wrap .my-space-img-banner {
  width: 100%;
  height: 270px;
  display: flex;
}

.qu-history-card-content-item {
  padding: 10px;
  /* width: 95%; */
}

.qu-history-video-content {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.qu-history-video-content img {
  margin-right: 10px;
  cursor: pointer;
}

.qu-history-title-text {
  font-size: 15px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.qu-history-title-description {
  font-size: 15px;
  color: #212121;
  text-align: left;
  margin-top: 8;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.qu-history-text-hrs {
  color: #8C8C8C;
  font-size: 13px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.qu-history-mt-10 {
  margin-top: 10px;
}

.qu-history-bottom-section-div {
  display: flex;
  justify-content: space-between;
}

.qu-history-ta-left {
  text-align: left;
}

.qu-history-ta-right {
  text-align: right;
}

.qu-history-read {
  color: #FFB200;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  cursor: pointer;
}

.qu-history-read:hover {
  opacity: 0.5;
}

/*----- Search Bar Start CSS -----*/
.qu-history-search-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 410px;
}

.search-input {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.search-input input {
  font-size: 17px;
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  width: 100%;
}

.filter-icon {
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}

/*----- Search Bar End CSS -----*/

/*----- Modal Start CSS -----*/
.qu-history-modal-msg-content {
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
}

.qu-history-modal-msg-text {
  font-size: 25px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  width: 90%;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.qu-history-modal-close-button {
  color: #FFB200;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  height: fit-content;
  cursor: pointer;
  text-align: end;
}

.qu-history-modal-close-button:hover,
.qu-history-modal-close-button:focus {
  color: #FFB200;
  text-decoration: none;
  cursor: pointer;
}

.qu-history-modal-action-content {
  display: flex;
  padding: 12px;
  margin-top: 10px;
  align-items: center;
}

.qu-history-modal-yes-btn {
  /* width: 80px; */
  height: fit-content;
  margin-right: 20px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0px;
  border-color: transparent;
  background-color: transparent;
  color: #FFB200;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.qu-history-modal-dont-delete-btn {
  width: 141px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid;
  border-color: #FFB200;
  background-color: #FFB200;
  color: #FFFFFF;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

/*----- Modal End CSS -----*/

@media screen and (max-width: 767px) {
  .qu-history {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 60px;
  }

  .qu-history-col-count-3 {
    column-count: 1;
    padding-top: 10px;
  }

  .qu-history-title-search-div {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .qu-history-search-wrap-outer {
    width: 100%;
  }
  .qu-history-search-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  }

  .qu-history-title-mt-20 {
    margin-top: 20px;
    display: none;
  }

  .qu-history-modal-msg-content {
    display: flex;
    padding-bottom: 20px;
  }

  .qu-history-modal-action-content {
    display: flex;
    padding: 7px;
    margin-top: 10px;
  }
  .search-input input {
    font-size: 15px;
  }
  .qu-history-modal-msg-text {
    font-size: 20px;
  }
  .qu-history-title-description {
    font-size: 13px;
  }
  .qu-history-title-text {
    font-size: 17px;
  }
  .qu-history .custom-col-4.custom-col {
    padding: 0;
    margin: 0;
  }
  .qu-history .qu-history-title-search-div {
    padding: 0;
  }
  .questionHistory {
    padding-top: 55px;
  }
  
}

/*----- New CSS End -----*/



@media screen and (min-width: 768px) and (max-width: 991px) {
  .qu-history-modal-msg-text {
    font-size: 23px;
  }
  .qu-history-title-description {
    font-size: 14px;
  }
  .qu-history .qu-history-title-search-div .qu-history-search-wrap-outer .qu-history-search-wrap {
    justify-content: flex-end;  
    width: auto;
  }
  .qu-history-title {
    font-size: 33px;
  }
  .qu-history .qu-history-title-search-div {
    display: block;
    margin: 0;
  }
  
  .qu-history .qu-history-title-search-div .qu-history-search-wrap-outer .qu-history-search-wrap {
    margin-top: 10px;
  }
  .qu-history .qu-history-title-search-div .qu-history-search-wrap-outer .qu-history-search-wrap .search-input {
    width: 50%;
  }

}

@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .qu-history-title {
    font-size: 30px;
  }
  .qu-history-search-wrap {
    width: 300px;
  }
}


