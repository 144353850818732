.spotify-player-wrapper {
  margin-top: 20px;
  text-align: center;
}

.playerTime {
  color: #6B6B6B;
}

.playLines {
  display: flex;
  align-items: center;
  width: 25%;
  /* Make the playline stretch horizontally */
  padding: 10px;
}

.playLines input[type="range"] {
  flex-grow: 1;
  /* Allow the input range to grow and take up available space */
  margin: 10px;
  /* Add some margin to the right for spacing */
}

.timeDisplay {
  white-space: nowrap;
  /* Prevent text from wrapping */
}



/* Title styles */
.player-title {
  color: #fff;
  font-size: 24px;
  margin-bottom: 10px;
}

/* Spotify player styles */
.spotify-player {
  max-width: 500px;
  margin: 0 auto;
}

/* Loading message styles */
.spotify-player-wrapper p {
  color: #fff;
  font-size: 18px;
}

/* Spotify player customization */
/* Customize the player colors */
.spotify-player iframe {
  background-color: #000;
}

/* Customize the track name and artist color */
.sb-player-track-info {
  color: #ccc !important;
}

/* Customize the slider color */
.sb-player-controls .sb-player-slider .sb-player-seeker {
  background-color: #1db954 !important;
}

/* Customize the active color */
.sb-player-controls button.active,
.sb-player-controls button:hover {
  background-color: #1db954 !important;
  color: #fff !important;
}


/* SpotifyPlayer.css */
.player-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 200px;
  margin-top: 10px;
  /* background-color: #F9B004; */
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  /* padding: 5px; */
}

.iframe {
  border-radius: 10px;
}


.button-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  padding: 0 20px;
}



.prev-button,
.next-button {
  padding: 10px 20px;
  background-color: #F9B004;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;

  transition: background-color 0.3s ease;
}

.prev-button:hover,
.next-button:hover {
  background-color: #fff;
  color: #F9B004;
}


.BackgroundColorContainer_backgroundColorContainer__YZSQ7 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #F9B004;
  z-index: 0;
}

























/* audio player css */


/*----- New CSS Start -----*/
.video-play-top {
  padding: 40px 15px 40px;
}

.video-play-star-home {
  position: absolute;
  top: 50%;
  right: 4%;
  display: flex;
}

.video-play-thumbnail-div {
  display: flex;
  position: relative;
  /* height: 600px; */
  justify-content: center;
  align-items: center;
}

.video-play-thumbnail-div.lokedDiv::after {
  background-color: rgb(193 193 192 / 70%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}

.video-play-thumbnail {
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
  width: 100%;
}

.document_card {
  display: flex;
}

.video-play-layer-image {
  background-color: rgb(193 193 192 / 70%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.add-my-playlist-modal-content .add-my-playlist-modal-content-list-scroll {
  height: 300px;
  overflow-y: auto;

}

.fetch-more-playlist {
  color: #FFB200;
  font-size: medium;
  font-weight: 600;
  cursor: pointer;
  text-align: left;

}

.video-play-lock-div {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  margin-left: 0px;
}

.video-play-like-count {
  display: flex;
}

.video-play-title-div {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.video-play-title-div .video-title {
  font-size: 27px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.video-play-d-flex {
  display: flex;
}

.video-play-jc-sb {
  justify-content: space-between;
}

.video-play-hrs-time {
  font-size: 15px;
  color: #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.video-play-hrs-txt {
  font-size: 15px;
  color: #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 4px;
}

.video-play-dot {
  height: 6px;
  width: 6px;
  background-color: rgb(188, 188, 188);
  margin-left: 10px;
  margin-right: 10px;
  align-self: center;
  border-radius: 10px;
}

.video-play-mt-5 {
  margin-top: 5px;
}

.video-play-mt-30 {
  margin-top: 30px;
}

.video-play-mr-10 {
  margin-right: 10px;
  cursor: pointer;
}

.video-play-m-15 {
  margin: 15px;
}

.video-play-reference-material {
  font-size: 25px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-bottom: 10px;
  text-align: left;
}

.video-play-reference-material-card {
  min-width: 130px;
  height: 74px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FFFCF5;
  box-shadow: 1px 3px 15px 0px rgba(0, 0, 0, 0.15);
  padding: 10px;
}

.reference-material-card-title {
  font-size: 17px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-align: left;
}

.reference-material-card-type {
  font-size: 15px;
  color: #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
  margin-top: 2px;
}

.reference-material-download-type {
  font-size: 17px;
  color: #FFB200;
  text-align: right;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.video-play-suggested-video {
  font-size: 25px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: left;
}

.suggested-video-column-count-3 {
  column-count: 3;
  padding-bottom: 60px;
}

.suggested-video-play-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  /* height: 395px; */
}

.suggested-video-play-inside-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #FFFCF5;
}

.suggested-video-play-inside-wrap .suggested-video-play-list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
}

.suggested-video-play-inside-wrap .suggested-video-img-banner {
  width: 100%;
  height: 270px;
  height: 170px;
  display: flex;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
}

.suggested-video-play-play-icon {
  position: absolute;
  bottom: -29px;
  right: 0px;
  cursor: pointer;
}

.suggested-video-play-card-content-item {
  padding: 15px 10px 10px;
}

.suggested-video-play-video-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.suggested-video-play-video-content img {
  margin-right: 13px;
  cursor: pointer;
}

.suggested-video-play-title-text {
  font-size: 17px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.suggested-video-play-text-hrs {
  font-size: 15px;
  color: #8C8C8C;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.suggested-video-mt-10 {
  margin-top: 10px;
}

.video-play-title-mt-20 {
  margin-top: 20px;
  display: block;
}

.video-play-title-d-flex {
  display: flex;
  padding-bottom: 20px;
}

.video-play-c-pointer {
  cursor: pointer;
}

.video-play-heading-title {
  font-size: 35px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-left: 10px;
}


/*----- Modal Start CSS -----*/
.add-my-playlist-modal-content {
  padding-bottom: 20px;
  min-width: 250px;
}

.add-my-playlist-modal-d-flex {
  display: flex;
}

.add-my-playlist-modal-d-flex-outer {
  overflow: auto;
  max-height: 350px;
}

.modal-mb-5 {
  margin-bottom: 5px;
}

.add-my-playlist-modal-msg-text {
  font-size: 25px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  width: 90%;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.add-my-playlist-modal-close-button {
  color: #FFB200;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  width: 10%;
  text-align: end;
}

.add-my-playlist-modal-close-button:hover,
.add-my-playlist-modal-close-button:focus {
  color: #FFB200;
  text-decoration: none;
  cursor: pointer;
}

.modal-mt-15 {
  margin-top: 15px;
}

input[type="checkbox" i] {
  margin: inherit;
  margin-right: 7px;
  margin-top: 0px;
}

.add-my-playlist-checkbox-text {
  font-size: 17px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.add-my-playlist-create-playlist {
  color: #FFB200;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.add-my-playlist-modal-action {
  display: flex;
  padding: 0px 12px;
  margin-top: 10px;
}

.add-my-playlist-modal-cancel-btn {
  font-size: 17px;
  width: 100px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0px;
  border-color: transparent;
  background-color: transparent;
  color: #FFB200;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-right: 15px;
  cursor: pointer;
}

.add-my-playlist-modal-add-btn {
  font-size: 17px;
  width: 100px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid;
  border-color: #FFB200;
  background-color: #FFB200;
  color: #FFFFFF;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  padding: inherit;
}

/*----- Modal End CSS -----*/

.suggestedVideo {
  margin: 0 -5px;
}

.suggestedVideo .video-play-suggested-video {
  padding: 0 5px;
}

.suggestedVideo .suggested-video-mt-10 {
  justify-content: flex-start;
}

.videoPlayPageOuterMain.video-play-top {
  padding-top: 85px;
  width: 100%;
  box-sizing: border-box;
}


.closeButton {
  background: none;
  border: none;
  color: #000;
  /* color: #FFB200; */
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}

@media (min-width: 992px) {
  .youTubeMusicPlayer .padPlay.myextra-class {
    justify-content: flex-start;
    flex-direction: column;
    /* max-width: 25%; */
    position: relative;
    display: flex;
    border: none;
    box-shadow: none;
    background: transparent;
    /* margin-top: -280px; */
    /* margin-top: -470px; */
    max-width: 31%;
    padding-bottom: 50px;
            position: absolute;
              bottom: 260px;
  }

  .youTubeMusicPlayer .padPlay.myextra-class .padPlayImg {
    display: none;
  }

  .youTubeMusicPlayer .padPlay.myextra-class .details {
    display: none;
  }

  .youTubeMusicPlayer .padPlay.myextra-class .volumeController {
    display: none;
  }

  .youTubeMusicPlayer .padPlay.myextra-class .closeButton {
    display: none;
  }

  .youTubeMusicPlayer .padPlay.myextra-class .playLines {
    width: 85%;
  }

  .youTubeMusicPlayer .padPlay.myextra-class .player .controls .shuffle {
    display: none;
  }

  .youTubeMusicPlayer .padPlay .details .subTitleOuter .info {
    width: 30px;
  }

  .youTubeMusicPlayer .padPlay.myextra-class .bottomSection {
    flex-direction: column;
  }
}


/* player css */
.youTubeMusicPlayer .padPlay {
  box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
  border-top: 1px solid #FFB200;
  display: inline-flex;
  gap: 15px;
  align-items: center;
  position: fixed;
  bottom: 0;
  padding: 12px 45px;
  background: #FFFAF0;
  justify-content: space-between;
  left: 240px;
  width: 100%;
  overflow: auto;
  max-width: calc(100% - 322px);
  opacity: 1;
}

.youTubeMusicPlayer .padPlay.active {
  opacity: 1;
}

.youTubeMusicPlayer .padPlay .padPlayImg {
  width: 86px;
  height: 86px;
}

.youTubeMusicPlayer .padPlay .details {
  /* width: 305px;*/
  width: 215px;
}

.youTubeMusicPlayer .padPlay .details .title {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  color: #212121;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.youTubeMusicPlayer .padPlay .details .subTitleOuter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.youTubeMusicPlayer .padPlay .details .subTitleOuter .subTitle {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  margin: 0;
  color: #6B6B6B;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.youTubeMusicPlayer .padPlay .player {
  display: flex;
  flex-direction: column;
}

.youTubeMusicPlayer .padPlay .player .controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.youTubeMusicPlayer .padPlay .player .controls .padPlayInner {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  box-shadow: 1px 3px 15px 0px #00000026;
}

.youTubeMusicPlayer .padPlay .player .controls .padPlayInner .playIcon {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.youTubeMusicPlayer .padPlay .player .playLine {
  display: flex;
  align-items: center;
  gap: 10px;
}

.youTubeMusicPlayer .padPlay .player .playLine .songTime {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #6B6B6B;
  margin: 0;
}

.youTubeMusicPlayer .padPlay .player .playLine .time {
  position: relative;
  display: flex;
  align-items: center;
}

.youTubeMusicPlayer .padPlay .player .playLine .songRemainingTme {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #6B6B6B;
  margin: 0;
}

.youTubeMusicPlayer .padPlay .player .playLine .time .runningBar {
  position: absolute;
  left: 0;
  width: 150px;
  height: 2px;
}

.youTubeMusicPlayer .padPlay .player .playLine .time .playDot {
  position: absolute;
  left: 150px;
  width: 6px;
  height: 6px;
}

.youTubeMusicPlayer .padPlay .player .playLine .time .playBar {
  width: 100%;
  height: 2px;
}

.youTubeMusicPlayer .padPlay .volumeController {
  display: flex;
  gap: 10px;
  align-items: center;
}

.youTubeMusicPlayer .padPlay .volumeController .time {
  position: relative;
  display: flex;
  align-items: center;
  width: 80px;
}

.youTubeMusicPlayer .padPlay .volumeController .time .runningBar {
  position: absolute;
  left: 0;
  width: 30px;
  height: 2px;
}

.youTubeMusicPlayer .padPlay .volumeController .time .playDot {
  position: absolute;
  left: 30px;
  width: 6px;
  height: 6px;
}

.youTubeMusicPlayer .padPlay .volumeController .time .playBar {
  width: 100%;
  height: 2px;
}

/* player css */

.youTubeMusicPlayer .padPlay .padPlayImg {
  width: 86px;
  height: 86px;
  border-radius: 5px;
}
.youTubeMusicPlayer .padPlay .topSection {
  width: 100%;
  justify-content: flex-start;
  display: flex;
  gap: 15px;
  align-items: center;
}
.youTubeMusicPlayer .padPlay .bottomSection {
  justify-content: space-between;
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: center;
}
.youTubeMusicPlayer .padPlay .details .subTitleOuter .info {
  width: 30px;
}

@media (max-width: 991px) {
  .video-play-title-mt-20 {
    display: none;
  }
  .progressBar {
    display: none;
  }
  .youTubeMusicPlayer .padPlay {
    left: 100px;
    max-width: calc(100% - 190px);
  }

  .youTubeMusicPlayer .padPlay .volumeController {
    display: none;
  }

  .youTubeMusicPlayer .padPlay .details {
    width: 220px;
  }
  .youTubeMusicPlayer .padPlay {
    flex-direction: column;
    left: 100px;
    max-width: calc(100% - 190px);
  }
  .youTubeMusicPlayer .padPlay .topSection {
    width: 100%;
    justify-content: flex-start;
  }
  .youTubeMusicPlayer .padPlay .bottomSection {
    justify-content: center;
    width: 100%;
  }
  .youTubeMusicPlayer .padPlay .details {
    max-width: 450px;
    width: 100%;
  }
  .playLines {
    width: 60%;
  }
  .youTubeMusicPlayer .padPlay .padPlayImg {
    width: 60px;
    height: 60px;
    border-radius: 8px;
  }

}

@media screen and (max-width: 767px) {
  .youTubeMusicPlayer .padPlay .padPlayImg {
    width: 42px;
    height: 42px;
  }
  
  .youTubeMusicPlayer .padPlay .details {
    max-width: 230px;
  }
  .youTubeMusicPlayer .padPlay .bottomSection {
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }
  .youTubeMusicPlayer .padPlay .bottomSection .playLines input[type="range"] {
    width: 40px;
  }

  .youTubeMusicPlayer .padPlay {
    left: 0;
    width: 100%;
    max-width: calc(100% - 14px);
    bottom: 55px;
    padding: 7px;
    gap: 7px;
  }

  .youTubeMusicPlayer .padPlay .padPlayImg {
    width: 42px;
    height: 42px;
    border-radius: 8px;
  }

  .youTubeMusicPlayer .padPlay .details .title {
    font-size: 15px;
    line-height: 22.5px;
    color: #F9B004;
  }

  .youTubeMusicPlayer .padPlay .details {
    /* max-width: 230px; */
  }

  /* .youTubeMusicPlayer .padPlay .details .subTitleOuter .info {
    display: none;
  } */

  .youTubeMusicPlayer .padPlay .player .controls {
    gap: 8px;
  }

  .youTubeMusicPlayer .padPlay .player .controls .prev {
    display: none;
  }

  .youTubeMusicPlayer .padPlay .player .controls .next {
    display: none;
  }

  .youTubeMusicPlayer .padPlay .player .controls .playAll {
    display: none;
  }

  .youTubeMusicPlayer .padPlay .player .controls .padPlayInner {
    width: 24px;
    height: 24px;
  }

  .youTubeMusicPlayer .padPlay .player .controls .padPlayInner .playIcon {
    width: 12px;
    height: 12px;
  }


  .youTubeMusicPlayer .padPlay .player .playLine {
    display: none;
  }

  .controls button {
    width: 30px !important;
    height: 30px !important;
  }

  .controls button svg {
    font-size: 10px;
  }

  .closeButton {
    right: 0px;
    top: 0px;
  }

  .closeButton svg {
    font-size: 24px !important;
  }

  .suggested-video-play-inside-wrap .suggested-video-img-banner {
    /* height: 150px; */
  }

  .suggestedVideo .suggested-video-play-card-wrap .ind-card-img-banner {
    /* height: 150px !important; */
  }

  .video-play-title-mt-20 {
    margin-top: 20px;
    display: none;
  }

 

  .video-play-title-div .video-title {
    font-size: 17px;
    -webkit-line-clamp: 2;
  }

  .video-play-title-div {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .mobile-ref-material {
    padding-left: 15px;
    padding-right: 15px;
  }

  .video-play-top {
    padding: 0px 0px 40px;
  }

  .video-play-star-home {
    position: absolute;
    top: 20%;
    right: 4%;
    display: flex;
  }

  .suggested-video-column-count-3 {
    column-count: 1;
    padding-bottom: 20px;
  }

  .add-my-playlist-modal-content {
    padding-bottom: 20px;
    width: 265px;
  }

  .add-my-playlist-modal-action {
    display: flex;
    padding: 0 7px;
    margin-top: 10px;
  }

  .video-play-hrs-time {
    font-size: 13px;
  }

  .video-play-hrs-txt {
    font-size: 13px;
  }

  .video-play-reference-material {
    font-size: 20px;
  }

  .reference-material-card-title {
    font-size: 15px;
  }

  .reference-material-card-type {
    font-size: 13px;
  }

  .reference-material-download-type {
    font-size: 15px;
  }

  .video-play-suggested-video {
    font-size: 20px;
  }

  .suggestedVideo .custom-col {
    padding-left: 5px;
    padding-right: 5px;
  }

  .add-my-playlist-modal-cancel-btn {
    font-size: 15px;
  }

  .add-my-playlist-modal-add-btn {
    font-size: 15px;
  }

  .add-my-playlist-modal-msg-text {
    font-size: 20px;
  }

  .add-my-playlist-checkbox-text {
    font-size: 15px;
  }

  .suggested-video-play-title-text {
    font-size: 15px;
  }

  .suggested-video-play-text-hrs {
    font-size: 13px;
  }

  .videoPlayPageOuterMain.video-play-top {
    padding-top: 0px;
  }

  .video-div1 {
    width: 100% !important;
  }

  .video-div2 {
    width: 100% !important;
  }

}

/*----- New CSS End -----*/




@media screen and (min-width: 768px) {
  .videoPlayPageOuter {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .videoPlayPageOuter .videoPlayPage {
    padding-right: 50px;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .video-play-title-div .video-title {
    font-size: 23px;
    -webkit-line-clamp: 2;
  }

  .video-play-reference-material {
    font-size: 23px;
  }

  .video-play-suggested-video {
    font-size: 23px;
  }

  .add-my-playlist-modal-msg-text {
    font-size: 23px;
  }

  .videoPlayPageOuterMain.video-play-top {
    padding-top: 85px;
  }

}


/* new css  */






/* .progressBar input[type="range"] {
  
  width: 100%;
  height: 10px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

/* .progressBar input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: yellow;
  cursor: pointer;
}
  */

/* .progressBar input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: yellow;
  cursor: pointer;
}  */

.volumeImg:active {
  transform: scale(0.95);
  background-color: #f0f0f0;
}


.playLine {
  display: flex;
  align-items: center;
  gap: 10px;
}

.songTime,
.songRemainingTme {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #6B6B6B;
  margin: 0;
}

.time {
  position: relative;
  display: flex;
  align-items: center;
  /* width: 100%; */
}

.runningBar {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ddd;
  /* Default background color for running bar */
}

.playDot {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: yellow;
  /* Color for the play dot */
  border-radius: 50%;
  cursor: pointer;
  transition: left 0.1s ease;
}

.playBar {
  width: 100%;
  height: 2px;
  background-color: yellow;
  /* Color for the play bar */
}
.subTitleContainer {
  display: flex;
  justify-content: space-between; /* Distribute space between the two divs */
  align-items: center; /* Vertically align the divs */
  gap: 10px;
}


