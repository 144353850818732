/*----- New CSS Start -----*/
.shared-playlist-outer-wrap {
  padding: 0 15px;
  padding-top: 10px;
  column-count: 3;
}
.sharedPlaylist {
  padding-top: 65px;
}
.shared-playlist-title-mt-20 {
  margin-top: 20px;
  display: contents;
}

.shared-playlist-d-flex {
  display: flex;
  cursor: pointer;
}

.shared-playlist-c-pointer {
  cursor: pointer;
}

.shared-playlist-title {
  color: #212121;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-left: 10px;
}

.shared-playlist-title-search-div {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding: 0 15px;
}

.shared-playlist-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  /* height: 390px; */
}

.shared-playlist-inside-wrap {
  display: block;
  width: 100%;
  background-color: #FFFCF5;
}

.shared-playlist-inside-wrap .list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
}

.shared-playlist-inside-wrap .img-banner {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  display: flex;
}

.shared-playlist-play-icon {
  position: absolute;
  bottom: -32px;
  right: 0px;
}

.shared-playlist-content-item {
  padding: 10px;
  width: 95%;
}

.shared-playlist-video-content {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.shared-playlist-video-content img {
  padding-right: 10px;
}

.shared-playlist-title-text {
  font-size: 17px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.shared-playlist-text-hrs {
  color: #8C8C8C;
  font-size: 13px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.shared-playlist-like-icon-div {
  text-align: left;
  padding-top: 10px;
}

.shared-playlist-c-pointer {
  cursor: pointer;
}

/*----- Search Bar Start CSS -----*/
.shared-playlist-search-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 480px;
}

.search-input {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.search-input input {
  font-size: 17px;
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  width: 100%;
}

.filter-icon {
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}

/*----- Search Bar End CSS -----*/


/*----- Modal Start CSS -----*/
.shared-playlist-modal-msg-content {
  display: flex;
  padding-bottom: 20px;
}

.shared-playlist-modal-msg-text {
  color: #212121;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  width: 90%;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.shared-playlist-modal-close-button {
  color: #FFB200;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  width: 10%;
  text-align: end;
}

.shared-playlist-modal-close-button:hover,
.shared-playlist-modal-close-button:focus {
  color: #FFB200;
  text-decoration: none;
  cursor: pointer;
}

.shared-playlist-modal-action-content {
  display: flex;
  padding: 12px;
  margin-top: 10px;
}

.shared-playlist-modal-yes-btn {
  width: 80px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0px;
  border-color: transparent;
  background-color: transparent;
  color: #FFB200;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.shared-playlist-modal-dont-delete-btn {
  width: 141px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid;
  border-color: #FFB200;
  background-color: #FFB200;
  color: #FFFFFF;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

/*----- Modal End CSS -----*/


@media screen and (max-width: 767px) {
  .shared-playlist-outer-wrap {
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    column-count: 1;
  }
  .sharedPlaylist .my-favorites-tab-div {
    padding: 0 15px;
  }

  .shared-playlist-card-wrap {
    box-shadow: 1px 3px 15px 0px #00000026;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
    height: inherit;
  }

  /* .shared-playlist-inside-wrap {
    display: flex;
    width: 100%;
    background-color: #FFFCF5;
  }

  .shared-playlist-inside-wrap .list-image {
    max-width: 30%;
    width: 100%;
    position: relative;
    flex-shrink: 0;
  } */

  .shared-playlist-inside-wrap .img-banner {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .shared-playlist-play-icon {
    position: absolute;
    bottom: -12px;
    right: -30px;
  }

  .shared-playlist-like-icon-div {
    text-align: right;
  }

  .shared-playlist-modal-msg-content {
    display: flex;
    padding-bottom: 20px;
  }

  .shared-playlist-modal-action-content {
    display: flex;
    padding: 7px;
    margin-top: 10px;
  }

  .shared-playlist-title-search-div {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .shared-playlist-search-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 345px;
  }

  .shared-playlist-title-mt-20 {
    margin-top: 20px;
    display: none;
  }
  .search-input input {
    font-size: 15px;
  }
  .sharedPlaylist {
    padding-top: 55px;
    padding-bottom: 20px;
  }




  
}

/*----- New CSS End -----*/



@media screen and (min-width: 768px) and (max-width: 991px) {
  .sharedPlaylist .shared-playlist-search-wrap {
    width: 50%;
    justify-content: flex-end;
    margin-bottom: 0px;
    align-items: center;
    float: right;
  }
  .shared-playlist-title-search-div {
    display: block;
    padding-bottom: 60px;
  }

}

@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .shared-playlist-title {
    font-size: 30px;
  }
  .shared-playlist-search-wrap {
    width: 300px;
  }
}
