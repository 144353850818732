.aboutUs-page {
  padding: 0px 15px;
  padding-bottom: 50px;
  padding-top: 65px;
}

.aboutUs-page .mainTitle {
  font-family: Poppins;
  font-size: 35px;
  font-weight: 400;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  margin-bottom: 0px;
}

.aboutUs-page .aboutUs-img-div {
  margin-bottom:15px;
  text-align: center;
}

.aboutUs-page .aboutUs-img-div .AbousImage {
  width:100%;
  /* max-width: 600px; */
  margin: 15px auto;
  border-radius: 10px;
}

.aboutUs-page .first {
  /* box-shadow: 1px 3px 25px 0px #f2ac0a1a inset;
  border: 1px solid #eeaa0c;
  border-radius: 10px;
  padding: 30px 20px; */
}

.aboutUs-page .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  /* margin: 30px 15px; */
  padding: 15px;
  background: #fffcf5;
}

.aboutUs-page .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
   
}

.aboutUs-page .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

@media (max-width: 768px) {

  .aboutUs-page .aboutUs-img-div{
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .firstOuter {
    /* box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
    border: 1px solid #EEAA0C;
    border-radius: 10px;
    padding: 30px 40px; */
    margin-top: 35px;
  }
}

@media (max-width: 767px) {
  .aboutUs-page .mainTitle {
    display: none;
  }
  .aboutUs-page .first .about-us-desc .description {
    font-size: 15px;
  }
  .aboutUs-page {
    padding-top: 55px;
  }


}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .aboutUs-page .mainTitle {
    font-size: 33px;
  }

}