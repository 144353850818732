.faqs {
  padding: 10px 15px;
  padding-top: 70px;
}

.faqs .heading {
  font-size: 25px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: #212121;
  padding-top: 20px;
}

.faqs .accordian {
  padding-top: 10px;
  width: 80%;
}

.faqs .mainTitle {
  font-size: 35px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  display: none;
  margin: 0;
}

.faqs .accordian .accordian-element {
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  border-radius: 10px;
  margin-bottom: 15px;
}

.faqs .accordian .accordian-element .accordian-title p {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: #212121;
  margin: 0;
  padding: 10px;
}

.faqs .accordian .accordian-element .accordian-content {
  border-top: 1px solid #DBC48E;
}

.faqs .accordian .accordian-element .accordian-content p {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  padding: 15px 15px;
  margin: 0;
}

.faqs .accordian .accordian-element .accordian-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faqs .accordian .accordian-element .accordian-title svg.MuiSvgIcon-root {
  fill: #EEAA0C;
  padding-right: 10px;
}

/*----- Search Bar Start CSS -----*/
.faqs-search-box-mobile {
  display: contents;
}

.faqs-search-wrap {
  display: flex;
  justify-content: center;
  margin-left: auto;
  width: 100%;
  margin-right: auto;
}

.search-input {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.search-input input {
  font-size: 17px;
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  width: 100%;
}

.filter-icon {
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}

.language select {
  font-size: 17px;
  box-shadow: none;
  background: #FFFCF5;
  border: none;
  border-radius: 10px;
  padding: 11px 30px 11px 10px;
  width: 100%;
  margin: 0;
  font-family: Poppins;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #EEAA0C;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../../../assets/Phase2/arrowDownOrange.svg") 90% / 15px no-repeat;

}

/*----- Search Bar End CSS -----*/
.faqs-search-box-mobile .language {
  display: none;
}

@media (max-width: 767px) {
  .search-input input {
    font-size: 15px;
  }
  .faqs .heading {
    font-size: 20px;
  }
  .faqs .accordian .accordian-element .accordian-title p {
    font-size: 15px;
  }
  .faqs .accordian .accordian-element .accordian-content p {
    font-size: 15px;
  }
  .language select {
    font-size: 15px;
  }
  .faqs .accordian {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .faqs-search-box-mobile .language {
    display: block;
  }
  .faqs .mainTitle {
    display: block;
  }
  .faqs {
    padding: 105px 15px 10px 15px;
  }
  .faqs-search-box-mobile .faqs-search-wrap {
    float: right;
  }
  .faqs .heading {
    padding-bottom: 30px;
  }
  .faqs-search-wrap {
    width: 250px;
  }
  .faqs-search-box-mobile-outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } 
  .faqs-search-box-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 991px) {
  .faqs-search-wrap {
    width: 335px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .faqs .mainTitle {
    font-size: 33px;
  }
  .faqs-search-box-mobile .faqs-search-wrap {
    justify-content: flex-end;
  }
  .faqs .heading {
    font-size: 23px;
  }
  .faqs .accordian {
    width: 100%;
  }

}