/*----- New CSS Start -----*/
.watch-later-play-outer-wrap {
  padding: 10px;
  padding-top: 85px;
}

.watch-later-play-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  /* height: 395px; */
}

.watch-later-play-inside-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #FFFCF5;
}

.watch-later-play-inside-wrap .watch-later-play-list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  background: #000000ba;
}

.watch-later-play-inside-wrap .watch-later-img-banner {
  width: 100%;
  height: 270px;
  height: 170px;
  display: flex;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
}

.watch-later-play-play-icon {
  position: absolute;
  bottom: -29px;
  right: 0px;
  cursor: pointer;
}

.watch-later-play-card-content-item {
  padding: 15px 10px 10px;
}

.watch-later-play-video-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.watch-later-play-video-content img {
  margin-right: 13px;
  cursor: pointer;
}

.watch-later-mt-10 {
  margin-top: 10px;
}

.watch-later-play-title-text {
  font-size: 15px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.watch-later-play-text-hrs {
  color: #8C8C8C;
  font-size: 13px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.search-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
}
.searchIcon-outer {
  display: none;
}

.search-input {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.search-input input {
  font-size: 17px;
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  width: 100%;
}

.filter-icon {
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}

.watch-later-column-count-3 {
  column-count: 3;
  padding-bottom: 60px;
}

.watch-later-heading-div {
  margin-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  display: block;
}

.watch-later-d-flex {
  display: flex;
}

.watch-later-c-pointer {
  cursor: pointer;
}

.watch-later-title {
  color: #212121;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-left: 10px;
}

.watch-later-tab-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 5px;
}

.watch-later-tab-a-center {
  align-self: center;
}


/* List View CSS */
.watch-later-play-outer-wrap .custom-row.listView {
  flex-direction: column;
}
.watch-later-play-outer-wrap .custom-row.listView .custom-col-4 {
  width: 100%;
}
.watch-later-play-outer-wrap .custom-row.listView .watch-later-play-inside-wrap {
  flex-direction: row;
}
.watch-later-play-outer-wrap .custom-row.listView .watch-later-play-list-image {
  width: 100px;
}
.watch-later-play-outer-wrap .custom-row.listView .watch-later-play-play-icon {
  bottom: 0;
  right: -15px;
  width: 40px;
}
.watch-later-play-outer-wrap .custom-row.listView .watch-later-play-card-content-item {
    width: 100%;
}
.watch-later-play-outer-wrap .custom-row.listView .watch-later-play-video-content {
    flex-direction: row;
}
.watch-later-play-outer-wrap .custom-row.listView .watch-later-mt-10 {
    margin: 0;
}






/* List View CSS */


@media screen and (max-width: 991px) {
  .watch-later-column-count-3 {
    column-count: 2;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .watch-later-play-inside-wrap .watch-later-img-banner {
    /* height: 150px; */
  }
  .watch-later-play-outer-wrap .custom-row {
    padding: 0 10px;
  }
  .watch-later-column-count-3 {
    column-count: 1;
    padding-bottom: 20px;
  }
  .watch-later-play-list-image .watch-later-img-banner {
    /* height: 150px !important; */
  }

  .watch-later-tab-div {
    display: block;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 10px;
  }

  .watch-later-heading-div {
    margin-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    display: none;
  }

  .watch-later-play-outer-wrap {
    padding: 5px;
    padding-top: 50px;
  }
  .search-input input {
    font-size: 15px;
  }

}

/*----- New CSS End -----*/

@media screen and (min-width: 768px) and (max-width: 991px) {
  /* .search-input {
    display: none;
    width: 0;
  } */
  /* .searchIcon-outer {
    display: block;
  } */
  .search-wrap {
    justify-content: flex-end;
  }

}
