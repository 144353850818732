.allBhaktiPads-page {
  padding: 0px 15px;
  padding-bottom: 50px;
  padding-top: 105px;
  position: relative;
}

.allBhaktiPads-page .allBhaktiPads-page-title-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}

.allBhaktiPads-page .allBhaktiPads-page-title-outer .allBhaktiPads-page-title {
  /* margin-top: 20px; */
}

.allBhaktiPads-page .allBhaktiPads-page-title-outer .allBhaktiPads-page-title .my-favorites-d-flex {
  padding: 0;
}


/* three dot css start*/
.popupOuter {
  position: relative;
  z-index: 1;
}

.allBhaktiPads-page .morePopup {
  position: absolute;
  right: 20px;
  top: 50px;
  background: #FDFDFD;
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 4px 10px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 12px 15px;
  z-index: 9;
}

.allBhaktiPads-page .table-responsive .table tbody tr:nth-last-child(1) .morePopup {
  top: -5px;
}

.allBhaktiPads-page .morePopup .outer {
  display: flex;
  gap: 10px;
  align-items: center;
  width: max-content;
}

.allBhaktiPads-page .morePopup .outer .label {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8c8c8c;
  margin: 0;
}

/* three dot css END*/



.allBhaktiPads-page .top {
  margin-top: 43px;
  box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
  border: 1px solid #EEAA0C;
  border-radius: 10px;
  padding: 22px;
  display: flex;
  align-items: center;
  gap: 22px;
}

.allBhaktiPads-page .top .topImg {
  width: 131px;
  height: 131px;
  border-radius: 8px;
}

.allBhaktiPads-page .top .label {
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  color: #212121;
  margin: 0;
  text-transform: uppercase;
  font-family: Poppins;
}

.allBhaktiPads-page .mainTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
}

.allBhaktiPads-page .mainTitleOuter .viewMore {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}

.allBhaktiPads-page .mainTitleOuter .mainTitle {
  letter-spacing: 0em;
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
  margin: 0;
}

.allBhaktiPads-page .aboutUs-img-div {
  margin-bottom: 15px;
  text-align: center;
}

.allBhaktiPads-page .aboutUs-img-div .AbousImage {
  width: 100%;
  margin: 15px auto;
  border-radius: 10px;
}

.allBhaktiPads-page .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  padding: 15px;
  background: #fffcf5;
}

.allBhaktiPads-page .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;

}

.allBhaktiPads-page .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.table-responsive {
  overflow: auto;
}


/* Table css */
.allBhaktiPads-page .table-responsive .table th,
.allBhaktiPads-page .table-responsive .table td {
  border: none;
}

.allBhaktiPads-page .table-responsive .table th:nth-of-type(1) {
  padding-left: 0;
}

.allBhaktiPads-page .table-responsive .table td:nth-of-type(1) {
  padding-left: 0;
}

.allBhaktiPads-page .table-responsive .table td:nth-last-child(1) {
  padding-right: 0;
}

.allBhaktiPads-page .table-responsive .table th {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
}

.allBhaktiPads-page .table-responsive .table th {
  border-bottom: 1px solid #DBC48E;
}

.allBhaktiPads-page .table-responsive .table .srNo {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  margin: 0;
  text-align: left;
}

.allBhaktiPads-page .table-responsive .table .srNo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.allBhaktiPads-page .table-responsive .table .srNo .no {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  color: #212121;
}

.allBhaktiPads-page .table-responsive .table .srNo .bhaktiImg {
  width: 60px;
  height: 60px;
  border-radius: 8px;
}

.allBhaktiPads-page .table-responsive .table .titleSection .title {
  margin: 0;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  color: #212121;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
  min-width: 400px;
}

.allBhaktiPads-page .table-responsive .table .titleSection .subTitle {
  margin: 0;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  color: #6B6B6B;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.allBhaktiPads-page .table-responsive .table .album {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  margin: 0;
  color: #6B6B6B;
}

.allBhaktiPads-page .table-responsive .table .duration {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8C8C8C;
  margin: 0;
}

.allBhaktiPads-page .table-responsive .table .actions {
  display: flex;
  gap: 15px;
  align-items: center;
  position: relative;
}


.allBhaktiPads-page .top .label {
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  color: #212121;
  margin: 0;
  text-transform: uppercase;
  font-family: Poppins;
}

.allBhaktiPads-page .mainTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
}

.allBhaktiPads-page .mainTitleOuter .viewMore {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
}

.allBhaktiPads-page .mainTitleOuter .mainTitle {
  letter-spacing: 0em;
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
  margin: 0;
}

.allBhaktiPads-page .aboutUs-img-div {
  margin-bottom: 15px;
  text-align: center;
}

.allBhaktiPads-page .aboutUs-img-div .AbousImage {
  width: 100%;
  margin: 15px auto;
  border-radius: 10px;
}

.allBhaktiPads-page .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  padding: 15px;
  background: #fffcf5;
}

.allBhaktiPads-page .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;

}

.allBhaktiPads-page .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.table-responsive {
  overflow: auto;
}

/* Table css */

/* player css */



.table-responsive {
  overflow: auto;
}

.allBhaktiPads-page .tableHover:hover {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
}


.srNo {
  position: relative;
}


/* Show the play button on row hover */

.allBhaktiPads-page .table-responsive .table .srNo .no {
  width: 30px;
}

/* player css */

.allBhaktiPads-page .controlsTop {
  display: none;
  align-items: center;
  gap: 12px;
  padding-bottom: 20px;
  padding-top: 15px;
}

.allBhaktiPads-page .controlsTop .playIcon {
  width: 36px;
  height: 36px;
}

.allBhaktiPads-page .controlsTop .moreImg {
  transform: rotate(90deg);
}


@media (max-width: 991px) {
  .allBhaktiPads-page .table-responsive .table .titleSection .title {
    min-width: auto;
  }

  .allBhaktiPads-page .top .label {
    font-size: 32px;
  }

  .allBhaktiPads-page .mainTitleOuter .mainTitle {
    font-size: 23px;
    line-height: 34.5px;
  }

  .allBhaktiPads-page .table-responsive .table th.none {
    display: none;
  }

  .allBhaktiPads-page .table-responsive .table td.none {
    display: none;
  }

  .allBhaktiPads-page .table-responsive .table .titleSection {
    max-width: 290px;
  }

  .allBhaktiPads-page .mainTitleOuter .mainTitle {
    font-size: 23px;
    line-height: 34.5px;
  }

  .youTubeMusicPlayer .padPlay {
    left: 100px;
    max-width: calc(100% - 190px);
  }

  .youTubeMusicPlayer .padPlay .volumeController {
    display: none;
  }

  .youTubeMusicPlayer .padPlay .details {
    width: 220px;
  }

  .allBhaktiPads-page .allBhaktiPads-page-title-outer .list-of-play-search .search-input {
    display: none;
  }

  .allBhaktiPads-page .allBhaktiPads-page-title-outer .list-of-play-search .searchIcon-outer.searchTooltip {
    display: block;
  }

  .allBhaktiPads-page .morePopup {
    display: none;
  }

}


@media (max-width: 767px) {
  .allBhaktiPads-page {
    padding-top: 50px;
  }

  .allBhaktiPads-page .allBhaktiPads-page-title-outer {
    display: inline;
  }

  .allBhaktiPads-page .allBhaktiPads-page-title-outer .allBhaktiPads-page-title {
    display: none;
  }

  .allBhaktiPads-page .top .label {
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }

  .allBhaktiPads-page .mainTitleOuter .mainTitle {
    font-size: 18px;
    line-height: 27px;
  }

  .allBhaktiPads-page .mainTitleOuter .viewMore {
    height: auto;
    padding: 0;
    font-size: 13px;
    line-height: 19.5px;
  }

  .allBhaktiPads-page .mainTitleOuter {
    display: none;
  }

  /* .mobHide {
    display: none;
  } */


  .allBhaktiPads-page .table-responsive .table .actions .more {
    transform: rotate(90deg);
  }

  .allBhaktiPads-page .table-responsive .table .srNo .bhaktiImg {
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }

  .allBhaktiPads-page .table-responsive .table .titleSection .title {
    font-size: 15px;
    line-height: 22.5px;
    max-width: 150px;
  }

  .allBhaktiPads-page .table-responsive .table .titleSection .subTitle {
    font-size: 13px;
    line-height: 19.5px;
  }

  .allBhaktiPads-page .table-responsive .table td {
    padding: 10px;
  }

  .allBhaktiPads-page .table-responsive .table .titleSection {
    max-width: 210px;
  }

  .allBhaktiPads-page .allBhaktiPads-page-title-outer .list-of-play-search .search-input {
    display: block;
  }

  .allBhaktiPads-page .allBhaktiPads-page-title-outer .list-of-play-search .searchIcon-outer.searchTooltip {
    display: none;
  }

  .allBhaktiPads-page .controlsTop {
    display: flex;
  }

  .allBhaktiPads-page .table-responsive .table .actions .favTooltip {
    display: none;
  }

}

@media (min-width: 768px) {
  .allBhaktiPads-page .padPlay .player .controls .plus {
    display: none;
  }
}


.iframe {
  border-radius: 12px
}
