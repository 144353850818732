/*----- New CSS Start -----*/
.myCreatedPlaylistVideo {
  padding-top: 65px;
}
.create-playlist-video {
  padding-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.create-playlist-video .create-playlist-video-top-div, 
.create-playlist-video .add-any-video-div, 
.create-playlist-video .suggested-video-div .suggested-video-title {
  padding: 0 5px;
}
.create-playlist-video-top-div {
  position: relative;
}

.create-playlist-video-top-left {
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

.create-playlist-video-top-right {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.add-any-video-div {
  text-align: center;
  margin-top: 20px;
}

.add-any-video-txt {
  font-size: 23px;
  color: #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.add-video-btn {
  font-size: 17px;
  width: 140px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid;
  border-color: #FFB200;
  background-color: #FFB200;
  color: #FFFFFF;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 10px;
}

.suggested-video-title {
  font-size: 25px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: left;
}

.suggested-video-column-count-3 {
  column-count: 3;
  padding-bottom: 60px;
}

.suggested-video-play-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  /* height: 395px; */
}

.suggested-video-play-inside-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #FFFCF5;
}

.suggested-video-play-inside-wrap .suggested-video-play-list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  background: #000000ba;
}

.suggested-video-play-inside-wrap .suggested-video-img-banner {
  width: 100%;
  height: 270px;
  height: 170px;
  display: flex;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: scale-down;
}

.suggested-video-play-play-icon {
  position: absolute;
  bottom: -29px;
  right: 0px;
  cursor: pointer;
}

.suggested-video-play-card-content-item {
  padding: 15px 10px 10px;
}

.suggested-video-play-video-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.suggested-video-play-video-content img {
  margin-right: 13px;
  cursor: pointer;
}

.suggested-video-play-title-text {
  font-size: 17px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.suggested-video-play-text-hrs {
  font-size: 15px;
  color: #8C8C8C;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.suggested-video-mt-10 {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.suggested-video-add-btn {
  font-size: 17px;
  color: #F6AE06;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  text-transform: uppercase;
  cursor:pointer;
}
.myCreatePlayListVideoAddBtnSuggested .suggested-video-mt-10 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MyCreatePlayListSelectVideo .my-created-playlist-title-search-div {
  display: flex;
}



@media screen and (max-width: 767px) {
  .suggested-video-play-inside-wrap .suggested-video-img-banner {
    /* height: 150px; */
  }
  .create-playlist-video {
    padding-top: inherit;
    padding-left: inherit;
    padding-right: inherit;
  }
  .myCreatedPlaylistVideo .create-playlist-video .suggested-video-play-inside-wrap .ind-card-img-banner {
    height: 150px !important;
  }

  .suggested-video-div {
    padding-left: 15px;
    padding-right: 15px;
  }
  .create-playlist-video-top-div img {
    height: auto !important;
  }
  .suggested-video-column-count-3 {
    column-count: 1;
    padding-bottom: 30px;
  }
  .add-any-video-txt {
    font-size: 15px;
  }
  .add-video-btn {
    font-size: 15px;
  }
  .suggested-video-title {
    font-size: 20px;
  }
  .suggested-video-play-title-text {
    font-size: 15px;
  }
  .suggested-video-play-text-hrs {
    font-size: 13px;
  }
  .suggested-video-add-btn {
    font-size: 15px;
  }
  .create-playlist-video .suggested-video-div .custom-col-4.custom-col {
    margin-bottom: 0;  
  }
  .myCreatedPlaylistVideo {
    padding-top: 0;
  }
  .create-playlist-video .create-playlist-video-top-div, 
  .create-playlist-video .add-any-video-div, 
  .create-playlist-video .suggested-video-div .suggested-video-title {
    padding: 0;
  }


  
}

/*----- New CSS End -----*/

@media screen and (min-width: 768px) and (max-width: 991px) {
  .add-any-video-txt {
    font-size: 17px;
  }
  .suggested-video-title {
    font-size: 23px;
  }
  .MyCreatePlayListSelectVideo .my-created-playlist-title-search-div .my-created-playlist-search-wrap-outer .my-created-playlist-search-wrap {
    justify-content: flex-end;
    align-items: center;
  }

}