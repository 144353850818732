/*----- New CSS Start -----*/
.download-ebook-col-count-3 {
  padding: 0 15px;
  padding-top: 10px;
  column-count: 3;
}
.downloadEBook {
  padding-top: 105px;
}
.download-ebook-title-mt-20 {
  margin-top: 20px;
  display: contents;
}

.download-ebook-d-flex {
  display: flex;
  cursor: pointer;
  width: fit-content;
}

.download-ebook-title {
  font-size: 35px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-left: 10px;
}

.download-ebook-title-search-div {
  display: flex;
  justify-content: space-between;
  /* margin-top: 40px; */
  padding: 0 15px;
  align-items: center;
  margin-bottom: 20px;
}

.download-ebook-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  /* height: 390px; */
}

.download-ebook-inside-wrap {
  display: block;
  width: 100%;
  background-color: #FFFCF5;
}
.downloadEBook .download-ebook-col-count-3.custom-row {
  padding: 0 10px;
}
.download-ebook-inside-wrap .download-ebook-list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
}

.download-ebook-inside-wrap .download-ebook-img-banner {
  width: 100%;
  height: 270px;
  height: 170px;
  display: flex;
  cursor: pointer;
  height: 100%;
  aspect-ratio: 9/14;
  object-fit: unset;
}

.download-ebook-play-icon {
  position: absolute;
  bottom: -32px;
  right: 0px;
}

.download-ebook-card-content-item {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.download-ebook-video-content {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.download-ebook-video-content img {
  margin-right: 10px;
  cursor: pointer;
}

.download-ebook-title-text {
  font-size: 17px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.download-ebook-text-hrs {
  font-size: 15px;
  color: #8C8C8C;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

.download-ebook-like-icon-div {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.download-ebook-read {
  font-size: 17px;
  color: #FFB200;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
  cursor: pointer;
  /* display: none; */
}


/*----- Search Bar Start CSS -----*/
.download-ebook-search-wrap {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  width: 480px;
}

.search-input {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.search-input input {
  font-size: 17px;
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  width: 100%;
}

.filter-icon {
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}

/*----- Search Bar End CSS -----*/


/*----- Modal Start CSS -----*/
.download-ebook-modal-content {
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
}

.download-ebook-modal-msg-text {
  color: #212121;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  width: 90%;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.download-ebook-modal-close-button {
  color: #FFB200;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  height: fit-content;
  text-align: end;
}

.download-ebook-modal-close-button:hover,
.download-ebook-modal-close-button:focus {
  color: #FFB200;
  text-decoration: none;
  cursor: pointer;
}

.download-ebook-modal-action-content {
  display: flex;
  padding: 12px;
  margin-top: 10px;
  align-items: center;
}

.download-ebook-modal-dont-delete-btn {
  width: 141px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid;
  border-color: #FFB200;
  background-color: #FFB200;
  color: #FFFFFF;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.download-ebook-modal-yes-btn {
  /* width: 80px; */
  margin-right: 20px;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0px;
  border-color: transparent;
  background-color: transparent;
  color: #FFB200;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

/* .download-ebook-modal-yes-btn:hover {
  background-color: transparent;
} */

/*----- Modal End CSS -----*/
.downloadEBook .download-ebook-col-count-3 .custom-col-4 {
  width: 20%;
}



@media (max-width: 991px) {
  .download-ebook-col-count-3 {
    column-count: 2;
  }
  .downloadEBook .download-ebook-col-count-3 .custom-col-4 {
    width: 33.33%;
  }
}

@media screen and (max-width: 767px) {
  .download-ebook-col-count-3 {
    column-count: 1;
  }
  .downloadEBook .download-ebook-col-count-3 .custom-col-4 {
    width: 100%;
  }

  .download-ebook-card-wrap {
    box-shadow: 1px 3px 15px 0px #00000026;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
    height: inherit;
  }

  .download-ebook-inside-wrap {
    display: flex;
    width: 100%;
    background-color: #FFFCF5;
  }

  .download-ebook-inside-wrap .download-ebook-list-image {
    max-width: 30%;
    width: 100%;
    position: relative;
    flex-shrink: 0;
  }

  .download-ebook-inside-wrap .download-ebook-img-banner {
    width: 100%;
    height: 100%;
    /* height: 120px; */
    display: flex;
  }

  .download-ebook-play-icon {
    position: absolute;
    bottom: -12px;
    right: -30px;
  }

  .download-ebook-title-search-div {
    display: flex;
    justify-content: center;
    margin: 0;
    /* margin-top: 20px; */
  }

  .download-ebook-search-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 345px;
  }

  .download-ebook-title-mt-20 {
    margin-top: 20px;
    display: none;
  }

  /*----- Mobile Modal Start -----*/
  .download-ebook-modal-content {
    display: flex;
    padding-bottom: 20px;
  }

  .download-ebook-modal-action-content {
    display: flex;
    padding: 7px;
    margin-top: 10px;
  }
  .search-input input {
    font-size: 15px;
  }
  .download-ebook-title-text {
    font-size: 15px;
    -webkit-line-clamp: 2;
    height: 40px;
  }
  .download-ebook-read {
    font-size: 15px;
  }
  .download-ebook-text-hrs {
    font-size: 13px;
  }
  .downloadEBook .custom-col-4.custom-col {
    margin-bottom: 0;
  }
  /*----- Mobile Modal End -----*/
  .downloadEBook {
    padding-top: 75px;
  }



  
}

/*----- New CSS End -----*/

@media screen and (min-width: 768px) and (max-width: 991px) {
  .download-ebook-title-text {
    -webkit-line-clamp: 2;
    height: 40px;
  }
  .download-ebook-search-wrap {
    float: right;
    margin: 20px 0px;
    width: 50%;
  }
  .download-ebook-title {
    font-size: 33px;
  }

  .download-ebook-title-search-div {
    display: block;
  }



}

@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .download-ebook-title {
    font-size: 30px;
  }
  .download-ebook-search-wrap {
    max-width: 300px;
  }
  .downloadEBook .download-ebook-col-count-3 .custom-col-4 {
    width: 33.33%;
  }
}


