/*----- Individual Top Banner Start CSS -----*/
.ind-list-main-banner {
  position: relative;
}
.individualPlaylist {
  padding-top: 65px;
}
.ind-list-main-banner::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  display: block;
  background: #0000009e;
  content: '';
  border-radius: 27px;
}

.ind-list-top-banner {
  height: 320px;
  margin-top: 40px;
  margin-left: 15px;
  margin-right: 15px;
}

.ind-list-top-img-banner {
  width: 100%;
  height: 320px;
}

.ind-list-b-radius-27 {
  border-radius: 27px;
}

.ind-list-top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 15px;
  z-index: 1;
}

.ind-list-pl-15 {
  margin-left: 15px;
}

.ind-list-banner-content {
  position: absolute;
  bottom: 40px;
  right: 0;
  left: 0;
  padding: 15px;
  z-index: 1;
}

.ind-list-banner-title {
  font-size: 27px;
  text-align: left;
  color: white;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0;
  text-transform: capitalize;
}

.ind-list-banner-text {
  display: flex;
  align-items: end;
  margin-top: 0;
}

.individual-truncate {
  font-size: 15px;
  width: 100%;
  color: #B1AFAB;
  line-height: 19.5px;
  overflow: auto;
  /* text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box; */
  text-align: left;
  /* overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
  max-height: 60px;
}

.individualPlaylist .ind-list-top-banner {
  height: 400px;
  height: auto;
  width: 100%;
}
.individualPlaylist .ind-list-top-banner .ind-list-top-img-banner {
  height: 400px;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
}

.read-more {
  display: inline-block;
  vertical-align: top;
  margin-top: 1px;
  margin-left: 5px;
  margin-bottom: 0;
  color: #F6AE06;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  width: 95px;
  cursor: pointer;
}

.ind-list-get-referal {
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
  padding: 15px;
  z-index: 1;
}

.ind-list-yellow-text {
  margin-bottom: 0;
  color: #F6AE06;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.ind-list-get-referal {
  color: white;
  font-size: 17px;
}

.ind-list-play-icon-img {
  position: absolute;
  bottom: -25px;
  right: 5px;
  z-index: 1;
  cursor: pointer;
}

/*----- Individual Top Banner End CSS -----*/

/*----- Search Bar Start CSS -----*/
.ind-list-play-outer-wrap {
  padding: 10px;
}

.search-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 433px;
  align-items: center;
}
.searchIcon-outer {
  display: none;
}

.search-input {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.search-input input {
  font-size: 17px;
  height: 46px;
  line-height: 46px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  color: #B1AFAB;
  padding-left: 50px;
  width: 100%;
}

.filter-icon {
  width: 42px;
  align-items: center;
  justify-content: end;
  display: flex;
  margin-right: 5px;
}

/*----- Search Bar End CSS -----*/

/*----- Individual Play List Card Start CSS -----*/
.ind-card-column-count-3 {
  column-count: 3;
  padding-bottom: 60px;
}

.ind-card-play-card-wrap {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  overflow: hidden;
  /* height: 395px; */
}

.ind-card-play-inside-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #FFFCF5;
}

.ind-card-play-inside-wrap .ind-card-play-list-image {
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-shrink: 0;
}
.ind-card-play-inside-wrap .ind-card-play-list-image img {
  cursor: pointer;
}

.ind-card-play-inside-wrap .ind-card-img-banner {
  width: 100%;
  height: 270px;
  height: 170px;
  display: flex;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
}

.ind-card-play-play-icon {
  position: absolute;
  bottom: -29px;
  right: 0px;
}

.ind-card-play-card-content-item {
  padding: 15px 10px 10px;
}

.ind-card-play-video-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.ind-card-play-video-content img {
  margin-right: 13px;
  cursor: pointer;
}

.ind-card-mt-10 {
  margin-top: 10px;
}

.ind-card-play-title-text {
  font-size: 17px;
  color: #212121;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 19.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.ind-card-play-text-hrs {
  font-size: 15px;
  color: #8C8C8C;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 19.5px;
  height: 20px;
}

/*----- Individual Play List Card End CSS -----*/

/*----- Reference Material Modal Start CSS -----*/
.ref-material-modal-content {
  display: flex;
  padding-bottom: 20px;
}

.ref-material-modal-msg-text {
  font-size: 25px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  width: 90%;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.ref-material-modal-close-button {
  color: #FFB200;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  width: 10%;
  text-align: end;
}

.ref-material-modal-close-button:hover,
.ref-material-modal-close-button:focus {
  color: #FFB200;
  text-decoration: none;
  cursor: pointer;
}

.ref-material-position-rel {
  position: relative;
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 20px;
}

.ref-material-img {
  width: 400px;
  margin-left: -10px;
  margin-right: -12px;
  display: none;
}

.ref-material-top-left {
  /* position: absolute; */
  top: 24px;
  left: 15px;
  display: flex;
}

.ref-material-left-title {
  font-size: 20px;
  color: #212121;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.ref-material-left-title-type {
  font-size: 15px;
  color: #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
  margin-left: 5px;
  margin-block-start: inherit;
  margin-block-end: inherit;
}

.ref-material-top-right {
  /* position: absolute; */
  top: 27px;
  right: 15px;
  display: flex;
  align-items: center;
}

.ref-material-yellow-download-img {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.ref-material-right-title-read {
  font-size: 17px;
  color: #FFB200;
  text-align: right;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 15px;
  margin-block-start: inherit;
  margin-block-end: inherit;
  cursor: pointer;
}

/*----- Reference Material Modal End CSS -----*/

.ind-list-top-header img {
  cursor: pointer;
}

.individualPlaylist .ind-list-play-icon-img {
  bottom: -35px;
}


@media screen and (max-width: 767px) {
  .ind-list-play-outer-wrap {
    padding: 15px;
  }
  .ind-list-play-outer-wrap .custom-col-4.custom-col {
    padding: 0;
  }

  .ind-list-main-banner::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 5px;
    display: block;
    background: #0000009e;
    content: '';
    border-radius: 0;
  }

  .ind-list-top-banner {
    height: inherit;
    margin-top: inherit;
    margin-left: inherit;
    margin-right: inherit;
  }

  .ind-list-b-radius-27 {
    border-radius: 0px;
    height: auto;
  }

  .search-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
    width: inherit;
  }

  .ind-card-column-count-3 {
    column-count: 1;
    padding-bottom: 20px;
  }
  .search-input input {
    font-size: 15px;
  }
  .ind-list-banner-title {
    font-size: 24px;
    -webkit-line-clamp: 2;
  }
  .individual-truncate {
    font-size: 13px;
  }
  .ind-list-get-referal {
    font-size: 15px;
  }
  .ind-card-play-title-text {
    font-size: 15px;
  }
  .ind-card-play-text-hrs {
    font-size: 13px;
  }
  .ref-material-modal-msg-text {
    font-size: 20px;
  }
  .ref-material-right-title-read {
    font-size: 15px;
  }
  .individualPlaylist {
    padding-top: 0px;
  }
  .ind-card-play-inside-wrap .ind-card-play-list-image .ind-card-img-banner {
    /* height: 150px !important; */
  }



}

@media (min-width: 768px) {
  .search-wrap {
    padding: 0 5px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  /* .search-input {
    display: none;
    width: 0;
  } */
  /* .searchIcon-outer {
    display: block;
  } */
  .search-wrap {
    justify-content: flex-end;
  }

  .individualPlaylist .search-wrap {
    justify-content: flex-start;
  }
  .individualPlaylist .search-wrap .search-input {
    display: block;
    width: inherit;
  }
  .individualPlaylist .search-wrap .searchIcon-outer {
    display: none;
  }
  
  .ind-list-banner-title {
    font-size: 25px;
  }
  .ref-material-modal-msg-text {
    font-size: 23px;
  }


}





