.individualBhaktipad-page {
  padding: 0px 15px;
  padding-bottom: 50px;
  padding-top: 105px;
  position: relative;
}

.individualBhaktipad-page .individualBhaktipad-page-title-outer {
  display: none;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  width: 100%;

}

.individualBhaktipad-page .individualBhaktipad-page-title-outer .individualBhaktipad-page-title {
  width: 100%;
}

.individualBhaktipad-page .individualBhaktipad-page-title-outer .individualBhaktipad-page-title .my-favorites-d-flex {
  padding: 0;
  width: 100%;
  gap: 24px;
  align-items: flex-start;
}

.individualBhaktipad-page .individualBhaktipad-page-title-outer .list-of-play-search {
  display: none;
}

.individualBhaktipad-page .mainTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
}

.individualBhaktipad-page .mainTitleOuter .viewMore {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}

.individualBhaktipad-page .mainTitleOuter .mainTitle {
  letter-spacing: 0em;
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
  margin: 0;
}

.individualBhaktipad-page .aboutUs-img-div {
  margin-bottom: 15px;
  text-align: center;
}

.individualBhaktipad-page .aboutUs-img-div .AbousImage {
  width: 100%;
  margin: 15px auto;
  border-radius: 10px;
}

.individualBhaktipad-page .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  padding: 15px;
  background: #fffcf5;
}

.individualBhaktipad-page .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;

}

.individualBhaktipad-page .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}




.individualBhaktipad-page .mainTitleOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
}

.individualBhaktipad-page .mainTitleOuter .viewMore {
  font-size: 17px;
  margin-bottom: 0;
  color: #F6AE06;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
}

.individualBhaktipad-page .mainTitleOuter .mainTitle {
  letter-spacing: 0em;
  color: #212121;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: left;
  margin: 0;
}

.individualBhaktipad-page .aboutUs-img-div {
  margin-bottom: 15px;
  text-align: center;
}

.individualBhaktipad-page .aboutUs-img-div .AbousImage {
  width: 100%;
  margin: 15px auto;
  border-radius: 10px;
}

.individualBhaktipad-page .first .about-us-desc {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  padding: 15px;
  background: #fffcf5;
}

.individualBhaktipad-page .first .about-us-desc .description {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;

}

.individualBhaktipad-page .first .about-us-desc .description-bold {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

/* player css */
.individualBhaktipad-page .albumInfo .left .padPlay {
  display: inline-flex;
  gap: 38px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: auto;
  flex-direction: column;
}

.individualBhaktipad-page .albumInfo .left .padPlay .player {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 400px;
}

.individualBhaktipad-page .albumInfo .left .padPlay .player .controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.individualBhaktipad-page .albumInfo .left .padPlay .player .controls .padPlayInner {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  box-shadow: 1px 3px 15px 0px #00000026;
}

.individualBhaktipad-page .albumInfo .left .padPlay .player .controls .padPlayInner .playIcon {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.playerTime {
  color: #6B6B6B;
}

.playLine {
  display: flex;
  align-items: center;
  width: 95%;
  /* Make the playline stretch horizontally */
  padding: 10px;
}

.playLine input[type="range"] {
  flex-grow: 1;
  /* Allow the input range to grow and take up available space */
  margin-right: 10px;
  /* Add some margin to the right for spacing */
}

.timeDisplay {
  white-space: nowrap;
  /* Prevent text from wrapping */
}

.individualBhaktipad-page .albumInfo .left .padPlay .player .playLine {
  display: flex;
  align-items: center;
  gap: 10px;
}

.individualBhaktipad-page .albumInfo .left .padPlay .player .playLine .songTime {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #6B6B6B;
  margin: 0;
}

.individualBhaktipad-page .albumInfo .left .padPlay .player .playLine .time {
  position: relative;
  display: flex;
  align-items: center;
}

.individualBhaktipad-page .albumInfo .left .padPlay .player .playLine .songRemainingTme {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #6B6B6B;
  margin: 0;
}

.individualBhaktipad-page .albumInfo .left .padPlay .player .playLine .time .runningBar {
  position: absolute;
  left: 0;
  width: 150px;
  height: 2px;
}

.individualBhaktipad-page .albumInfo .left .padPlay .player .playLine .time .playDot {
  position: absolute;
  left: 150px;
  width: 6px;
  height: 6px;
}

.individualBhaktipad-page .albumInfo .left .padPlay .player .playLine .time .playBar {
  width: 100%;
  height: 2px;
}

/* player css */
.individualBhaktipad-page .albumInfo .left .padPlay .controlsTop {
  width: 100%;
  display: flex;
  gap: 28px;
  flex-direction: column;
}

.individualBhaktipad-page .albumInfo .left .viewLyrics {
  width: fit-content;
  padding: 11px 20px;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  line-height: 25.5px;
  text-align: center;
  color: #fff;
  box-shadow: 1px 3px 15px 0px #00000026;
  border: 1px solid #EEAA0C;
  background: #EEAA0C;
  border-radius: 10px;
}

.individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .controlsTopOuter {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .controlsTopOuter .info {
  display: flex;
  flex-direction: column;
}

.individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .controlsTopOuter .info .infoName {
  margin: 0;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: #212121;
}

.individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .controlsTopOuter .info .infoDetailOuter {
  display: flex;
  gap: 8px;
  align-items: center;
}

.individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .controlsTopOuter .info .infoDetailOuter .infoDetail {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #6b6b6b;
  margin: 0;
}

.individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .controlsTopOuter .info .infoDetailOuter .infoImg {
  width: 16px;
}

.individualBhaktipad-page .albumInfo .left .padPlay .player .controlsOptions {
  display: flex;
  align-items: center;
  gap: 28px;
  justify-content: center;
  padding-top: 10px;
}

.individualBhaktipad-page .albumInfo .left .padPlay .player .controlsOptions .playIcon {
  width: 36px;
  height: 36px;
}

.individualBhaktipad-page .albumInfo .left .padPlay .player .controlsOptions .padPlayInner {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  box-shadow: 1px 3px 15px 0px #00000026;
}



.individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .controlsTopOuter .controlsTopInner {
  display: flex;
  align-items: center;
  gap: 15px;
}

.individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .controlsTopOuter .controlsTopInner .playIcon {
  width: 36px;
  height: 36px;
}

.individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .controlsTopOuter .controlsTopInner .moreImg {
  /* transform: rotate(90deg); */
}

.individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .controlsTopOuter .controlsTopInner .padPlayInner {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  box-shadow: 1px 3px 15px 0px #00000026;
}

.individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .padPlayInner .pauseIcon {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 24px;
  height: 24px;
}

.individualBhaktipad-page .albumInfo .right {
  background: #FDF4E1;
  padding: 44px 53px;
  border-radius: 10px;
  max-height: 510px;
  overflow: auto;
  width: 100%;
  position: relative;
  z-index: 99;
  min-height: 510px;
}

.controlsTopInner .favTooltip {
  position: relative;
  z-index: 99;
}

.controlsTopInner .outer {
  position: relative;
  z-index: 99;
}

.individualBhaktipad-page .albumInfo .right p {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #212121;
}


.individualBhaktipad-page .albumOuter {
  /* padding-top: 38px; */
  padding-bottom: 36px;
}

.individualBhaktipad-page .albumOuter .album {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  color: #212121;
  margin: 0;
  text-transform: uppercase;
  margin-left: 40px;
}

.individualBhaktipad-page .albumOuter .playingFrom {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  color: #8C8C8C;
  margin: 0;
  display: none;
}

.individualBhaktipad-page .albumOuter .albumName {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  text-align: left;
  color: #212121;
  margin: 0;
  display: flex;
  align-items: baseline;
}

.individualBhaktipad-page .albumOuter .albumDetail {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #8c8c8c;
  margin: 0;
  text-transform: uppercase;
}

.individualBhaktipad-page .albumInfo {
  display: flex;
  gap: 53px;
  justify-content: space-between;
}

.individualBhaktipad-page .albumInfo .left {
  display: flex;
  flex-direction: column;
  gap: 45px;
  width: 90%;
  text-align: center;
}

.individualBhaktipad-page .albumInfo .left .topImg {
  /* width: 400px; */
  height: 200px;
}

.individualBhaktipad-page .albumInfo .controlsTopInner {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 80px;
}

.individualBhaktipad-page .albumInfo .right {
  display: block;
}

@media (max-width: 991px) {

  .lyricsMobileViewOuter {
    display: flex;
    flex-direction: column-reverse;
  }

  .individualBhaktipad-page .mainTitleOuter .mainTitle {
    font-size: 23px;
    line-height: 34.5px;
  }

  .individualBhaktipad-page .mainTitleOuter .mainTitle {
    font-size: 23px;
    line-height: 34.5px;
  }

  .individualBhaktipad-page .albumInfo .left .padPlay {
    max-width: 100%;
    flex-direction: column-reverse;
  }

  .individualBhaktipad-page .albumInfo .left .padPlay .player {
    width: 100%;
    flex-direction: column-reverse;
  }

  .individualBhaktipad-page .individualBhaktipad-page-title-outer .list-of-play-search .search-input {
    display: none;
  }

  .individualBhaktipad-page .individualBhaktipad-page-title-outer .list-of-play-search .searchIcon-outer.searchTooltip {
    display: block;
  }

  .individualBhaktipad-page .albumOuter .playingFrom {
    display: block;
  }

  .individualBhaktipad-page .albumOuter .album {
    display: none;
  }

  .individualBhaktipad-page .albumOuter .albumName {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }

  .individualBhaktipad-page .albumOuter .albumDetail {
    display: none;
  }

  .individualBhaktipad-page .albumInfo .right.show {
    display: block;
  }

  .individualBhaktipad-page .albumInfo .right.check {
    display: none;
  }

  .individualBhaktipad-page .albumInfo .left {
    width: 100%;
  }

  .individualBhaktipad-page .albumInfo .left .topImg {
    width: 456px;
    margin: 0 auto;
    height: auto;
  }

  .individualBhaktipad-page .albumOuter {
    padding-top: 28px;
  }

  .individualBhaktipad-page .albumInfo .left .padPlay .player .playLine .time {
    width: 100%;
  }

}

@media (max-width: 991px) {
  .mobileOnly {
    display: block;
    padding-bottom: 80px !important;
  }

  .individualBhaktipad-page .albumInfo .controlsTopInner {
    z-index: 0;
  }

  .individualBhaktipad-page .albumInfo .controlsTopInner {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 5px;
    /* padding-bottom: 50px; */
  }
}

@media (min-width: 992px) {
  .mobileOnly {
    display: none;
  }
}


@media (max-width: 767px) {
  .individualBhaktipad-page {
    padding-top: 50px;
  }

  .individualBhaktipad-page .individualBhaktipad-page-title-outer {
    display: inline;
  }

  .my-favorites-c-pointers {
    display: none;
  }

  .individualBhaktipad-page .individualBhaktipad-page-title-outer .individualBhaktipad-page-title .my-favorites-c-pointer {
    display: none;
  }

  .individualBhaktipad-page .mainTitleOuter .mainTitle {
    font-size: 18px;
    line-height: 27px;
  }

  .individualBhaktipad-page .mainTitleOuter .viewMore {
    height: auto;
    padding: 0;
    font-size: 13px;
    line-height: 19.5px;
  }

  .individualBhaktipad-page .mainTitleOuter {
    display: none;
  }

  /* .mobHide {
    display: none;
  } */

  .individualBhaktipad-page .individualBhaktipad-page-title-outer .list-of-play-search .search-input {
    display: block;
  }

  .individualBhaktipad-page .individualBhaktipad-page-title-outer .list-of-play-search .searchIcon-outer.searchTooltip {
    display: none;
  }

  .individualBhaktipad-page .controlsTop {
    display: flex;
  }

  .individualBhaktipad-page .padPlay {
    left: 0;
    width: 100%;
    max-width: calc(100% - 14px);
    bottom: 55px;
    padding: 7px;
    gap: 7px;
  }

  .individualBhaktipad-page .padPlay .player .controls {
    gap: 12px;
  }

  .individualBhaktipad-page .padPlay .player .controls .prev {
    display: none;
  }

  .individualBhaktipad-page .padPlay .player .controls .next {
    display: none;
  }

  .individualBhaktipad-page .padPlay .player .controls .playAll {
    display: none;
  }

  .individualBhaktipad-page .padPlay .player .controls .padPlayInner {
    width: 24px;
    height: 24px;
  }

  .individualBhaktipad-page .padPlay .player .controls .padPlayInner .playIcon {
    width: 12px;
    height: 12px;
  }


  .individualBhaktipad-page .padPlay .player .playLine {
    display: none;
  }

  .individualBhaktipad-page .albumInfo .left .topImg {
    width: auto;
    height: 200px;
  }

  .individualBhaktipad-page .albumOuter .playingFrom {
    font-size: 13px;
    line-height: 19.5px;
  }

  .individualBhaktipad-page .albumOuter .albumName {
    font-size: 17px;
    line-height: 22.5px;
    justify-content: center;
  }

  .individualBhaktipad-page .albumInfo .left {
    gap: 43px;
  }

  .individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .controlsTopOuter .controlsTopInner {
    display: none;
  }




}

@media (min-width: 768px) {
  .individualBhaktipad-page .padPlay .player .controls .plus {
    display: none;
  }

  .individualBhaktipad-page .albumInfo .left .padPlay .player .controlsOptions {
    display: none;
  }
}

@media (min-width: 991px) {
  .individualBhaktipad-page .albumInfo .left .viewLyrics {
    display: none;
  }

  .individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .controlsTopOuter .info {
    display: none;
  }

  .individualBhaktipad-page .albumInfo .left .padPlay .controlsTop .controlsTopOuter {
    justify-content: center;
  }
}