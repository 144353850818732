.contactUs {
  padding-bottom: 60px;
  padding: 0 15px;
  padding-top: 55px;
}

.contactUs .box {
  /* padding: 0 15px; */
}

.contactUs .box .mainTitle {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.contactUs .box .field .title {
  font-size: 15px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 0;
  margin-top: 20px;
}

.contactUs .box .field {
  padding-bottom: 10px;
}

.contactUs .box .field input {
  font-size: 17px;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  border: none;
  border-radius: 10px;
  padding: 11px 10px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: #212121;
  width: 95%;
}

.contactUs .box .field textarea {
  font-size: 17px;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 0em;
  color: #212121;
  width: 95%;
  height: 91px;
}


.contactUs .box .submitBtn button.btn.save {
  border-radius: 10px;
  box-shadow: 1px 3px 15px 0px #00000026;
  background: #FFFCF5;
  border: none;
  background: linear-gradient(97.47deg, #FFB200 0%, #EEAA0C 100%);
  height: 46px;
  display: block;
  margin: 0 auto;
  padding: 0 20px;
  width: 40%;
}

.contactUs .box .submitBtn button.btn.save .title {
  font-size: 17px;
  margin: 0;
  color: #fff;
  font-family: Poppins;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}

.contactUs .box .writeUs {
  text-align: center;
  padding: 30px 0px;
}

.contactUs .box .writeUs .writeUsText {
  font-size: 15px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #8C8C8C;
  margin: 0;
}

.contactUs .box .writeUs .link {
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #212121;
  margin: 0;
  text-decoration: none;
}

.contactUs .locateUs {
  /* padding: 0 15px; */
}

.contactUs .locateUs iframe {
  box-shadow: 1px 3px 15px 0px #00000026;
  border-radius: 10px;
  border: none;
}

.contactUs .locateUs .locateTitle {
  font-size: 25px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.contactUsModal .modal-msg-content .desc {
  font-size: 25px;
  margin: 0;
  font-family: Poppins;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.contactUsModal .userImage {
  text-align: center;
  margin-bottom: 30px;
}

.contactUs .mainTitle {
  font-size: 35px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
  display: none;
}

.contactUs .dropMsgTitle {
  font-size: 25px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.modal-msg-content {
  display: flex;
  padding-bottom: 20px;
}
.modal-close-button {
  color: #FFB200;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  width: 10%;
  text-align: end;
}

@media (min-width: 991px) {
  .contactUs .contactUsInner {
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }

  .contactUs .locateUs .locateTitle {
    margin-top: 0px;
  }

  .contactUs .box {
    width: 50%;
    box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
    border: 1px solid #EEAA0C;
    border-radius: 10px;
    padding: 0px 30px;
    box-sizing: border-box;
  }

  .contactUs .locateUs {
    width: 50%;
  }
}


@Media (min-width: 768px) {
  .contactUs .mainTitle {
    display: block;
  }
  .contactUs {
    padding-bottom: 50px;
    padding-top: 65px;
  }

}

@media (max-width: 767px) {
  .contactUs .dropMsgTitle {
    font-size: 20px;
  }
  .contactUs .box .field .title {
    font-size: 13px;
  }
  .contactUs .box .field input {
    font-size: 15px;
  }
  .contactUs .box .field textarea {
    font-size: 15px;
  }
  .contactUs .box .submitBtn button.btn.save .title {
    font-size: 15px;
  }
  .contactUs .box .writeUs .writeUsText {
    font-size: 13px;
  }
  .contactUs .box .writeUs .link {
    font-size: 15px;
  }
  .contactUs .locateUs .locateTitle {
    font-size: 20px;
  }
  .contactUs .locateUs {
    padding-top: 30px;
  }
  .contactUsModal .modal-msg-content .desc {
    font-size: 20px;
  }


}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .contactUs .mainTitle {
    font-size: 33px;;
  }
  .contactUs .box {
    width: 100%;
    box-shadow: 1px 3px 25px 0px #F2AC0A1A inset;
    border: 1px solid #EEAA0C;
    border-radius: 10px;
    padding: 0px 30px;
    box-sizing: border-box;
  }
  .contactUs .dropMsgTitle {
    font-size: 23px;
  }
  .contactUs .locateUs .locateTitle {
    font-size: 23px;
  }
  .contactUs .locateUs {
    padding-top: 30px;
  }
  .contactUsModal .modal-msg-content .desc {
    font-size: 23px;
  }



}


